import { Component, ViewChild } from '@angular/core';
import { NbLayoutComponent } from '@nebular/theme';
import { OneColumnLayoutComponent } from '../one-column/one-column.layout';

@Component({
  selector: 'ngx-slim-one-column',
  styleUrls: ['./slim-one-column.layout.scss'],
  templateUrl: './slim-one-column.layout.html',
})
export class SlimOneColumnLayoutComponent extends OneColumnLayoutComponent {

  @ViewChild(NbLayoutComponent, { static: false }) layout: NbLayoutComponent;

}
