import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-pie-chart',
  template: `<div class="pie" > {{percentage}}%</div>`,
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() percent: any = 100;
  @Input() border: any = '5px';
  @Input() color: any = 'blue';
  @Input() width: any = '100px';

  constructor() { }

  //berify number has decimal with 2 digits


  get percentage() {
    return Number.isInteger(this.percent) ? this.percent : this.percent.toFixed(2);
  }

  ngOnInit() {
    document.body.style.setProperty('--p', this.percent);
    document.body.style.setProperty('--b', this.border);
    document.body.style.setProperty('--c', this.color);
    document.body.style.setProperty('--w', this.width);
  }

  ngOnChanges(changes: any) {
    if (changes.percent != null && changes.percent.currentValue != null && changes.percent.currentValue != undefined) {
      document.body.style.setProperty('--p', this.percent);
    }
  }


}
