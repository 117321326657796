import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { EditarCampoComponent } from '../editar-campo/editar-campo.component';

@Component({
  selector: 'ngx-carregar-campos',
  templateUrl: './carregar-campos.component.html',
  styleUrls: ['./carregar-campos.component.scss']
})
export class CarregarCamposComponent implements OnInit {

  @Input() component: any;
  @Input() tipo_api: any;

  @Input() value: any;

  @Input() preview: boolean = false;
  @Input() readOnly;

  @Input() formEdit: FormGroup;

  @Output() deleteComponent: any = new EventEmitter();

  formInput = this.fb.group({
    input: ['', Validators.required]
  });

  constructor(private dialogService: NbDialogService, private fb: FormBuilder) { }

  ngOnInit(): void {
    if (!this.preview) {
      this.formInput.get('input').disable();
    }
  }

  get intputValue() {
    return this.formInput.get('input').value;
  }

  configuracoes() {
    this.dialogService.open(EditarCampoComponent, {
      context: {
        component: this.component,
        tipo_api: this.tipo_api
      }
    });
  }

  get titulo() {
    if (this.formEdit != null) {
      let title = this.formEdit.get('titulo').value;
      return title != null ? title : this.component.title;
    } else {
      return this.component.title;
    }
  }

  get placeholder() {
    if (this.formEdit != null) {
      let placeholder = this.formEdit.get('placeholder').value;
      return placeholder != null ? placeholder : this.component.placeholder;
    } else {
      return this.component.placeholder;
    }
  }

  get obrigatorio() {
    if (this.formEdit != null) {
      let obrigatorio = this.formEdit.get('obrigatorio').value;
      return obrigatorio != null ? obrigatorio : this.component.obrigatorio;
    } else {
      return this.component.obrigatorio;
    }
  }

  get size() {
    if (this.formEdit != null) {
      let tamanho = this.formEdit.get('tamanho_campo').value;
      return tamanho != null ? tamanho : this.component.tamanho;
    } else {
      let size = this.component.tamanho_campo;
      return size == undefined ? 'col-md-12' : size;
    }
  }

  get maxlength() {
    if (this.formEdit != null) {
      let max_caracteres = this.formEdit.get('max_caracteres').value;
      return max_caracteres != null ? max_caracteres : this.component.max_caracteres;
    } else {
      return this.component.max_caracteres;
    }
  }

  get minlength(): number {
    if (this.formEdit != null) {
      let min_caracteres = this.formEdit.get('min_caracteres').value;
      return min_caracteres != null ? min_caracteres : this.component.min_caracteres;
    } else {
      return this.component.min_caracteres;
    }
  }

  get minVal() {
    if (this.formEdit != null) {
      let min_val = this.formEdit.get('min_val').value;
      return min_val != null ? min_val : this.component.min_val;
    } else {
      return this.component.min_val;
    }
  }

  get maxVal() {
    if (this.formEdit != null) {
      let max_val = this.formEdit.get('max_val').value;
      return max_val != null ? max_val : this.component.max_val;
    } else {
      return this.component.max_val;
    }
  }

  get minData() {
    if (this.formEdit != null) {
      let min_data = this.formEdit.get('min_data').value;
      return min_data != null ? min_data : this.component.min_data;
    } else {
      return this.component.min_data;
    }
  }

  get Ordenacao() {
    if (this.formEdit != null) {
      let tipo_ordenacao = this.formEdit.get('tipo_ordenacao').value;
      return tipo_ordenacao != null ? tipo_ordenacao : this.component.tipo_ordenacao;
    } else {
      return this.component.tipo_ordenacao;
    }
  }

  get options() {
    if (this.formEdit != null) {
      let options = this.formEdit.get('opcoes').value;
      return options != null ? options : this.component.options;
    } else {
      return this.component.options;
    }
  }

  get canMinimize() {
    return this.size != 'col-md-1';
  }

  get canMaximize() {
    return this.size != 'col-md-12';
  }

  mask_phone() {
    const size = this.intputValue.length;
    return size <= 10 ? '(00) 0000-00009' : '(00) 0 0000-0000';
  }

  minimize() {
    let currentSize = this.size.replace('col-md-', '');
    this.component.tamanho_campo = 'col-md-' + (parseInt(currentSize) - 1);
  }

  maximize() {
    let currentSize = this.size.replace('col-md-', '');
    this.component.tamanho_campo = 'col-md-' + (parseInt(currentSize) + 1);
  }

  removeComponent() {
    this.deleteComponent.emit({ component: this.component });
  }

}
