import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileReaderComponent } from './file-reader.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NbCardModule } from '@nebular/theme';

@NgModule({
  declarations: [FileReaderComponent],
  imports: [
    CommonModule,

    NbCardModule,
    NgxDocViewerModule,
  ],
  exports: [
    FileReaderComponent
  ],
  entryComponents: [
    FileReaderComponent
  ]
})
export class FileReaderModule { }