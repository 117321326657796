import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ApiBase } from '../../../@core/api/api-base.service';

@Component({
  selector: 'nb-treegrid-pagination',
  templateUrl: './nb-treegrid-pagination.component.html',
  styleUrls: ['./nb-treegrid-pagination.component.scss']
})
export class NbTreegridPaginationComponent implements OnInit {

  @Output() buildDataSource: any = new EventEmitter();

  @Input('treeGridDataSource') treeGridDataSource: any;
  @Input('data') paginationData;
  @Input('perPage') perPage: number = 5;

  constructor(private derectorRef: ChangeDetectorRef, private api: ApiBase) { }

  ngOnInit() {
    this.derectorRef.detectChanges();
  }

  ngOnChanges(changes: any) {
    if (changes.paginationData != null && changes.paginationData.currentValue != null && changes.paginationData.currentValue != undefined) {
      this.buildDataSource.emit(changes.paginationData.currentValue);
    }
  }

  get hasDate() {
    return this.paginationData != undefined;
  }

  get maxPage() {
    return this.hasDate ? this.paginationData.dados.last_page : '?';
  }

  get currentPage() {
    return this.hasDate ? this.paginationData.dados.current_page : '?';
  }

  updatePage(event) {
    this.perPage = event.target.value;

    this.api.apiGetFullPath(this.paginationData.path, { paginate: this.perPage }).subscribe(response => {
      this.buildDataSource.emit(response);
      this.paginationData = response;
    });
  }

  get hasNextPage() {
    return this.hasDate ? (this.paginationData.dados.next_page_url != null) : false;
  }

  nextPage() {
    this.api.apiGetFullPath(this.paginationData.dados.next_page_url, { paginate: this.perPage }).subscribe(response => {
      this.buildDataSource.emit(response);
      this.paginationData = response;
    });
  }

  get hasPreviousPage() {
    return this.hasDate ? (this.paginationData.dados.prev_page_url != null) : false;
  }

  previousPage() {
    this.api.apiGetFullPath(this.paginationData.dados.prev_page_url, { paginate: this.perPage }).subscribe(response => {
      this.buildDataSource.emit(response);
      this.paginationData = response;
    });
  }

}
