import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { UtilsService } from '../../../../../../@core/utils';

interface FSEntry {
  agencia?: string;
  porcentagem_variavel?: string;
  valor?: string;
  acao?: string;
}

@Component({
  selector: 'ngx-detalhes-agencia',
  templateUrl: './detalhes-agencia.component.html',
  styleUrls: ['./detalhes-agencia.component.scss']
})
export class DetalhesAgenciaComponent implements OnInit {

  allColumns = ['nome', 'rec_adm', 'sobras', 'porcentagem'];

  dataSource: NbTreeGridDataSource<FSEntry>;

  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  @Input() data: any;

  constructor(protected ref: NbDialogRef<DetalhesAgenciaComponent>, private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>, private utils: UtilsService) { }

  excelData = [];

  ngOnInit() {

    let table_data = [];
    
    let key = Object.keys(this.data)[0];

    this.data[key].forEach(colaborador => {
      table_data.push({ data: { nome: colaborador.nome, rec_adm:  parseFloat((colaborador.rec_adm + colaborador.rec_adm_rat)).toFixed(2), sobras: parseFloat((colaborador.sobras + colaborador.sobras_rat)).toFixed(2), porcentagem: colaborador.porcentagem} });
      this.excelData.push( { matricula: colaborador.codigo, nome: colaborador.nome, centro_custo: colaborador.centro_custo, nome_pa: colaborador.nome_pa, rec_adm:  parseFloat((colaborador.rec_adm + colaborador.rec_adm_rat)).toFixed(2), sobras: parseFloat((colaborador.sobras + colaborador.sobras_rat)).toFixed(2), porcentagem: colaborador.porcentagem, subtotal: parseFloat(((colaborador.rec_adm + colaborador.rec_adm_rat) + (colaborador.sobras + colaborador.sobras_rat))).toFixed(2)});
    });

    this.dataSource = this.dataSourceBuilder.create(table_data);
  }

  close() {
    this.ref.close();
  }


  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  get agencia(){
    return Object.keys(this.data)[0];
  }

  export(){
    this.utils.exportAsExcelFile(this.excelData, 'Relatório de Colaboradores - ' + this.agencia);
  }

}
