import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { EnvironmentRoles } from '../../../../../@core/auth/roles.service';
import { SelectIconComponent } from '../select-icon/select-icon.component';

@Component({
  selector: 'ngx-create-edit',
  templateUrl: './create-edit.component.html',
  styleUrls: ['./create-edit.component.scss']
})
export class CreateEditComponent implements OnInit {

  @Input() form;
  @Input() categorys;

  @Input() disablePrimaryFields;

  roles: any;

  constructor(private dialogService: NbDialogService) { }

  ngOnInit() {
    this.roles = new EnvironmentRoles();
  }

  selectCategory(event) {
    this.form.get('children_id').patchValue(null);
  }

  selectCategoryType(event) {

    if (event.id == 'link') {

      this.form.get('children_id').patchValue(null);

      this.form.get('url').setValidators([Validators.required]);
      this.form.get('url').updateValueAndValidity();

      this.form.get('category_id').setValidators(null);
      this.form.get('category_id').updateValueAndValidity();

      this.form.get('icon').setValidators([Validators.required]);
      this.form.get('icon').updateValueAndValidity();

      this.form.get('color').setValidators([Validators.required]);
      this.form.get('color').updateValueAndValidity();

    } else if (event.id == 'subcategory') {

      this.form.get('category_id').setValidators([Validators.required]);
      this.form.get('url').setValidators(null);

      this.form.get('icon').setValidators(null);
      this.form.get('icon').updateValueAndValidity();

      this.form.get('color').setValidators(null);
      this.form.get('color').updateValueAndValidity();

      this.form.get('children_id').patchValue(null);

    } else {
      this.form.get('url').setValidators(null);

      this.form.get('category_id').setValidators(null);

      this.form.get('icon').setValidators([Validators.required]);
      this.form.get('color').setValidators([Validators.required]);
    }

    this.form.updateValueAndValidity();
  }

  selectIcon() {
    this.dialogService.open(SelectIconComponent, {
      context: {
        form: this.form
      }
    });
  }

  get type() {
    return this.form.get('type').value;
  }

  get hasPremission() {
    return this.roles.tela ? this.roles.tela.includes(8) : true;
  }

  get hasChildren() {
    if (this.categorys) {
      const category = this.categorys.filter(c => c.category_id == this.form.get('category_id').value)[0];
      if (category) {
        return category.children.length > 0;
      }
    }
    return false;
  }

  get children() {
    return this.categorys != null ? this.categorys.filter(c => c.category_id == this.form.get('category_id').value)[0].children : [];
  }

}
