import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { MENU_ITEMS } from './pages-menu';
import { MENU_CONF } from '../config-menu';

import { EnvironmentRoles } from '../@core/auth/roles.service';
import { ApiBase } from '../@core/api/api-base.service';
import { ApiService } from '../@core/api/api.service';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
  <ngx-one-column-layout>
    <nb-menu [items]="menu"></nb-menu>
    <router-outlet></router-outlet>
  </ngx-one-column-layout>
`,
})
export class PagesComponent {
  menu = MENU_ITEMS;
  menu_config = MENU_CONF;

  constructor(
    apiServ: ApiService,
  ) {
    apiServ.apiGet('categoria/menu').subscribe(response => {

      localStorage.setItem('Menu', JSON.stringify(response.dados.categoria));
      var menuLimpo = localStorage.getItem('Menu').split(',"children":[]').join('');
      this.menu = MENU_ITEMS;
      this.menu = [...this.menu, ...JSON.parse(menuLimpo)];

      // Permissão de Gerenciamento
      // console.log('tela', this.roles);
      var roles: any = new EnvironmentRoles();
      // console.log('pages roles', roles);

      if (roles.tela != undefined) {

        // Página Gerencial
        if (roles.tela.includes(5)) {
          this.menu = this.menu.concat(this.menu_config);
        }
      }
    },
      error => {
        console.log(error);
      }
    );
  }
}
