import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { LinksComponent } from '../links.component';
import { NbDialogService } from '@nebular/theme';
import { ApiService } from '../../@core/api/api.service';
import { MENU_ITEMS } from '../../pages/pages-menu';
import { ApiBase } from '../../@core/api/api-base.service';
import { EnvironmentRoles } from '../../@core/auth/roles.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-links-favoritos',
  templateUrl: './favoritos.component.html',
  styleUrls: ['./favoritos.component.scss']
})
export class FavoritosComponent implements OnInit {
  roles: any = new EnvironmentRoles();

  @Input() moreAction: boolean;
  @Input() inputFavoritos: any;

  linksFavoritos = [];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private dialogService: NbDialogService,
    private api: ApiBase,
  ) {
  }

  ngOnInit() {
    // console.log(this.linksFavoritos.length);
    this.getPermissoes();
    this.buscarFavoritos();
  }

  async getPermissoes() {
    var permissoesJson = await this.api.apiGetPromise("permissoes", {}).then(a => { return a });
    environment.roles = Object(permissoesJson).roles;
    this.roles = environment.roles;
  }

  buscarFavoritos() {
    // Carregar favoritos
    this.api.apiGet('perfil/links/favorito', {}).subscribe(response => {
      this.linksFavoritos = response.dados.link;
    },
      error => {
        console.log(error);
      }
    );
  }

  openLinks(params: any = {}) {

    //Modal de Ramais
    var modal = this.dialogService.open(
      LinksComponent,
      {
        hasBackdrop: true,
        closeOnBackdropClick: true,
        closeOnEsc: true,
        hasScroll: true,
        context: {
          params: params,
        }
      }
    );
    modal.onClose.subscribe(data => {
      this.buscarFavoritos();
    });
  }

}
