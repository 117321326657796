import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { TokenStorage } from '../auth/token-storage.service';
// import { UtilsService } from '../services/utils.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ApiBase } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends ApiBase {

  API_URL = environment.STG_URL + '/api/';

  /**
	 * Submit login request
	 * @param {String} ROTA
	 * @returns {Observable<any>}
	 */
  public apiGetUser(extraHeader: any = []): Observable<any> {
    this.updateHeaders(extraHeader);

    // Expecting response from API
    return this.http.get(this.API_URL + 'user', this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
	 * Submit login request
	 * @param {String} ROTA
	 * @returns {Observable<any>}
	 */
  public apiCreateUser(): Observable<any> {
    this.updateHeaders();

    // Expecting response from API
    return this.http.post(this.API_URL + 'aplication/create', {}, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
	 * Submit login request
	 * @param {String} ROTA
	 * @returns {Observable<any>}
	 */
  public apiGetPasta(extraHeader: any = []): Observable<any> {
    this.updateHeaders(extraHeader);

    // Expecting response from API
    return this.http.get(this.API_URL + 'user/' + this.getUser().id, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
	 * Submit login request
	 * @param {String} ROTA
	 * @returns {Observable<any>}
	 */
  public apiGetSearch(search: String): Observable<any> {
    this.updateHeaders();

    // Expecting response from API
    return this.http.get(this.API_URL + 'user/arquivo/search/' + search, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
	   * Submit login request
	   * @param {String} ROTA
	   * @returns {Observable<any>}
	   */
  public apiUpload(pasta: String, data: any, extraHeader: any = []): Observable<any> {
    this.updateHeaders(extraHeader);

    // Expecting response from API
    return this.http.post(this.API_URL + 'user/pasta/' + pasta + '/arquivo', data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} credential
   * @returns {Observable<any>}
   */
  public apiPublic(pasta: string, idFile: string, data: any): Observable<any> {
    this.updateHeaders();

    // Expecting response from API
    return this.http.put(this.API_URL + 'user/pasta/' + pasta + '/arquivo/public/' + idFile, data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} credential
   * @returns {Observable<any>}
   */
  public apiPrivate(pasta: string, idFile: string, data: any): Observable<any> {
    this.updateHeaders();

    // Expecting response from API
    return this.http.put(this.API_URL + 'user/pasta/' + pasta + '/arquivo/private/' + idFile, data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} credential
   * @returns {Observable<any>}
   */
  public apiDeleteFile(pasta: string, idFile: string): Observable<any> {
    this.updateHeaders();

    // Expecting response from API
    return this.http.delete(this.API_URL + 'user/pasta/' + pasta + '/arquivo/delete/' + idFile, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

}
