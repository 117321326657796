import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColaboradoresAfastarComponent } from './colaboradores-afastar.component';
import { NbButtonModule, NbCardModule, NbDatepickerModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ColaboradoresAfastarComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbDatepickerModule,

    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [ColaboradoresAfastarComponent],
  exports: [ColaboradoresAfastarComponent]
})
export class ColaboradoresAfastarModule { }
