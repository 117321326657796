import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
  NbMenuModule,
  NbCheckboxModule,
  NbLayoutModule,
  NbBadgeModule,
  NbSpinnerModule,
  NbProgressBarModule,
  NbAccordionModule,
  NbAlertModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgxFileDropModule } from 'ngx-file-drop';

import { StorageComponent } from './storage.component';
import { PdftronComponent } from './pdftron/pdftron.component';
import { FileReaderModule } from './file-reader/file-reader.module';

@NgModule({
  declarations: [StorageComponent, PdftronComponent],
  imports: [
    CommonModule,
    FormsModule,
    NbMenuModule,
    NbCardModule,
    NbCheckboxModule,
    NbUserModule,
    NbButtonModule,
    NbTabsetModule,
    NbActionsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbButtonModule,
    NgxEchartsModule,
    NbLayoutModule,
    NbBadgeModule,
    NbSpinnerModule,
    NbProgressBarModule,
    NbAccordionModule,
    NbAlertModule,

    // eva icon
    NbEvaIconsModule,

    // Smart Table
    Ng2SmartTableModule,

    //upload drop
    NgxFileDropModule,

    FileReaderModule,
  ],
  exports: [StorageComponent],
  entryComponents: [StorageComponent, PdftronComponent]
})
export class StorageModule { }