import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RamaisComponent } from './ramais.component';
import { NbCardModule, NbIconModule, NbUserModule, NbActionsModule, NbAccordionModule, NbInputModule, NbSelectModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CartaoDeVisitaModule } from '../perfil/cartao-de-visita/cartao-de-visita.module';



@NgModule({
  declarations: [RamaisComponent],
  imports: [
    CommonModule,
    RouterModule,
     
    FormsModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbInputModule,

    NgSelectModule,

    NgbModule,

    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,

    NbUserModule,
    NbActionsModule,

    NbAccordionModule,

    CartaoDeVisitaModule,
  ],
  exports: [
    RamaisComponent
  ],
  entryComponents: [
    RamaisComponent
  ]
})
export class RamaisModule { }
