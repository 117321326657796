import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class 
ConfirmationComponent implements OnInit {

  @Input('title') title: string;
  @Input('message') message: string;
  @Input('status') status: string = 'danger';

  constructor(private ref: NbDialogRef<ConfirmationComponent>) { }

  ngOnInit() { }

  close(success: boolean = false) {
    this.ref.close({ success: success });
  }

  get color(): string {
    switch (this.status) {
      case 'danger':
        return '#EC401A';
      case 'info':
        return '#1CB1A3';
    }
  }

}
