import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanEstrategicoComponent } from './plan-estrategico.component';
import { ThemeModule } from '../@theme/theme.module';
import { NbMenuModule } from '@nebular/theme';
import { PlanEstrategicoRoutingModule } from './plan-estrategico-routing.module';
import { BannerModule } from './banner/banner.module';



@NgModule({
  declarations: [PlanEstrategicoComponent],
  imports: [
    PlanEstrategicoRoutingModule,
    BannerModule,

    CommonModule,
    ThemeModule,
    NbMenuModule,
  ]
})
export class PlanEstrategicoModule { }
