import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelacaoColaboradoresComponent } from './relacao-colaboradores.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTooltipModule, NbTreeGridModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [RelacaoColaboradoresComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbTooltipModule,

    FormsModule,
    ReactiveFormsModule,
    NbTreeGridModule
  ],
  exports: [RelacaoColaboradoresComponent],
  entryComponents: [RelacaoColaboradoresComponent]
})
export class RelacaoColaboradoresModule { }
