import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColaboradoresRealocarComponent } from './colaboradores-realocar.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,

    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ColaboradoresRealocarComponent],
  exports: [ColaboradoresRealocarComponent],
  entryComponents: [ColaboradoresRealocarComponent]
})
export class ColaboradoresRealocarModule { }
