import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectIconComponent } from './select-icon.component';
import { NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';



@NgModule({
  declarations: [SelectIconComponent],
  imports: [
    CommonModule,

    NbCardModule,
    NbButtonModule,
    NbEvaIconsModule,

    NbIconModule
  ],
  exports: [SelectIconComponent],
  entryComponents: [SelectIconComponent]
})
export class SelectIconModule { }
