import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ContextMenuComponent } from './context-menu/context-menu.component';

@Component({
  selector: 'ngx-context-button',
  templateUrl: './context-button.component.html',
  styleUrls: ['./context-button.component.scss']
})
export class ContextButtonComponent implements OnInit {

  constructor(
    private dialogService: NbDialogService,
  ) {

  }

  ngOnInit() {
    
  }

  openContextMenu(params: any = {}) {
    console.log('Abriu menu de contexto.');

    //Modal de Ramais
    var modal = this.dialogService.open(
      ContextMenuComponent,
      {
        hasBackdrop: true,
        closeOnBackdropClick: true,
        closeOnEsc: true,
        hasScroll: true,
        context: {
          params: params,
        }
      }
    );
    modal.onClose.subscribe(data => {
      console.log("Menu de Contexto", data);
      // if(data.status) {
      //   this.loadPage(this.aplicativo.current_page);
      // }
    });
  }

}
