import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManterComponent } from './manter.component';
import {
  NbDialogModule,
  NbTreeGridModule,
  NbCardModule,
  NbInputModule,
  NbButtonModule,
  NbActionsModule,
  NbUserModule,
  NbCheckboxModule,
  NbRadioModule,
  NbDatepickerModule,
  NbSelectModule,
  NbIconModule,
  NbTooltipModule
} from '@nebular/theme';
import { NbMomentDateModule  } from "@nebular/moment";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbDateFnsDateModule } from '@nebular/date-fns';

@NgModule({
  declarations: [ManterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    NbCardModule,
    NbTreeGridModule,
    NbDialogModule.forChild(),

    NbInputModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbMomentDateModule,
    NbDateFnsDateModule.forChild({
      format: 'dd/MM/yyyy',
    }),

    NbSelectModule,
    NbIconModule,

    NbTooltipModule,
  ],
  exports: [ManterComponent],
  entryComponents: [ManterComponent]
})
export class ManterModule { }
