export const environment = {
  production: true,
  roles: {},

  // LOCAL CABEADA
  STG_URL: "https://apistorage.unicb.coop.br",

  DMS_URL: "https://api.unicentrobr.com.br/api-demissionarios/api",

  // API_URL: "https://apiauth.unicb.coop.br/api",
  // IMG_URL: "https://apiauth.unicb.coop.br/api",
  API_URL: "https://api.unicentrobr.com.br/api-auth/api",
  IMG_URL: "https://api.unicentrobr.com.br/api-auth/api",

  PRC_URL: "https://apiprocessos.unicb.coop.br/api",
  GRT_URL: "https://apigarantia.unicb.coop.br/api",
  SDJ_URL: "https://apisimuladordj.unicb.coop.br/api",
  CSB_URL: "https://apisimuladordj.unicb.coop.br/api",

  GTM_URL: "https://apimetricas.unicb.coop.br/api",

  BNU_URL: "https://apibndu.unicb.coop.br/api",

  COL_URL: "https://apicolaboradores.unicb.coop.br/api",

  SPA_URL: "https://apipatrocinio.unicb.coop.br/api",

  //URL API SCOPI
  SCP_URL: "https://apiformularios.unicb.coop.br/api",

  //URL CADEIA DE VALOR
  CDV_URL: "https://api.unicentrobr.com.br/portalprocessos/api",

  //URL Plataforma Inovação
  PIN_URL: "https://api.unicentrobr.com.br/intranet/api",

  // HOM_URL: "http://apigw.unicb.coop.br:8000/homcobrancabancaria/api",
  HOM_URL: "https://apihomcobbancaria.unicb.coop.br/api",

  //URL Da api Variável
  // VAR_URL: "https://apigw.unicb.coop.br:8000/apivariavel/api",
  VAR_URL: "https://apivariavel.unicb.coop.br/api",

  //URL API METAS
  MET_URL: "https://apimetasinv.unicb.coop.br/api",
};
