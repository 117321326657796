import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule, NbDialogModule, NbSpinnerModule, NbTreeGridModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditcategoryComponent } from './editcategory.component';
import { CreateEditModule } from '../create-edit/create-edit.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [EditcategoryComponent],
  imports: [
    CommonModule,

    FormsModule,
    NbCardModule,
    NbButtonModule,
    NbSpinnerModule,
    NbTreeGridModule,
    NbDialogModule,
    NgSelectModule,

    CreateEditModule,
    SweetAlert2Module,
    ReactiveFormsModule,

    RouterModule,
  ],
  entryComponents: [EditcategoryComponent],
  exports: [EditcategoryComponent]
})
export class EditCategoryModule { }
