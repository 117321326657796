import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { ApiColaboradores } from '../../../../../@core/api/colaboradores.service';
import { UtilsService } from '../../../../../@core/utils';
import { ColaboradoresAfastarComponent } from './colaboradores-afastar/colaboradores-afastar.component';
import { ColaboradoresRealocarComponent } from './colaboradores-realocar/colaboradores-realocar.component';
import { ColaboradoresTransferirComponent } from './colaboradores-transferir/colaboradores-transferir.component';

interface FSEntry {
  matricula?: string;
  nome?: string;
  centro_custo?: string;
  centro_calculo?: string;
  subtotal?: string;
  acao?: string;
}

@Component({
  selector: 'ngx-relacao-colaboradores',
  templateUrl: './relacao-colaboradores.component.html',
  styleUrls: ['./relacao-colaboradores.component.scss']
})
export class RelacaoColaboradoresComponent implements OnInit {

  allColumns = ['matricula', 'nome', 'centro_custo', 'centro_calculo', 'subtotal', 'acao'];

  dataSource: NbTreeGridDataSource<FSEntry>;

  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  @Input() data: any;
  @Input() data_pa: any;

  constructor(protected ref: NbDialogRef<RelacaoColaboradoresComponent>, private dialogService: NbDialogService, private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>, public utils: UtilsService, private api: ApiColaboradores) { }

  ngOnInit() {
    this.atualizarTabela();
  }

  close() {
    this.ref.close();
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  get agencia() {
    return this.data_pa.agencia;
  }

  transferir(row) {
    this.api.apiPost('colaboradores/buscar', { codigo: row.data.matricula }).subscribe(response => {
      let dialog = this.dialogService.open(ColaboradoresTransferirComponent, {
        context: {
          data: response.dados
        }
      });

      dialog.onClose.subscribe(response => {
        if (response != undefined && typeof response != 'undefined') {
          console.log(response);

          if (response.status) {
            this.atualizarTabela();
          }
        }
      });

    })
  }

  afastar(row) {
    this.api.apiPost('colaboradores/afastamento/buscar', { codigo: row.data.matricula }).subscribe(response => {

      let dialog = this.dialogService.open(ColaboradoresAfastarComponent, {
        context: {
          data: response.dados
        }
      });

      dialog.onClose.subscribe(response => {
        if (response != undefined && typeof response != 'undefined') {
          console.log(response);

          if (response.status) {
            this.atualizarTabela();
          }
        }
      });

    })
  }

  realocar(row) {

    this.api.apiPost('colaboradores/buscar', { codigo: row.data.matricula }).subscribe(response => {

      let dialog = this.dialogService.open(ColaboradoresRealocarComponent, {
        context: {
          data: response.dados
        }
      });

      dialog.onClose.subscribe(response => {
        if (response != undefined && typeof response != 'undefined') {
          if (response.status) {
            this.atualizarTabela();
          }
        }
      });

    })

  }

  atualizarTabela() {
    let table_data = [];

    let key = Object.keys(this.data)[0];

    this.data[key].forEach(colaborador => {
      table_data.push({ data: { matricula: colaborador.codigo, nome: colaborador.nome, centro_custo: colaborador.centro_custo, centro_calculo: colaborador.centro_custo_calculo, subtotal: (colaborador.salario + colaborador.qb_de_caixa + colaborador.grat_cargo), transferencia: colaborador.transferencia } })
    });

    this.dataSource = this.dataSourceBuilder.create(table_data);
  }

}
