import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from './context-menu.component';
import { NbCardModule, NbInputModule, NbButtonModule, NbToggleModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ContextMenuComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbToggleModule,
  ],
  exports: [
    ContextMenuComponent
  ],
  entryComponents: [
    ContextMenuComponent
  ]
})
export class ContextMenuModule { }
