import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { data } from './icons.js';

@Component({
  selector: 'ngx-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss']
})
export class SelectIconComponent implements OnInit {

  @Input() form;

  icons;

  constructor(private ref: NbDialogRef<SelectIconComponent>) { }

  ngOnInit() {
    this.icons = Array.from(data);
  }

  selectIcon(icon) {
    this.form.get('icon').patchValue(icon.name);
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

}
