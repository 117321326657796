import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartaoDeVisitaComponent } from './cartao-de-visita.component';
import { NbCardModule, NbIconModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';



@NgModule({
  declarations: [CartaoDeVisitaComponent],
  imports: [
    CommonModule,

    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,
  ],
  exports: [
    CartaoDeVisitaComponent,
  ],
  entryComponents: [
    CartaoDeVisitaComponent
  ]
})
export class CartaoDeVisitaModule { }
