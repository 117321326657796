import { Injectable } from '@angular/core';
import { ApiBase } from './api-base.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestformService extends ApiBase {

  API_URL = environment.SCP_URL + '/';

  fields = [
    {
      tipo: 'paragrafo',
      title: 'Parágrafo',
      icon: 'text-outline',
    },

    {
      tipo: 'campo',
      title: 'Campo',
      placeholder: 'Placeholder do Campo',
      icon: 'text-outline',
    },
    {
      tipo: 'texto_longo',
      title: 'Texto Longo',
      placeholder: 'Placeholder do Texto Longo',
      icon: 'text-outline'
    },
    {
      tipo: 'email',
      title: 'Email',
      placeholder: 'Placeholder do Email',
      icon: 'email-outline'
    },
    {
      tipo: 'telefone',
      title: 'Telefone',
      placeholder: 'Placeholder do Telefone',
      icon: 'phone-outline'
    },
    {
      tipo: 'numero',
      title: 'Número',
      placeholder: 'Placeholder do Número',
      icon: 'text-outline'
    },
    {
      tipo: 'data',
      title: 'Data',
      placeholder: 'Placeholder da Data',
      icon: 'calendar-outline'
    },
    {
      tipo: 'caixa_selecao',
      title: 'Caixa de Seleção',
      icon: 'checkmark-square-2-outline'
    },

    {
      tipo: 'grupo_selecao',
      title: 'Grupo de Seleção',
      icon: 'checkmark-circle-2-outline',
    },

    {
      tipo: 'lista_valores',
      title: 'Lista de Valores',
      placeholder: 'Placeholder da Lista de Valores',
      icon: 'list-outline'
    },

    // {
    //   tipo: 'anexo_arquivo',
    //   title: 'Anexo de Arquivo',
    //   icon: 'file-add-outline'
    // },

  ];

  selects = {
    tipo_api: [
      { id: 'scopi', name: 'Scopi' }
    ],
    sim_nao: [
      {id: 'SIM', name: 'Sim'},
      {id: 'NAO', name: 'Não'},
    ],
    disponibilizacao: [
      {id: 'PERIODICA', name: 'Periódica'},
      {id: 'INTEGRAL', name: 'Integral'}
    ]
  };

}
