import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'ngx-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input('title') title: string;
  @Input('message') message: string;
  @Input('status') status: string = 'info';

  constructor(private ref: NbDialogRef<ConfirmationComponent>) { }

  ngOnInit() { }

  close(success: boolean = false) {
    this.ref.close({ success: success });
  }

  get color(): string {
    switch (this.status) {
      case 'danger':
        return '#EC401A1A';
      case 'warning':
        return '#fbbc051A';
      case 'info':
        return '#1CB1A31A';
    }
  }

}
