import { NbMenuItem } from '@nebular/theme';

export const MENU_CONF: NbMenuItem[] = [
  // {
  //   title: 'Configuração',
  //   group: true,
  // },
  // {
  //   title: 'Configuração',
  //   icon: 'settings-2-outline',
  //   children: [
  {
    title: 'Gerencial',
    icon: 'settings-2-outline',
    link: '/gerencial',
  }
  //   ]
  // },
];
