import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by"><b><a href="/" target="_blank">PGC - Painel Gerencial da Cooperativa</a></b> 2019</span>
    <div class="socials">
      <a href="http://www.unisicoob.com.br/unicentrobrasileira/pagina-inicial" target="_blank" class="ion ion-android-globe"></a>
      <a href="https://pt-br.facebook.com/sicoobunicentrobrasileira/" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://www.instagram.com/sicoobunicentrobrasileira/?hl=pt" target="_blank" class="ion ion-social-instagram"></a>
      <a href="https://www.linkedin.com/company/sicoob-unicentro-brasileira" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
}
