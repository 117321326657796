import { Component, OnInit } from '@angular/core';
import { useInterval } from '../../../@core/utils/util-service.service';


@Component({
  selector: 'auth-carousel',
  templateUrl: './auth-carousel.component.html',
  styleUrls: ['./auth-carousel.component.scss'],
})
export class AuthCarouselComponent implements OnInit {

  items: Array<{ image: string, title: string, description: string }>

  index: number = 0;

  time: number = 1000 * 8;
  interval: any;


  ngOnInit(): void {

    this.items = [
      {
        image: '../../../../assets/auth/register.svg',
        title: 'Crie a sua própria conta',
        description: 'Para criar a sua conta na plataforma, basta informar o seu e-mail corporativo e a matrícula. É simples e rápido! A partir disso, você poderá acessar todas as funcionalidades disponíveis na plataforma.'
      },
      {
        image: '../../../../assets/auth/forgot-password.svg',
        title: 'Perdeu sua senha? Recupere agora!',
        description: 'Se você esqueceu sua senha de acesso, basta informar seu e-mail corporativo na página de recuperação de senha do Facilita. Você receberá um e-mail de redefinição de senha com um link seguro.'
      }
    ]

    this.interval = useInterval(() => {

      this.interval.restart(this.time)

      if (this.index < 1) {
        this.index++;
      } else {
        this.index = 0;
      }

    }, this.time)

    this.interval.start();
  }

  changeIndex(index: number) {
    this.index = index;
    this.interval.restart(this.time)
  }


}
