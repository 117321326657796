import { Component, OnInit, Input } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss']
})
export class PrintButtonComponent implements OnInit {

  printSectionValue: any;

  @Input() printSection: any;

  constructor() {
  }

  ngOnInit() {
    this.printSectionValue = this.printSection;
  }

  callPrint() {
    window.print();
  }
}
