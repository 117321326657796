import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

import { ApiPortalInovacao } from '../../../../../@core/api/portal-inovacao';
import { UtilsService } from '../../../../../@core/utils';

@Component({
  selector: 'ngx-create-edit',
  templateUrl: './create-edit.component.html',
  styleUrls: ['./create-edit.component.scss']
})
export class CreateEditComponent implements OnInit {

  @Input() data;

  saving: boolean;

  form = this.fb.group({
    name: ['', Validators.required],
    link: ['', Validators.required],
    icon: ['']
  });

  constructor(@Optional() private ref: NbDialogRef<any>, private fb: FormBuilder, private api: ApiPortalInovacao, private utils: UtilsService) { }

  ngOnInit() {
    console.log('data', this.data);

    if(this.data != undefined) {
      this.form.get('name').patchValue(this.data.name);
      this.form.get('link').patchValue(this.data.link);
    }

  }

  close() {
    this.ref.close();
  }

  onSelectfile(event) {
    if (event.target.files != null) {
      const archive = event.target.files.item(0);

      let reader = new FileReader();
      reader.readAsDataURL(archive);

      reader.onload = () => {
        this.form.get('icon').patchValue(archive);
        this.form.updateValueAndValidity();
      }

    }
  }

  removeFile(element) {
    this.form.get('icon').patchValue(null);
    element.value = null;
  }

  save() {
    this.saving = true;

    this.api.apiPost(this.data == undefined ? 'links' : 'links/update', this.buildPayload).subscribe(response => {

      this.utils.showToast('top-right', response.status, response.dados);

      if(response.status == 'success') {
        this.saving = false;
        this.form.reset();

        this.ref.close({update: true});
      }

    });
  }

  get buildPayload() {
    const formData = new FormData();

    if(this.data != undefined) {
      formData.append('id', this.data.id);
    }

    for (const field in this.form.controls) {
      const fieldValue = this.form.get(field).value;
      formData.append(field, fieldValue);
    }

    return formData;
  }


}
