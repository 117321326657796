import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SobrasAreasComponent } from './sobras-areas.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTreeGridModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [SobrasAreasComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,

    FormsModule,
    ReactiveFormsModule,
    NbTreeGridModule
  ],
  exports: [SobrasAreasComponent],
  entryComponents: [SobrasAreasComponent]
})
export class SobrasAreasModule { }
