import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditarCampoComponent } from './editar-campo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbIconModule, NbInputModule, NbRadioModule, NbSelectModule } from '@nebular/theme';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarregarCamposModule } from '../carregar-campos/carregar-campos.module';



@NgModule({
  declarations: [EditarCampoComponent],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    NbInputModule,
    NbCardModule,
    NbIconModule,
    NbSelectModule,
    NbRadioModule,
    NbDatepickerModule,
    NbCheckboxModule,
    NbButtonModule,

    NgxFileDropModule,
    NgSelectModule,

    CarregarCamposModule
  ],
  exports: [EditarCampoComponent],
  entryComponents: [EditarCampoComponent]
})
export class EditarCampoModule { }
