import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { FormBuilder } from '@angular/forms';

import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import 'quill-emoji/dist/quill-emoji.js';

import './CustomImage';

Quill.register('modules/blotFormatter', BlotFormatter);

@Component({
  selector: 'ngx-model-post',
  templateUrl: './model-post.component.html',
  styleUrls: ['./model-post.component.scss']
})
export class ModelPostComponent implements OnInit {

  @Input() form;

  modules = {
    'emoji-shortname': true,
    'emoji-textarea': false,
    'emoji-toolbar': true,
    blotFormatter: {},
    'toolbar': {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link', 'image', 'video'],                         // link and image, video
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } },

    }
  }

  formModel = this.fb.group({
    content: []
  });

  constructor(private ref: NbDialogRef<ModelPostComponent>, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.formModel.get('content').patchValue(this.form.get('content').value);
  }


  save() {
    this.form.get('content').patchValue(this.formModel.get('content').value);
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

}
