import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColaboradoresTransferirComponent } from './colaboradores-transferir.component';
import { NbButtonModule, NbCardModule, NbDatepickerModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbDatepickerModule,

    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ColaboradoresTransferirComponent],
  exports: [ColaboradoresTransferirComponent],
  entryComponents: [ColaboradoresTransferirComponent]
})
export class ColaboradoresTransferirModule { }
