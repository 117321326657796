import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UtilsService } from '../@core/utils';
import { Validators, FormBuilder } from '@angular/forms';
import { ApiBase } from '../@core/api/api-base.service';
import { environment } from '../../environments/environment';
import { PdftronComponent } from '../storage/pdftron/pdftron.component';
import { CartaoDeVisitaComponent } from '../perfil/cartao-de-visita/cartao-de-visita.component';

@Component({
  selector: 'ngx-ramais',
  templateUrl: './ramais.component.html',
  styleUrls: ['./ramais.component.scss']
})
export class RamaisComponent implements OnInit {

  STG_URL: string = environment.STG_URL + '/';

  loading = true;
  loadingSearch = true;

  params: any;

  formFiltro = this.fb.group({
    nome: ['',
      [
        Validators.minLength(1),
        Validators.maxLength(45)
      ]
    ],
    agencia: ['',
      [
      ],
    ],
    setor: ['',
      [
      ],
    ]
  });

  resultadosFiltrados: any;
  searchTerm: string;
  contatos: any = [];
  agencias = [];

  constructor(
    protected dialogRef: NbDialogRef<RamaisComponent>,
    public utils: UtilsService,
    private fb: FormBuilder,
    private api: ApiBase,
    private dialogService: NbDialogService,
  ) {

  }

  ngOnInit() {
    this.carregarContatos();

    //Evento ao modificar o formulário
    this.formFiltro.valueChanges.subscribe(val => {
      this.aguardarDigitacao();
    });
  }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }

  waitTime: any;
  waitingType = false;
  aguardarDigitacao() {
    // console.log('aguardar digitação...');
    this.waitingType = true;

    clearTimeout(this.waitTime);
    this.waitTime = setTimeout((a) => {
      // console.log('carregar');

      this.waitingType = false;
      this.carregarContatos();

    }, 500);
  }

  carregarContatos() {
    this.loadingSearch = true;

    // Carregar links utéis
    this.api.apiGet('contatos', {
      search: this.formFiltro.value.nome
    }).subscribe(
      response => {
        this.contatos = response.dados;

        // console.log(this.contatos);
        this.loading = false;
        this.loadingSearch = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  getAgencias() {
    return JSON.parse(JSON.stringify(this.agencias));
  }

  abrirCartaoVisita(user: any) {
    var modal = this.dialogService.open(
      CartaoDeVisitaComponent,
      {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        hasScroll: true,
        context: {
          data: user
        }
      }
    );
    modal.onClose.subscribe(retorno => {
      console.log(retorno);
    });
  }
}