import { Component, OnInit, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  constructor() {
    // customize default values of carousels used by this component tree
    // config.showNavigationArrows = true;
    // config.showNavigationIndicators = true;
  }
  
  ngOnInit() {
  }

}
