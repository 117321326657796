import { Component } from '@angular/core';
import { PagesComponent } from '../pages/pages.component';

@Component({
  selector: 'ngx-plan-estrategico',
  templateUrl: './plan-estrategico.component.html',
  styleUrls: ['./plan-estrategico.component.scss']
})
export class PlanEstrategicoComponent extends PagesComponent {


}
