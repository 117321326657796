import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule, NbDialogModule, NbIconModule, NbSpinnerModule, NbTreeGridModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateEditComponent } from './create-edit.component';

@NgModule({
  declarations: [CreateEditComponent],
  imports: [
    CommonModule,

    FormsModule,
    NbCardModule,
    NbButtonModule,
    NbSpinnerModule,
    NbTreeGridModule,
    NbDialogModule,
    NbIconModule,

    NgSelectModule,

    ReactiveFormsModule,

    RouterModule,
  ],
  entryComponents: [CreateEditComponent],
  exports: [CreateEditComponent]
})
export class CreateEditModule { }
