import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { ApiBase } from '../../../../@core/api/api-base.service';
import { UtilsService } from '../../../../@core/utils';

@Component({
  selector: 'ngx-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  params: any = {};
  user = JSON.parse(localStorage.getItem('user'));

  formFeedback = this.fb.group({
    anonimo: [false,
      [
      ]
    ],
    assunto: ['',
      [
        Validators.required,
        Validators.maxLength(255),
      ]
    ],
    descricao: ['',
      [
        Validators.required,
        Validators.maxLength(65535),
      ]
    ],
  });

  constructor(
    protected dialogRef: NbDialogRef<ContextMenuComponent>,
    private router: Router,
    private fb: FormBuilder,
    private api: ApiBase,
    private utils: UtilsService,
  ) {

  }

  ngOnInit() {

  }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }

  submit() {
    console.log('submit');

    console.log(this.router, this.router.routerState.snapshot.url, this.params);

    this.api.apiPost('perfil/feedback', {
      router_url: this.router.routerState.snapshot.url,
      anonimo: this.formFeedback.value.anonimo || false,
      assunto: this.formFeedback.value.assunto,
      descricao: this.formFeedback.value.descricao,
    })
      .subscribe(
        response => {
          console.log(response);

          this.utils.showToast('top-right', 'success', 'Sugestão enviada com sucesso.', 'Tudo ok.')
          this.modalClose({}, true);

        },
        error => {
          console.log(error);
          this.utils.showToast('top-right', 'danger', 'Erro', error.message)
        }
      );

    // this.utils.showToast('top-right', 'success', 'Sugestão enviada com sucesso.', 'Tudo ok.');
  }
}
