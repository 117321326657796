import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ApiPortalInovacao } from '../../../../../@core/api/portal-inovacao';
import { UtilsService } from '../../../../../@core/utils';

@Component({
  selector: 'ngx-editcategory',
  templateUrl: './editcategory.component.html',
  styleUrls: ['./editcategory.component.scss']
})
export class EditcategoryComponent implements OnInit {

  @Input() data;
  @Input() categorys;

  form = this.fb.group({
    name: ['', Validators.required],
    color: ['', Validators.required],
    icon: ['', Validators.required],
    type: ['', Validators.required],
    url: [''],
    category_id: [''],
    children_id: ['']
  });

  constructor(private fb: FormBuilder, private utils: UtilsService, private api: ApiPortalInovacao, private ref: NbDialogRef<EditcategoryComponent>, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.form.get('name').patchValue(this.data.name);

    if (this.data.type == 'category') {
      this.form.get('color').patchValue(this.data.color);
      this.form.get('icon').patchValue(this.data.icon);
      this.form.get('url').patchValue(this.data.url);
      this.form.get('type').patchValue(this.data.category_type.toLowerCase());
    } else {
      this.form.get('type').patchValue(this.data.type.toLowerCase());

      this.form.get('color').setValidators(null);
      this.form.get('icon').setValidators(null);
    }

    this.form.get('category_id').patchValue(this.data.category_id);

    if (this.data.hasOwnProperty('children_id')) {
      this.form.get('children_id').patchValue(this.data.children_id);
    }

    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  get type() {
    return this.form.get('type').value;
  }

  update() {
    const form = this.form.value;
    const payload = this.data.type == 'subcategory' ? { id: this.data.id, name: form.name } : { id: this.data.children_id ? this.data.children_id : this.data.category_id, name: form.name, color: form.color, icon: form.icon, type: form.type, url: form.url };

    this.api.apiPut(this.data.type == 'subcategory' ? 'subcategory' : 'category', payload).subscribe(response => {
      this.utils.showToast('top-right', response.message, response.dados);
      this.ref.close({ update: true });
    });
  }

  delete() {
    this.api.apiDelete(this.data.type == 'subcategory' ? 'subcategory' : 'category', { id: this.data.id }).subscribe(response => {
      this.utils.showToast('top-right', response.message, response.dados);
      this.ref.close({ update: true });
    });
  }

}
