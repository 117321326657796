import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { TokenStorage } from '../auth/token-storage.service';
import { ApiBase } from './api-base.service';
import { ApiService } from './api.service';
import { ErrorDialogModule } from './error-dialog/error-dialog.module';

@NgModule({
  declarations: [
    // TokenStorage,
    // ApiService
  ],
  imports: [
    CommonModule,
    ErrorDialogModule,
  ],
  providers: [
    ApiBase,
    ApiService,
  ],
})
export class ApiModule { }
