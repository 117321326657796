import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  // {
  //   title: 'Home',
  //   icon: 'home-outline',
  //   link: '/pages/home',
  //   home: true,
  // },
  // {
  //   title: 'Processos',
  //   icon: 'swap-outline',
  //   link: '/processos',
  // },

  // {
  //   title: 'Cadeia de Valor',
  //   icon: 'bar-chart-2-outline',
  //   link: '/pages/tools/cadeia-valor',
  // },


  //Desativado a Pedido do Ramon
  // {
  //   title: 'Mídia',
  //   icon: 'film-outline',
  //   link: '/midia',
  // },

  {
    title: 'Plan. Estratégico',
    icon: 'trending-up-outline',
    link: '/planejamento-estrategico',
  },

  // {
  //   title: 'Categorias',
  //   group: true,
  // },
];
