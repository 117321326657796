import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealocarAgenciaComponent } from './realocar-agencia.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [RealocarAgenciaComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbTooltipModule,
    NgxMaskModule,

    FormsModule,
    ReactiveFormsModule
  ],
  exports: [RealocarAgenciaComponent],
  entryComponents: [RealocarAgenciaComponent]
})
export class RealocarAgenciaModule { }
