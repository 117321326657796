export const TYPES = [
    {
        type: "text",
        method: "text-viewer",
        ext: [
            "txt",
            "php",
            "c",
            "cpp",
            "h",
            "hpp",
            "js",
            "css",
        ]
    },
    {
        type: "image",
        method: "image-viewer",
        ext: [
            "gif",
            "png",
            "jpg",
            "jpeg",
            "bmp",
            "webp",
        ]
    },
    {
        type: "audio",
        method: "audio-viewer",
        ext: [
            "mp3",
            "wav"
        ]
    },
    {
        type: "video",
        method: "video-viewer",
        ext: [
            "mp4",
            "webm",
            "ogg"
        ]
    },
    {
        type: "pdf",
        method: "ngx-doc-viewer",
        ext: [
            "pdf"
        ]
    },
    {
        type: "office",
        method: "ngx-doc-viewer",
        ext: [
            "ppt",
            "pptx",
            "doc",
            "docx",
            "xls",
            "xlsx"
        ]
    },
    {
        type: "google",
        method: "ngx-doc-viewer",
        ext: [
            "html",
            "pages",
            "ai",
            "psd",
            "tiff",
            "dxf",
            "eps",
            "ps",
            "ttf",
            "xps",
            "zip",
            "rar",
            "csv",
            "svg"
        ]
    },
    {
        type: "url",
        method: "ngx-doc-viewer",
        ext: [
            "url"
        ]
    },
];

export const ICONS = [
    {
        icon: "assets/storage/file/ico/doc.png",
        ext: [
            "doc",
            "docm",
            "docx",
            "dotm",
            "dotx",
            "dot",
        ]
    },
    {
        icon: "assets/storage/file/ico/xls.png",
        ext: [
            "ods",
            "prn",
            "xlam",
            "xls",
            "xlsb",
            "xlsm",
            "xlsx",
            "xlt",
            "xltm",
            "xltx",
            "xlw",
            "dot"
        ]
    },
    {
        icon: "assets/storage/file/ico/ppt.png",
        ext: [
            "pptx",
            "pptm",
            "ppt",
            "ppa",
            "ppam",
            "pps",
            "ppsm",
            "ppsx",
            "pot",
            "potm",
            "potx"
        ]
    },
    {
        icon: "assets/storage/file/ico/pdf.png",
        ext: [
            "pdf"
        ]
    },
    {
        icon: "assets/storage/file/ico/csv.png",
        ext: [
            "csv"
        ]
    },
    {
        icon: "assets/storage/file/ico/txt.png",
        ext: [
            "txt"
        ]
    },
    {
        icon: "assets/storage/file/ico/mp3.png",
        ext: [
            "mp3",
            "wav"
        ]
    },
    {
        icon: "assets/storage/file/ico/mp4.png",
        ext: [
            "mp4",
            "webm",
            "ogg"
        ]
    },
    {
        icon: "assets/storage/file/ico/svg.png",
        ext: [
            "svg",
        ]
    },
    {
        icon: "assets/storage/file/ico/file.png",
        ext: [
            "default"
        ]
    }
];

export const EXTENSIONS = {
    "hqx": [
        "application/mac-binhex40",
    ],
    "cpt": [
        "application/mac-compactpro",
    ],
    "csv": [
        "text/x-comma-separated-values",
        "text/comma-separated-values",
        "application/octet-stream",
    ],
    "bin": [
        "application/macbinary",
    ],
    "dms": [
        "application/octet-stream",
    ],
    "lha": [
        "application/octet-stream",
    ],
    "lzh": [
        "application/octet-stream",
    ],
    "exe": [
        "application/octet-stream",
        "application/x-msdownload",
    ],
    "class": [
        "application/octet-stream",
    ],
    "psd": [
        "application/x-photoshop",
    ],
    "so": [
        "application/octet-stream",
    ],
    "sea": [
        "application/octet-stream",
    ],
    "dll": [
        "application/octet-stream",
    ],
    "oda": [
        "application/oda",
    ],
    "pdf": [
        "application/pdf",
        "application/x-download",
    ],
    "ai": [
        "application/postscript",
    ],
    "eps": [
        "application/postscript",
    ],
    "ps": [
        "application/postscript",
    ],
    "smi": [
        "application/smil",
    ],
    "smil": [
        "application/smil",
    ],
    "mif": [
        "application/vnd.mif",
    ],
    "xls": [
        "application/excel",
        "application/vnd.ms-excel",
        "application/msexcel",
    ],
    "ppt": [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
    ],
    "wbxml": [
        "application/wbxml",
    ],
    "wmlc": [
        "application/wmlc",
    ],
    "dcr": [
        "application/x-director",
    ],
    "dir": [
        "application/x-director",
    ],
    "dxr": [
        "application/x-director",
    ],
    "dvi": [
        "application/x-dvi",
    ],
    "gtar": [
        "application/x-gtar",
    ],
    "gz": [
        "application/x-gzip",
    ],
    "php": [
        "application/x-httpd-php",
        "text/x-php",
    ],
    "php4": [
        "application/x-httpd-php",
    ],
    "php3": [
        "application/x-httpd-php",
    ],
    "phtml": [
        "application/x-httpd-php",
    ],
    "phps": [
        "application/x-httpd-php-source",
    ],
    "js": [
        "application/x-javascript",
    ],
    "swf": [
        "application/x-shockwave-flash",
    ],
    "sit": [
        "application/x-stuffit",
    ],
    "tar": [
        "application/x-tar",
    ],
    "tgz": [
        "application/x-tar",
        "application/x-gzip-compressed",
    ],
    "xhtml": [
        "application/xhtml+xml",
    ],
    "xht": [
        "application/xhtml+xml",
    ],
    "zip": [
        "application/x-zip",
        "application/zip",
        "application/x-zip-compressed",
    ],
    "mid": [
        "audio/midi",
    ],
    "midi": [
        "audio/midi",
    ],
    "mpga": [
        "audio/mpeg",
    ],
    "mp2": [
        "audio/mpeg",
    ],
    "mp3": [
        "audio/mpeg",
        "audio/mpg",
        "audio/mpeg3",
        "audio/mp3",
    ],
    "aif": [
        "audio/x-aiff",
    ],
    "aiff": [
        "audio/x-aiff",
    ],
    "aifc": [
        "audio/x-aiff",
    ],
    "ram": [
        "audio/x-pn-realaudio",
    ],
    "rm": [
        "audio/x-pn-realaudio",
    ],
    "rpm": [
        "audio/x-pn-realaudio-plugin",
    ],
    "ra": [
        "audio/x-realaudio",
    ],
    "rv": [
        "video/vnd.rn-realvideo",
    ],
    "wav": [
        "audio/x-wav",
    ],
    "bmp": [
        "image/bmp",
    ],
    "gif": [
        "image/gif",
    ],
    "jpeg": [
        "image/jpeg",
        "image/pjpeg",
    ],
    "jpg": [
        "image/jpeg",
        "image/pjpeg",
    ],
    "jpe": [
        "image/jpeg",
        "image/pjpeg",
    ],
    "png": [
        "image/png",
    ],
    "tiff": [
        "image/tiff",
    ],
    "tif": [
        "image/tiff",
    ],
    "css": [
        "text/css",
    ],
    "html": [
        "text/html",
    ],
    "htm": [
        "text/html",
    ],
    "shtml": [
        "text/html",
    ],
    "txt": [
        "text/plain",
    ],
    "text": [
        "text/plain",
    ],
    "log": [
        "text/plain",
        "text/x-log",
    ],
    "rtx": [
        "text/richtext",
    ],
    "rtf": [
        "text/rtf",
    ],
    "xml": [
        "text/xml",
    ],
    "xsl": [
        "text/xml",
    ],
    "mpeg": [
        "video/mpeg",
    ],
    "mpg": [
        "video/mpeg",
    ],
    "mpe": [
        "video/mpeg",
    ],
    "qt": [
        "video/quicktime",
    ],
    "mov": [
        "video/quicktime",
    ],
    "avi": [
        "video/x-msvideo",
    ],
    "mp4": [
        "video/mp4",
    ],
    "wmv": [
        "video/x-ms-asf",
    ],
    "movie": [
        "video/x-sgi-movie",
    ],
    "doc": [
        "application/msword",
    ],
    "docx": [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    "xlsx": [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    "word": [
        "application/msword",
        "application/octet-stream",
    ],
    "xl": [
        "application/excel",
    ],
    "eml": [
        "message/rfc822",
    ],
    "json": [
        "application/json",
        "text/json"
    ],
};