import { AuthenticationModule } from './auth/authentication.module';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';

import { ApiModule } from './api/api.module';
import { UpdateService } from './update/update.service';
import { LayoutService, StateService, UtilsService } from './utils';


export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    return observableOf('guest');
  }
}

@NgModule({
  imports: [
    CommonModule,
    AuthenticationModule,
    ApiModule,
  ],
  exports: [
    NbAuthModule,
  ],
  providers: [
    UpdateService,
    LayoutService,
    StateService,
    UtilsService,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
    };
  }
}
