import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { NbAlertModule, NbCardModule } from '@nebular/theme';



@NgModule({
  declarations: [BannerComponent],
  imports: [
    CommonModule,

    NbCardModule,
    NbAlertModule,
  ]
})
export class BannerModule { }
