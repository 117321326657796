import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiBase } from '../../../@core/api/api-base.service';

@Component({
  selector: 'ngx-tarifas-pessoa-juridica',
  templateUrl: './tarifas-pessoa-juridica.component.html',
  styleUrls: ['./tarifas-pessoa-juridica.component.scss']
})
export class TarifasPessoaJuridicaComponent implements OnInit {

  //Tabela
  settingsServicos = {
    mode: "external",
    noDataMessage: "Não há dados.",
    actions: {
      columnTitle: "Ações",
      add: false,
      edit: false,
      delete: false,
      position: "right",
    },
    hideSubHeader: true,
    columns: {
      pacote: {
        title: 'Pacote',
      },
      valor: {
        title: 'Valor',
        valuePrepareFunction(value, row, cell) {
          if (value == 0) {
            return 'isento'
          } else {
            return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
          }
        }
      },
      ted: {
        title: 'TEDs'
      },
      cheques: {
        title: 'Cheques',
      },
      extratos_30_dias: {
        title: 'Extratos 30 dias',
        valuePrepareFunction(value, row, cell) {
          if (value == -1) {
            return 'ilimitado'
          } else {
            return value;
          }
        }
      },
      extratos_anteriores: {
        title: 'Extratos anteriores',
        valuePrepareFunction(value, row, cell) {
          if (value == -1) {
            return 'ilimitado'
          } else {
            return value;
          }
        }
      },
      extratos_mobile: {
        title: 'Extratos Sicoobnet',
        valuePrepareFunction(value, row, cell) {
          if (value == -1) {
            return 'ilimitado'
          } else {
            return value;
          }
        }
      },
      saque_atm: {
        title: 'Saques ATM',
        valuePrepareFunction(value, row, cell) {
          if (value == -1) {
            return 'ilimitado'
          } else {
            return value;
          }
        }
      },
      saque_presencial: {
        title: 'Saques (P)',
        valuePrepareFunction(value, row, cell) {
          if (value == -1) {
            return 'ilimitado'
          } else {
            return value;
          }
        }
      },
      transferencia_atm: {
        title: 'Transf. ATM',
        valuePrepareFunction(value, row, cell) {
          if (value == -1) {
            return 'ilimitado'
          } else {
            return value;
          }
        }
      },
      revogacao_sustacao: {
        title: 'Sustação',
      },
      cadastro: {
        title: 'Cadastro',
        valuePrepareFunction(value, row, cell) {
          if (value == 0) {
            return 'isento'
          } else {
            return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
          }
        }
      },
    },
  };

  public sourceServicos: LocalDataSource;

  constructor(
    private api: ApiBase,
  ) {
    this.sourceServicos = new LocalDataSource();
  }

  ngOnInit() {
    this.updateSource();
  }

  updateSource() {

    //Buscar Id do Tipo de Contrato
    this.api.apiGet("permissoes", []).subscribe(
      response => {
        // console.log(response);
        console.log('Pacotes Pessoa Jurídica');

        this.sourceServicos.load(
          [
            {
              pacote: 'I',
              valor: 24.5,
              cadastro: 0,
              cheques: 20,
              revogacao_sustacao: 1,
              extratos_30_dias: 4,
              extratos_anteriores: 1,
              extratos_mobile: -1,
              saque_atm: -1,
              saque_presencial: -1,
              transferencia_atm: -1,
              ted: 1,
            },
            {
              pacote: 'II',
              valor: 50,
              cadastro: 0,
              cheques: 40,
              revogacao_sustacao: 1,
              extratos_30_dias: 6,
              extratos_anteriores: 2,
              extratos_mobile: -1,
              saque_atm: -1,
              saque_presencial: -1,
              transferencia_atm: -1,
              ted: 5,
            },
            {
              pacote: 'III',
              valor: 88,
              cadastro: 0,
              cheques: 100,
              revogacao_sustacao: 1,
              extratos_30_dias: 6,
              extratos_anteriores: -1,
              extratos_mobile: -1,
              saque_atm: -1,
              saque_presencial: -1,
              transferencia_atm: -1,
              ted: 15,
            },
            {
              pacote: 'IV',
              valor: 126.5,
              cadastro: 0,
              cheques: 120,
              revogacao_sustacao: 1,
              extratos_30_dias: 10,
              extratos_anteriores: -1,
              extratos_mobile: -1,
              saque_atm: -1,
              saque_presencial: -1,
              transferencia_atm: -1,
              ted: 20,
            },
            {
              pacote: 'Premium',
              valor: 164,
              cadastro: 0,
              cheques: 200,
              revogacao_sustacao: 3,
              extratos_30_dias: -1,
              extratos_anteriores: -1,
              extratos_mobile: -1,
              saque_atm: -1,
              saque_presencial: -1,
              transferencia_atm: -1,
              ted: 30,
            },
          ]
        );
      },
      error => {
        console.log(error);
      }
    );
  }

}
