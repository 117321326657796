import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelPostComponent } from './model-post.component';
import { NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [ModelPostComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,

    FormsModule,
    ReactiveFormsModule,

    QuillModule
  ],
  exports: [ModelPostComponent],
  entryComponents: [ModelPostComponent],
})
export class ModelPostModule { }
