import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Validators, FormBuilder } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { RamaisComponent } from '../ramais/ramais.component';
import { UtilsService } from '../@core/utils';
import { ApiBase } from '../@core/api/api-base.service';

@Component({
  selector: 'ngx-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.scss']
})
export class TarifasComponent implements OnInit {

  STG_URL:string = environment.STG_URL + '/';

  loading = true;
  loadingSearch = true;

  pagina = 'geral';

  params: any;

  constructor(
    protected dialogRef: NbDialogRef<RamaisComponent>,
    private util: UtilsService,
    private fb: FormBuilder,
    private api: ApiBase,
  ) { 
    
  }

  ngOnInit() {

  }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }

  navegar(pagina: string){
    this.pagina = pagina;
  }

}