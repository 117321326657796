import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from '../auth/token-storage.service';
// import { UtilsService } from '../services/utils.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private API_URL = environment.API_URL + '/';
  private IMG_URL = environment.IMG_URL + '/';
  // private API_URL = "http://10.151.2.149:8094/api/";
  // private IMG_URL = "http://10.151.2.149:8094/api/";
  private TOKEN;
  private httpOptions: any;
  private user = JSON.parse(localStorage.getItem('user'));

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage,
    // private util: UtilsService,
    private router: Router,
  ) {
    let token: string = <string>localStorage.getItem('Token');
    this.TOKEN = token;

    let headers: HttpHeaders = new HttpHeaders();

    if (token != null) {

      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Accept', 'application/json, text/plain, */*');
      headers = headers.append("Api-Token", this.user.key_access);
      headers = headers.append("Authorization", this.TOKEN);
    }

    this.httpOptions = {
      headers: headers
    };
  }

  public getToken() {
    return this.TOKEN.value;
  }
  public getUrl() {
    return this.API_URL;
  }
  public getUrlImg() {
    return this.IMG_URL;
  }
  public getUrlFavicon(url: string) {
    return "https://www.google.com/s2/favicons?domain=" + url;
  }

	/**
	 * Submit login request
	 * @param {String} ROTA
	 * @returns {Observable<any>}
	 */
  public apiGet(ROTA: string): Observable<any> {
    // Expecting response from API
    return this.http.get(this.API_URL + ROTA, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

	/**
	   * Submit login request
	   * @param {String} ROTA
	   * @returns {Observable<any>}
	   */
  public apiPost(ROTA: string, data: any): Observable<any> {
    // Expecting response from API
    return this.http.post(this.API_URL + ROTA, data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

	/**
	   * Submit login request
	   * @param {String} credential
	   * @returns {Observable<any>}
	   */
  public apiPut(ROTA: string, data: any): Observable<any> {
    // Expecting response from API
    return this.http.put(this.API_URL + ROTA, data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

	/**
	   * Submit login request
	   * @param {String} ROTA
	   * @returns {Observable<any>}
	   */
  public apiDelete(ROTA: string): Observable<any> {
    // Expecting response from API
    return this.http.delete(this.API_URL + ROTA, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

	/**
	   * Handle Http operation that failed.
	   * Let the app continue.
	   * @param operation - name of the operation that failed
	   * @param result - optional value to return as the observable result
	   */
  private handleError(error: HttpErrorResponse) {
    console.log("esse", error);

    // const router = this.injector.get(Router);
    // if (error.rejection.status === 401 || error.rejection.status === 403) {
    if (error.status === 401) {
      console.log("erro 401", error);

      // this.router.navigate(['/login']);
    }

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Um erro ocorreu:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Código retornado de back-end ${error.status}, ` +
        `corpo foi: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }


}
