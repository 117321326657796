import { Component, OnInit, Input } from '@angular/core';
import { ApiBase } from '../../../@core/api/api-base.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ApiVariavel } from '../../../@core/api/variavel.service';

// "current_page": 1,
// "data": [],
// "first_page_url": "http:\/\/10.151.2.149:8097\/api\/geral?page=1",
// "from": 1,
// "last_page": 1151,
// "last_page_url": "http:\/\/10.151.2.149:8097\/api\/geral?page=1151",
// "next_page_url": "http:\/\/10.151.2.149:8097\/api\/geral?page=2",
// "path": "http:\/\/10.151.2.149:8097\/api\/geral",
// "per_page": 10,
// "prev_page_url": null,
// "to": 10,
// "total": 11504

interface Pagination {
  current_page: number,
  data: any,
  first_page_url: string,
  from: number,
  last_page: number,
  last_page_url: string,
  next_page_url: string,
  path: string,
  per_page: number,
  prev_page_url: any,
  to: number,
  total: number
};

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  production = environment.production;

  paginationData: Pagination = {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    next_page_url: '',
    path: '',
    per_page: 1,
    prev_page_url: '',
    to: 1,
    total: 1
  };
  justificationData?: string;

  paginationItems = [];

  @Input() pagination: any = this.paginationData;
  @Input() align: any;
  @Input() smartTable: any;
  @Input() params: any;

  formIrPara = this.fb.group({
    numero_pagina: ['',
      [
        Validators.min(1),
        Validators.max(this.pagination.last_page),
      ]
    ],
  });

  constructor(private api: ApiBase, private fb: FormBuilder, ) {

    // console.log(this.paginationData);


    // this.paginationData = this.pagination;

    //Define o alinhamento
    if (typeof this.align !== undefined) {

      switch (this.align) {
        case 'left':
          this.justificationData = "pagination";
          break;
        case 'right':
          this.justificationData = "pagination justify-content-end";
          break;
        case 'center':
        default:
          this.justificationData = "pagination justify-content-center";
      }
    }

  }

  ngOnInit() {
    // console.log(this.pagination);
  }


  paginaAnterior() {

    // console.log("pagina Anterior");
    // console.log(this.paginationData);
    // console.log(this.pagination);

    if (this.pagination.prev_page_url == null) {
      console.log("Primeira página");

    } else {
      this.updatePageFromApi(this.pagination.prev_page_url);
    }

  }

  proximaPagina() {
    // console.log("proxima Pagina");
    // console.log(this.paginationData);
    // console.log(this.pagination);

    if (this.pagination.next_page_url == null) {
      console.log("Última página");

    } else {
      this.updatePageFromApi(this.pagination.next_page_url);
    }
  }

  primeiraPagina() {
    // console.log("proxima Pagina");
    // console.log(this.paginationData);
    // console.log(this.pagination);

    this.updatePageFromApi(this.pagination.first_page_url);
  }

  ultimaPagina() {
    // console.log("proxima Pagina");
    // console.log(this.paginationData);
    // console.log(this.pagination);

    this.updatePageFromApi(this.pagination.last_page_url);
  }

  irParaPagina(page) {
    console.log("Ir para", page);

    if (page <= this.pagination.last_page) {
      this.updatePageFromApi(this.pagination.path + "?page=" + page);
    }
  }

  irParaForm() {
    this.irParaPagina(this.formIrPara.value.numero_pagina);
  }

  updatePageFromApi(page_url) {

    const api_identifier = page_url.split('.local')[0].replace('http://', '').replace('https://', '');

    const api_url = Object.values(environment).find(api => api.toString().includes(api_identifier));

    const params = page_url.substr(page_url.indexOf('/api/') + 4, page_url.length - 1);
    
    console.log(api_url, params);

    page_url = api_url + params;

    var protUrl = page_url.substr(0, page_url.indexOf('//') -1);

    if (this.production) {
      page_url = 'https' + page_url.substr(protUrl.length);
    }

    //Get next page
    this.api.apiGetFullPath(page_url, this.params).subscribe(
      response => {
        this.pagination = Object.values(response.dados)[0];
        this.atualizarSmartTable(this.pagination.data);
      },
      error => {
        console.log(error);
      }
    );
  }

  atualizarSmartTable(data) {
    this.smartTable.source.load(data);
  }

  classDefiner(itemId, current_page, last_page) {
    // console.log(a);

    // console.log(itemId, current_page);


    var displayClass = '';

    if (itemId == current_page) {
      displayClass = 'active';
    }

    if (itemId <= (current_page - 4) || itemId >= (current_page + 4)) {

      displayClass = 'd-none';

    } else {

      displayClass = '';

    }

    return 'page-item ' + displayClass;

  }
}