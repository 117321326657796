import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ApiColaboradores } from '../../../../../../@core/api/colaboradores.service';
import { UtilsService } from '../../../../../../@core/utils';

@Component({
  selector: 'ngx-colaboradores-realocar',
  templateUrl: './colaboradores-realocar.component.html',
  styleUrls: ['./colaboradores-realocar.component.scss']
})
export class ColaboradoresRealocarComponent implements OnInit {

  @Input() data: any;

  formRealocar = this.fb.group({
    codigo: [{value: '', disabled: true}, Validators.required],
    nome: [{value: '', disabled: true}],
    centro_custo: [{value: '', disabled: true}],
    novo_centro_custo: [null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(3)]],
    nome_novo_centro_custo: [{value: '', disabled: true}],
  });

  lista_agencias: any;

  constructor(private fb: FormBuilder, protected ref: NbDialogRef<ColaboradoresRealocarComponent>, private utils: UtilsService, private api: ApiColaboradores) { }

  ngOnInit() {
    this.api.apiGet('ficticio/todos-pas', '').subscribe(result => {
      this.lista_agencias = result.dados;
    });
    
    this.formRealocar.get('codigo').patchValue(this.data.codigo);
    this.formRealocar.get('nome').patchValue(this.data.nome);
    this.formRealocar.get('centro_custo').patchValue(this.data.nome_pa + ' (PA ' + this.data.centro_custo + ')');

  }

  get novo_centro_custo() {
    return this.formRealocar.get('nome_novo_centro_custo').value;
  }

  close(){
    this.ref.close();
  }

  realocar(){
    
    let formValue = this.formRealocar.getRawValue();

    let data = {
      codigo: formValue.codigo,
      centro_custo_old: this.data.centro_custo,
      centro_custo_novo: formValue.novo_centro_custo,
      transferencia: 0,
    };

    this.api.apiPost('realocados/novo', data).subscribe(response => {

      if(response.status == 200){

        this.ref.close();
        this.utils.showToast('top-right', 'success', 'Yeah!', 'Colaborador transferido com sucesso!');

      } else {
        this.utils.showToast('top-right', 'danger', 'Ops...', 'Erro na transferência do colaborador.');
      }

    })
    
  }

  old = 0;

  selecionarPa($event) {

    let registry = this.formRealocar.get('novo_centro_custo');

    if (registry.valid) {

      if (this.old != $event.target.value) {
        this.old = registry.value;

        let agencia = this.lista_agencias[$event.target.value];

        if (agencia != undefined && agencia != null) {
          this.formRealocar.get('nome_novo_centro_custo').patchValue(agencia[0].nome_pa);
        } else {
          this.formRealocar.get('nome_novo_centro_custo').patchValue('');
        }

      }
    } else {
      this.formRealocar.get('nome_novo_centro_custo').patchValue('');
    }

  }

}
