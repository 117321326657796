import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { ICONS, TYPES, EXTENSIONS } from '../../storage/storage-mimetypes';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ConfirmationComponent } from '../../@theme/components/confirmation/confirmation.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InfoComponent } from '../../@theme/components/info/info.component';
import { info } from 'console';

@Injectable()
export class UtilsService {

  constructor(
    private toastrService: NbToastrService,
    private nbDialog: NbDialogService,
    private sanitizer: DomSanitizer
  ) { }

  showToast(position, status, title, msg?) {
    this.toastrService.show(
      msg,
      title,
      { position, status }
    );
  }

  public showConfirmation(title, message, status = 'info'): Observable<any> {
    return this.nbDialog.open(ConfirmationComponent, {
      context: {
        status: status,
        title: title,
        message: message
      }
    }).onClose.pipe(map((response: any) => {
      return response;
    }));
  }

  public showInfo(title, message, status = 'info'): Observable<any> {
    return this.nbDialog.open(InfoComponent, {
      context: {
        title: title,
        message: message,
        status: status
      }
    }).onClose.pipe(map((response: any) => {
      return response;
    }));
  }

  // to remove error : Error: unsafe value used in a resource URL context
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }


  getCurrentYear() {
    return new Date().getFullYear();
  }

  get currentMonth() {
    return new Date().getMonth() + 1;
  }

  /**
   * Build url parameters key and value pairs from array or object
   * @param obj
   */
  urlParam(obj: any): string {
    return Object.keys(obj)
      .map(k => k + '=' + encodeURIComponent(obj[k]))
      .join('&');
  }


  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   * @see https://stackoverflow.com/a/34749873/1316921
   */
  mergeDeep(target, ...sources) {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  getPath(obj, val, path?) {
    path = path || '';
    let fullpath = '';
    for (const b in obj) {
      if (obj[b] === val) {
        return path + '/' + b;
      } else if (typeof obj[b] === 'object') {
        fullpath =
          this.getPath(obj[b], val, path + '/' + b) || fullpath;
      }
    }
    return fullpath;
  }

  getFindHTTPParams(queryParams): HttpParams {
    const params = new HttpParams()
      .set('lastNamefilter', queryParams.filter)
      .set('sortOrder', queryParams.sortOrder)
      .set('sortField', queryParams.sortField)
      .set('pageNumber', queryParams.pageNumber.toString())
      .set('pageSize', queryParams.pageSize.toString());

    return params;
  }

  getHTTPHeader() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  getNomeMes(mesNumero: number, simplified: boolean = false) {
    var nomes = [
      "Dezembro",
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro"
    ];
    var mesId = (mesNumero % 12);
    var nomeMes = (simplified ? nomes[mesId].substr(0, 3) : nomes[mesId]);
    return nomeMes;
  }

  //get numger of month
  getMonthNumber(monthName) {
    var monthNames = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];
    var monthNumber = monthNames.indexOf(monthName.toLowerCase()) + 1;
    return monthNumber;
  }

  getNomeDia(diaNumero: number, simplified: boolean = false) {
    var nomes = [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feria",
      "Sabado"
    ];
    var nomeId = (diaNumero % 7);
    var nomeDia = (simplified ? nomes[nomeId].substr(0, 3) : nomes[nomeId]);
    return nomeDia;
  }

  getStateFromUF(uf) {

    let data = "";

    switch (uf.toUpperCase()) {
      case "AC": data = "Acre"; break;
      case "AL": data = "Alagoas"; break;
      case "AM": data = "Amazonas"; break;
      case "AP": data = "Amapá"; break;
      case "BA": data = "Bahia"; break;
      case "CE": data = "Ceará"; break;
      case "DF": data = "Distrito Federal"; break;
      case "ES": data = "Espírito Santo"; break;
      case "GO": data = "Goiás"; break;
      case "MA": data = "Maranhão"; break;
      case "MG": data = "Minas Gerais"; break;
      case "MS": data = "Mato Grosso do Sul"; break;
      case "MT": data = "Mato Grosso"; break;
      case "PA": data = "Pará"; break;
      case "PB": data = "Paraíba"; break;
      case "PE": data = "Pernambuco"; break;
      case "PI": data = "Piauí"; break;
      case "PR": data = "Paraná"; break;
      case "RJ": data = "Rio de Janeiro"; break;
      case "RN": data = "Rio Grande do Norte"; break;
      case "RO": data = "Rondônia"; break;
      case "RR": data = "Roraima"; break;
      case "RS": data = "Rio Grande do Sul"; break;
      case "SC": data = "Santa Catarina"; break;
      case "SE": data = "Sergipe"; break;
      case "SP": data = "São Paulo"; break;
      case "TO": data = "Tocantíns"; break;
    }
    return data;
  }

  get uf() {
    return [
      { id: "AC", name: "Acre" },
      { id: "AL", name: "Alagoas" },
      { id: "AM", name: "Amazonas" },
      { id: "AP", name: "Amapá" },
      { id: "BA", name: "Bahia" },
      { id: "CE", name: "Ceará" },
      { id: "DF", name: "Distrito Federal" },
      { id: "ES", name: "Espírito Santo" },
      { id: "GO", name: "Goiás" },
      { id: "MA", name: "Maranhão" },
      { id: "MG", name: "Minas Gerais" },
      { id: "MS", name: "Mato Grosso do Sul" },
      { id: "MT", name: "Mato Grosso" },
      { id: "PA", name: "Pará" },
      { id: "PB", name: "Paraíba" },
      { id: "PE", name: "Pernambuco" },
      { id: "PI", name: "Piauí" },
      { id: "PR", name: "Paraná" },
      { id: "RJ", name: "Rio de Janeiro" },
      { id: "RN", name: "Rio Grande do Norte" },
      { id: "RO", name: "Rondônia" },
      { id: "RR", name: "Roraima" },
      { id: "RS", name: "Rio Grande do Sul" },
      { id: "SC", name: "Santa Catarina" },
      { id: "SE", name: "Sergipe" },
      { id: "SP", name: "São Paulo" },
      { id: "TO", name: "Tocantíns" }
    ]
  }

  extractDate(date, withTime = false) {
    let locale = new Date(date).toLocaleString();
    return withTime ? locale.split(" ")[0] + " às " + locale.split(" ")[1] : locale.split(" ")[0];
  }

  phoneMask(length: number) {
    return length <= 10 ? '(00) 0000-00009' : '(00) 0 0000-0000';
  }

  /**
     * Formatar numero para texto
     * @param n valor à ser formatado
     * @param digits digitos decimais
     * @param currency formatar como moeda local
     * @param scale escala de milhar[1= mil, 2=mi, 3=bi, 4=tri, 5=qua, a=automatica]
     * @param precision precisão da escala automatica, define quantos separadores de milhar devem aparecer
     * @param maxdigits numeros maximo de digitos decimais
     * @returns {string}
     */

  abbreviate_number = function (num, fixed) {
    if (num === null) { return null; } // terminate early
    if (num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      // c = -1,
      // d = new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL', maximumFractionDigits: 3 }).format(c), // enforce -0 is 0
      e = c + ' ' + ['', 'mil', 'mi', 'bi', 'tri'][k]; // append power

    console.error(c)
    return e;
  }

  numFormat(n: number, digits: number = 2, currency: boolean = false, scale: any = 'a', precision: number = 0, maxdigits: number = 2) {
    // Utilização
    // n         = Valor [float]
    // digits    = Digitos decimais [int]
    // currency  = Se é moeda [true]
    // scale     = Escala de milhar[1= mil, 2=mi, 3=bi, a=automatica]
    // precision = Precisão da escala automatica, define quantos separadores de milhar devem aparecer

    var style = 'decimal';

    if (currency) {
      style = 'currency';
    }

    if (!(typeof precision !== 'undefined')) {
      var precision = 0;
    }

    var text = "";
    switch (scale) {
      case 1:
        n = n / 1000;
        text = "mil";
        break;
      case 2:
        n = n / 1000000;
        text = "mi";
        break;
      case 3:
        n = n / 1000000000;
        text = "bi";
        break;
      case 4:
        n = n / 1000000000000;
        text = "tri";
        break;
      case 5:
        n = n / 1000000000000000;
        text = "qua";
        break;
      case "a":
        return this.numFormat(n, digits, currency, (Math.floor(Math.floor((n < 0 ? n * -1 : n)).toString().length / 3) - precision), precision, maxdigits);
      default:
        text = "";
    }

    var parameters =
    {
      style: style,
      locale: 'pt-BR',
    }

    if (currency) { parameters['currency'] = 'BRL'; }
    if (digits > 0) { parameters['minimumFractionDigits'] = digits; }
    if (maxdigits > 0) { parameters['maximumFractionDigits'] = maxdigits; }

    // Intl.NumberFormat('pt-BR', { style: (row.tipo == "qtd" ? 'decimal' : 'currency'), currency: 'BRL' }).format(value);

    var number = Intl.NumberFormat(parameters.locale, parameters).format(n);

    return number + " " + text;
  }

  formatCurrency(value) {
    return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
  }

  getArrayListItems(array, key) {
    var list = [];
    array.forEach(array => {
      list.push(array[key]);
    });
    return list;
  }

  levenstein(a = "", b = "") {
    var m = [], i, j, min = Math.min;
    if (!(a && b)) return (b || a).length;
    for (i = 0; i <= b.length; m[i] = [i++]);
    for (j = 0; j <= a.length; m[0][j] = j++);
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        m[i][j] = b.charAt(i - 1) == a.charAt(j - 1)
          ? m[i - 1][j - 1]
          : m[i][j] = min(
            m[i - 1][j - 1] + 1,
            min(m[i][j - 1] + 1, m[i - 1][j]))
      }
    }
    return m[b.length][a.length];
  }

  soundex(s) {
    var a = s.toLowerCase().split(''),
      f = a.shift(),
      r = '',
      codes = {
        a: '', e: '', i: '', o: '', u: '',
        b: 1, f: 1, p: 1, v: 1,
        c: 2, g: 2, j: 2, k: 2, q: 2, s: 2, x: 2, z: 2,
        d: 3, t: 3,
        l: 4,
        m: 5, n: 5,
        r: 6
      };
    r = f +
      a
        .map(function (v, i, a) { return codes[v] })
        .filter(function (v, i, a) {
          return ((i === 0) ? v !== codes[f] : v !== a[i - 1]);
        })
        .join('');
    return (r + '000').slice(0, 4).toUpperCase();
  }

  TYPES: any = TYPES;
  ICONS: any = ICONS;
  EXTENSIONS: any = EXTENSIONS;

  // Extrai a extensão do nome do arquivo
  getFileExt(filename) {
    return filename.substr(filename.lastIndexOf('.')) || false;
  }

  // Busca informações do tipo de arquivo
  getFileType(extension) {
    var fileType = this.TYPES.filter(
      type => {
        return type.ext.includes(extension.toLowerCase());
      }
    );
    return fileType[0] || { type: "undefined" }
  }

  // Busca informações do tipo de arquivo
  getFileTypeByName(typeName) {
    var fileType = this.TYPES.filter(
      type => {
        return type.type == typeName.toLowerCase();
      }
    );
    return fileType[0] || false
  }

  // Busca o icone da extensão
  getExtIcon(extension) {
    var extIcon: any;
    var defIcon: any;

    // Busca o icone pela extensão
    extIcon = [...this.ICONS].filter(icon => {
      return icon.ext.includes(extension.toLowerCase());
    }
    );
    // retorno padrão
    defIcon = [...this.ICONS].filter(icon => {
      return icon.ext.includes('default');
    }
    );
    return extIcon.length > 0 ? extIcon[0].icon : defIcon[0].icon;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, fileName + ' - ' + new DatePt().getPtDayString(new Date()) + '.xlsx');
  }

  get uuid() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export class LoaderCounter {
  loader: any;
  constructor() {
    this.set({
      show: true,
      total: 0,
      count: 0,
    });
  }
  get() {
    return this.loader;
  }
  set(loader) {
    this.loader = loader;
  }
  addCount() {
    this.loader.count++;
    this.check();
  }
  addTotal() {
    this.loader.total++;
    this.check();
  }
  removeTotal() {
    this.loader.total++;
    this.check();
  }
  check() {
    this.loader.show = this.loader.total == this.loader.count;
  }
  show() {
    return this.loader.show;
  }
}

export class DateValidator {

  static ptDate(control: FormControl): { [key: string]: any } {
    let ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

    if (!control.value.match(ptDatePattern))
      return { "ptDate": true };

    return null;
  }

  static usDate(control: FormControl): { [key: string]: any } {
    let usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

    if (!control.value.match(usDatePattern))
      return { "usDate": true };

    return null;
  }
}

export class DatePt {

  ptDayToDate(dateString: string, separator: string = '/'): Date {
    var dateParts = dateString.split(separator);
    return new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]))
  }

  getPtDayString(date: Date, separator: string = '/'): string {
    return ('00' + (date.getUTCDate())).substr(-2) + separator + ('00' + (date.getMonth() + 1)).substr(-2) + separator + date.getFullYear();
  }
}

export class NumberPt {

  ptToNumber(numberString: string): number {
    return parseFloat(numberString.replace('.', '_').replace(',', '.'));
  }
}


export class FileValidator {

  static fileMaxSize(maxSize: number): { [key: string]: any } {
    const validatorFn = (file: File) => {
      if (file instanceof File && file.size > maxSize) {
        return { "fileMinSize": { requiredSize: maxSize, actualSize: file.size, file } };
      }
    };
    return FileValidator.fileValidation(validatorFn);
  }

  static fileMinSize(minSize: number): { [key: string]: any } {
    const validatorFn = (file: File) => {
      if (file instanceof File && file.size < minSize) {
        return { "fileMinSize": { requiredSize: minSize, actualSize: file.size, file } };
      }
    };
    return FileValidator.fileValidation(validatorFn);
  }

  /**
   * extensions must not contain dot
   */
  static fileExtensions(allowedExtensions: Array<string>): { [key: string]: any } {

    const validatorFn = (file: File) => {
      if (allowedExtensions.length === 0) {
        return null;
      }
      if (file instanceof File) {
        const ext = FileValidator.getExtension(file.name);
        if (allowedExtensions.indexOf(ext) === -1) {
          return { "fileExtension": { allowedExtensions: allowedExtensions, actualExtension: file.type, file } };
        }
      }
      if (typeof file == 'string') {
        const ext = FileValidator.getExtension(file);
        if (allowedExtensions.indexOf(ext) === -1) {
          return { "fileExtension": { allowedExtensions: allowedExtensions, actualExtension: ext, filename: file } };
        }
      }
    };
    return FileValidator.fileValidation(validatorFn);
  }

  private static getExtension(filename: string): null | string {
    if (filename.indexOf('.') === -1) {
      return null;
    }
    return filename.split('.').pop();
  }

  private static fileValidation(validatorFn: (File) => null | object): { [key: string]: any } {
    return (formControl: FormControl) => {

      console.log(formControl);


      if (!formControl.value) {
        return null;
      }

      const files: File[] = [];
      const isMultiple = Array.isArray(formControl.value);
      isMultiple
        ? formControl.value.forEach((file: File) => files.push(file))
        : files.push(formControl.value);

      for (const file of files) {
        return validatorFn(file);
      }

      return null;
    };
  }
}

export function useInterval(callback: any, interval: number): Interval {
  return new Interval(callback, interval)
}

class Interval {

  timer: any = null

  constructor(private callback: any, private interval: number) {
  }

  start() {
    this.timer = setInterval(this.callback, this.interval)
  }

  stop() {
    clearInterval(this.timer)
    this.timer = null
  }

  restart(interval = 0) {
    this.stop()

    if (interval) {
      this.interval = interval
    }

    this.start()
  }
}

