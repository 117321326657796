export const data = [
  {
    "name": "activity-outline"
  },
  {
    "name": "activity"
  },
  {
    "name": "alert-circle-outline"
  },
  {
    "name": "alert-circle"
  },
  {
    "name": "alert-triangle-outline"
  },
  {
    "name": "alert-triangle"
  },
  {
    "name": "done-all"
  },
  {
    "name": "archive-outline"
  },
  {
    "name": "archive"
  },
  {
    "name": "arrow-circle-down-outline"
  },
  {
    "name": "arrow-circle-down"
  },
  {
    "name": "arrow-circle-left-outline"
  },
  {
    "name": "arrow-circle-left"
  },
  {
    "name": "arrow-circle-right-outline"
  },
  {
    "name": "arrow-circle-right"
  },
  {
    "name": "arrow-circle-up-outline"
  },
  {
    "name": "arrow-circle-up"
  },
  {
    "name": "arrow-down"
  },
  {
    "name": "arrow-down-outline"
  },
  {
    "name": "arrow-down-outline"
  },
  {
    "name": "arrow-down"
  },
  {
    "name": "arrow-ios-downward-outline"
  },
  {
    "name": "arrow-ios-downward"
  },
  {
    "name": "arrow-ios-back-outline"
  },
  {
    "name": "arrow-ios-back"
  },
  {
    "name": "arrow-ios-forward-outline"
  },
  {
    "name": "arrow-ios-forward"
  },
  {
    "name": "arrow-ios-upward-outline"
  },
  {
    "name": "arrow-ios-upward"
  },
  {
    "name": "arrow-left-outline"
  },
  {
    "name": "arrow-left"
  },
  // {
  //   "name": "arrow-right-outline-1"
  // },
  {
    "name": "arrow-right-outline"
  },
  {
    "name": "arrow-right"
  },
  {
    "name": "arrow-up-outline"
  },
  {
    "name": "arrow-up"
  },
  {
    "name": "arrowhead-down-outline"
  },
  {
    "name": "arrowhead-down"
  },
  {
    "name": "arrowhead-left-outline"
  },
  {
    "name": "arrowhead-left"
  },
  {
    "name": "arrowhead-right-outline"
  },
  {
    "name": "arrowhead-right"
  },
  {
    "name": "arrowhead-up-outline"
  },
  {
    "name": "arrowhead-up"
  },
  {
    "name": "at-outline"
  },
  {
    "name": "at"
  },
  {
    "name": "attach-2-outline"
  },
  {
    "name": "attach-2"
  },
  {
    "name": "attach-outline"
  },
  {
    "name": "attach"
  },
  {
    "name": "award-outline"
  },
  {
    "name": "award"
  },
  {
    "name": "backspace-outline"
  },
  {
    "name": "backspace"
  },
  {
    "name": "bar-chart-2-outline"
  },
  {
    "name": "bar-chart-2"
  },
  {
    "name": "bar-chart-outline"
  },
  {
    "name": "bar-chart"
  },
  {
    "name": "battery-outline"
  },
  {
    "name": "battery"
  },
  {
    "name": "charging-outline"
  },
  {
    "name": "charging"
  },
  {
    "name": "behance-outline"
  },
  {
    "name": "behance"
  },
  {
    "name": "bell-off-outline"
  },
  {
    "name": "bell-off"
  },
  {
    "name": "bell-outline"
  },
  {
    "name": "bell"
  },
  {
    "name": "bluetooth-outline"
  },
  {
    "name": "bluetooth"
  },
  {
    "name": "book-open-outline"
  },
  {
    "name": "book-open"
  },
  {
    "name": "book-outline"
  },
  {
    "name": "book"
  },
  {
    "name": "bookmark-outline"
  },
  {
    "name": "bookmark"
  },
  {
    "name": "briefcase-outline"
  },
  {
    "name": "briefcase"
  },
  {
    "name": "browser-outline"
  },
  {
    "name": "browser"
  },
  {
    "name": "brush-outline"
  },
  {
    "name": "brush"
  },
  {
    "name": "bulb-outline"
  },
  {
    "name": "bulb"
  },
  {
    "name": "calendar-outline"
  },
  {
    "name": "calendar"
  },
  {
    "name": "camera-outline"
  },
  {
    "name": "camera"
  },
  {
    "name": "car-outline"
  },
  {
    "name": "car"
  },
  {
    "name": "cast-outline"
  },
  {
    "name": "cast"
  },
  {
    "name": "checkmark-circle-2-outline"
  },
  {
    "name": "checkmark-circle-2"
  },
  {
    "name": "checkmark-circle-outline"
  },
  {
    "name": "checkmark-circle"
  },
  {
    "name": "checkmark-outline"
  },
  {
    "name": "checkmark-square-2-outline"
  },
  {
    "name": "checkmark-square-2"
  },
  {
    "name": "checkmark-square-outline"
  },
  {
    "name": "checkmark"
  },
  {
    "name": "chevron-down-outline"
  },
  {
    "name": "chevron-down"
  },
  {
    "name": "chevron-left-outline"
  },
  {
    "name": "chevron-left"
  },
  {
    "name": "chevron-right-outline"
  },
  {
    "name": "chevron-right"
  },
  {
    "name": "chevron-up-outline"
  },
  {
    "name": "chevron-up"
  },
  {
    "name": "clipboard-outline"
  },
  {
    "name": "clipboard"
  },
  {
    "name": "clock-outline"
  },
  {
    "name": "clock"
  },
  {
    "name": "close-circle-outline"
  },
  {
    "name": "close-circle"
  },
  {
    "name": "close-outline"
  },
  {
    "name": "close"
  },
  {
    "name": "cloud-download-outline"
  },
  {
    "name": "cloud-download"
  },
  {
    "name": "cloud-upload-outline"
  },
  {
    "name": "cloud-upload"
  },
  {
    "name": "code-download-outline"
  },
  {
    "name": "code-download"
  },
  {
    "name": "code-outline"
  },
  {
    "name": "code"
  },
  {
    "name": "collapse-outline"
  },
  {
    "name": "collapse"
  },
  {
    "name": "color-palette-outline"
  },
  {
    "name": "color-palette"
  },
  {
    "name": "color-picker-outline"
  },
  {
    "name": "color-picker"
  },
  {
    "name": "compass-outline"
  },
  {
    "name": "compass"
  },
  {
    "name": "copy-outline"
  },
  {
    "name": "copy"
  },
  {
    "name": "corner-down-left-outline"
  },
  {
    "name": "corner-down-left"
  },
  {
    "name": "corner-down-right-outline"
  },
  {
    "name": "corner-down-right"
  },
  {
    "name": "corner-left-down-outline"
  },
  {
    "name": "corner-left-down"
  },
  {
    "name": "corner-left-up-outline"
  },
  {
    "name": "corner-left-up"
  },
  {
    "name": "corner-right-down-outline"
  },
  {
    "name": "corner-right-down"
  },
  {
    "name": "corner-right-up-outline"
  },
  {
    "name": "corner-right-up"
  },
  {
    "name": "corner-up-left-outline"
  },
  {
    "name": "corner-up-left"
  },
  {
    "name": "corner-up-right-outline"
  },
  {
    "name": "corner-up-right"
  },
  {
    "name": "credit-card-outline"
  },
  {
    "name": "credit-card"
  },
  {
    "name": "crop-outline"
  },
  {
    "name": "crop"
  },
  {
    "name": "cube-outline"
  },
  {
    "name": "cube"
  },
  {
    "name": "diagonal-arrow-left-down-outline"
  },
  {
    "name": "diagonal-arrow-left-down"
  },
  {
    "name": "diagonal-arrow-left-up-outline"
  },
  {
    "name": "diagonal-arrow-left-up"
  },
  {
    "name": "diagonal-arrow-right-down-outline"
  },
  {
    "name": "diagonal-arrow-right-down"
  },
  {
    "name": "diagonal-arrow-right-up-outline"
  },
  {
    "name": "diagonal-arrow-right-up"
  },
  {
    "name": "download-outline"
  },
  {
    "name": "download"
  },
  {
    "name": "droplet-off-outline"
  },
  {
    "name": "droplet-off"
  },
  {
    "name": "droplet-outline"
  },
  {
    "name": "droplet"
  },
  {
    "name": "edit-2-outline"
  },
  {
    "name": "edit-2"
  },
  {
    "name": "edit-outline"
  },
  {
    "name": "edit"
  },
  {
    "name": "email-outline"
  },
  {
    "name": "email"
  },
  {
    "name": "expand-outline"
  },
  {
    "name": "expand"
  },
  {
    "name": "external-link-outline"
  },
  {
    "name": "external-link"
  },
  {
    "name": "eye-off-2-outline"
  },
  {
    "name": "eye-off-2"
  },
  {
    "name": "eye-off-outline"
  },
  {
    "name": "eye-off"
  },
  {
    "name": "eye-outline"
  },
  {
    "name": "eye"
  },
  {
    "name": "facebook-outline"
  },
  {
    "name": "facebook"
  },
  {
    "name": "file-add-outline"
  },
  {
    "name": "file-add"
  },
  {
    "name": "file-outline"
  },
  {
    "name": "file-remove-outline"
  },
  {
    "name": "file-remove"
  },
  {
    "name": "file-text-outline"
  },
  {
    "name": "file-text"
  },
  {
    "name": "file"
  },
  {
    "name": "film-outline"
  },
  {
    "name": "film"
  },
  {
    "name": "flag-outline"
  },
  {
    "name": "flag"
  },
  {
    "name": "flash-off-outline"
  },
  {
    "name": "flash-off"
  },
  {
    "name": "flash-outline"
  },
  {
    "name": "flash"
  },
  {
    "name": "flip-2-outline"
  },
  {
    "name": "flip-2"
  },
  {
    "name": "flip-outline"
  },
  {
    "name": "flip"
  },
  {
    "name": "folder-add-outline"
  },
  {
    "name": "folder-add"
  },
  {
    "name": "folder-outline"
  },
  {
    "name": "folder-remove-outline"
  },
  {
    "name": "folder-remove"
  },
  {
    "name": "folder"
  },
  {
    "name": "funnel-outline"
  },
  {
    "name": "funnel"
  },
  {
    "name": "gift-outline"
  },
  {
    "name": "gift"
  },
  {
    "name": "github-outline"
  },
  {
    "name": "github"
  },
  {
    "name": "globe-2-outline"
  },
  {
    "name": "globe-2"
  },
  {
    "name": "globe-outline"
  },
  {
    "name": "globe"
  },
  {
    "name": "google-outline"
  },
  {
    "name": "google"
  },
  {
    "name": "grid-outline"
  },
  {
    "name": "grid"
  },
  {
    "name": "hard-drive-outline"
  },
  {
    "name": "hard-drive"
  },
  {
    "name": "hash-outline"
  },
  {
    "name": "hash"
  },
  {
    "name": "headphones-outline"
  },
  {
    "name": "headphones"
  },
  {
    "name": "heart-outline"
  },
  {
    "name": "heart"
  },
  {
    "name": "home-outline"
  },
  {
    "name": "home"
  },
  {
    "name": "image-outline"
  },
  {
    "name": "image"
  },
  {
    "name": "inbox-outline"
  },
  {
    "name": "inbox"
  },
  {
    "name": "info-outline"
  },
  {
    "name": "info"
  },
  {
    "name": "keypad-outline"
  },
  {
    "name": "keypad"
  },
  {
    "name": "layers-outline"
  },
  {
    "name": "layers"
  },
  {
    "name": "layout-outline"
  },
  {
    "name": "layout"
  },
  {
    "name": "link-2-outline"
  },
  {
    "name": "link-2"
  },
  {
    "name": "link-outline"
  },
  {
    "name": "link"
  },
  {
    "name": "linkedin-outline"
  },
  {
    "name": "linkedin"
  },
  {
    "name": "list-outline"
  },
  {
    "name": "list"
  },
  {
    "name": "loader-outline"
  },
  {
    "name": "lock-outline"
  },
  {
    "name": "lock"
  },
  {
    "name": "log-in-outline"
  },
  {
    "name": "log-in"
  },
  {
    "name": "log-out-outline"
  },
  {
    "name": "log-out"
  },
  {
    "name": "map-outline"
  },
  {
    "name": "map"
  },
  {
    "name": "maximize-outline"
  },
  {
    "name": "maximize"
  },
  {
    "name": "menu-2-outline"
  },
  {
    "name": "menu-2"
  },
  {
    "name": "menu-arrow-outline"
  },
  {
    "name": "menu-arrow"
  },
  {
    "name": "menu-outline"
  },
  {
    "name": "menu"
  },
  {
    "name": "message-circle-outline"
  },
  {
    "name": "message-circle"
  },
  {
    "name": "message-square-outline"
  },
  {
    "name": "message-square"
  },
  {
    "name": "mic-off-outline"
  },
  {
    "name": "mic-off"
  },
  {
    "name": "mic-outline"
  },
  {
    "name": "mic"
  },
  {
    "name": "minimize-outline"
  },
  {
    "name": "minimize"
  },
  {
    "name": "minus-circle-outline"
  },
  {
    "name": "minus-circle"
  },
  {
    "name": "minus-outline"
  },
  {
    "name": "minus-square-outline"
  },
  {
    "name": "minus-square"
  },
  {
    "name": "minus"
  },
  {
    "name": "monitor-outline"
  },
  {
    "name": "monitor"
  },
  {
    "name": "moon-outline"
  },
  {
    "name": "moon"
  },
  {
    "name": "more-horizontal-outline"
  },
  {
    "name": "more-horizontal"
  },
  {
    "name": "more-vertical-outline"
  },
  {
    "name": "more-vertical"
  },
  {
    "name": "move-outline"
  },
  {
    "name": "move"
  },
  {
    "name": "music-outline"
  },
  {
    "name": "music"
  },
  {
    "name": "navigation-2-outline"
  },
  {
    "name": "navigation-2"
  },
  {
    "name": "navigation-outline"
  },
  {
    "name": "navigation"
  },
  {
    "name": "npm-outline"
  },
  {
    "name": "npm"
  },
  {
    "name": "options-2-outline"
  },
  {
    "name": "options-2"
  },
  {
    "name": "options-outline"
  },
  {
    "name": "options"
  },
  {
    "name": "pantone-outline"
  },
  {
    "name": "pantone"
  },
  {
    "name": "paper-plane-outline"
  },
  {
    "name": "paper-plane"
  },
  {
    "name": "pause-circle-outline"
  },
  {
    "name": "pause-circle"
  },
  {
    "name": "people-outline"
  },
  {
    "name": "people"
  },
  {
    "name": "percent-outline"
  },
  {
    "name": "percent"
  },
  {
    "name": "person-add-outline"
  },
  {
    "name": "person-add"
  },
  {
    "name": "person-delete-outline"
  },
  {
    "name": "person-delete"
  },
  {
    "name": "person-done-outline"
  },
  {
    "name": "person-done"
  },
  {
    "name": "person-outline"
  },
  {
    "name": "person-remove-outline"
  },
  {
    "name": "person-remove"
  },
  {
    "name": "person"
  },
  {
    "name": "phone-call-outline"
  },
  {
    "name": "phone-call"
  },
  {
    "name": "phone-missed-outline"
  },
  {
    "name": "phone-missed"
  },
  {
    "name": "phone-off-outline"
  },
  {
    "name": "phone-off"
  },
  {
    "name": "phone-outline"
  },
  {
    "name": "phone"
  },
  {
    "name": "pie-chart-outline"
  },
  {
    "name": "pie-chart"
  },
  {
    "name": "pin-outline"
  },
  {
    "name": "pin"
  },
  {
    "name": "play-circle-outline"
  },
  {
    "name": "play-circle"
  },
  {
    "name": "plus-circle-outline"
  },
  {
    "name": "plus-circle"
  },
  {
    "name": "plus-outline"
  },
  {
    "name": "plus-square-outline"
  },
  {
    "name": "plus-square"
  },
  {
    "name": "plus"
  },
  {
    "name": "power-outline"
  },
  {
    "name": "power"
  },
  {
    "name": "pricetags-outline"
  },
  {
    "name": "pricetags"
  },
  {
    "name": "printer-outline"
  },
  {
    "name": "printer"
  },
  {
    "name": "question-mark-circle-outline"
  },
  {
    "name": "question-mark-circle"
  },
  {
    "name": "question-mark-outline"
  },
  {
    "name": "question-mark"
  },
  {
    "name": "radio-button-off-outline"
  },
  {
    "name": "radio-button-off"
  },
  {
    "name": "radio-button-on-outline"
  },
  {
    "name": "radio-button-on"
  },
  {
    "name": "radio-outline"
  },
  {
    "name": "radio"
  },
  {
    "name": "recording-outline"
  },
  {
    "name": "recording"
  },
  {
    "name": "refresh-outline"
  },
  {
    "name": "refresh"
  },
  {
    "name": "repeat-outline"
  },
  {
    "name": "repeat"
  },
  {
    "name": "rewind-left-outline"
  },
  {
    "name": "rewind-left"
  },
  {
    "name": "rewind-right-outline"
  },
  {
    "name": "rewind-right"
  },
  {
    "name": "save-outline"
  },
  {
    "name": "save"
  },
  {
    "name": "scissors-outline"
  },
  {
    "name": "scissors"
  },
  {
    "name": "search-outline"
  },
  {
    "name": "search"
  },
  {
    "name": "settings-2-outline"
  },
  {
    "name": "settings-2"
  },
  {
    "name": "settings-outline"
  },
  {
    "name": "settings"
  },
  {
    "name": "shake-outline"
  },
  {
    "name": "shake"
  },
  {
    "name": "share-outline"
  },
  {
    "name": "share"
  },
  {
    "name": "shield-off-outline"
  },
  {
    "name": "shield-off"
  },
  {
    "name": "shield-outline"
  },
  {
    "name": "shield"
  },
  {
    "name": "shopping-bag-outline"
  },
  {
    "name": "shopping-bag"
  },
  {
    "name": "shopping-cart-outline"
  },
  {
    "name": "shopping-cart"
  },
  {
    "name": "shuffle-2-outline"
  },
  {
    "name": "shuffle-2"
  },
  {
    "name": "shuffle-outline"
  },
  {
    "name": "shuffle"
  },
  {
    "name": "skip-back-outline"
  },
  {
    "name": "skip-back"
  },
  {
    "name": "skip-forward-outline"
  },
  {
    "name": "skip-forward"
  },
  {
    "name": "slash-outline"
  },
  {
    "name": "slash"
  },
  {
    "name": "smartphone-outline"
  },
  {
    "name": "smartphone"
  },
  {
    "name": "speaker-outline"
  },
  {
    "name": "speaker"
  },
  {
    "name": "square-outline"
  },
  {
    "name": "square"
  },
  {
    "name": "star-outline"
  },
  {
    "name": "star"
  },
  {
    "name": "stop-circle-outline"
  },
  {
    "name": "stop-circle"
  },
  {
    "name": "sun-outline"
  },
  {
    "name": "sun"
  },
  {
    "name": "swap-outline"
  },
  {
    "name": "swap"
  },
  {
    "name": "sync-outline"
  },
  {
    "name": "sync"
  },
  {
    "name": "text-outline"
  },
  {
    "name": "text"
  },
  {
    "name": "thermometer-minus-outline"
  },
  {
    "name": "thermometer-minus"
  },
  {
    "name": "thermometer-outline"
  },
  {
    "name": "thermometer-plus-outline"
  },
  {
    "name": "thermometer-plus"
  },
  {
    "name": "thermometer"
  },
  {
    "name": "toggle-left-outline"
  },
  {
    "name": "toggle-left"
  },
  {
    "name": "toggle-right-outline"
  },
  {
    "name": "toggle-right"
  },
  {
    "name": "trash-2-outline"
  },
  {
    "name": "trash-2"
  },
  {
    "name": "trash-outline"
  },
  {
    "name": "trash"
  },
  {
    "name": "trending-down-outline"
  },
  {
    "name": "trending-down"
  },
  {
    "name": "trending-up-outline"
  },
  {
    "name": "trending-up"
  },
  {
    "name": "tv-outline"
  },
  {
    "name": "tv"
  },
  {
    "name": "twitter-outline"
  },
  {
    "name": "twitter"
  },
  {
    "name": "umbrella-outline"
  },
  {
    "name": "umbrella"
  },
  {
    "name": "undo-outline"
  },
  {
    "name": "undo"
  },
  {
    "name": "unlock-outline"
  },
  {
    "name": "unlock"
  },
  {
    "name": "upload-outline"
  },
  {
    "name": "upload"
  },
  {
    "name": "video-off-outline"
  },
  {
    "name": "video-off"
  },
  {
    "name": "video-outline"
  },
  {
    "name": "video"
  },
  {
    "name": "volume-down-outline"
  },
  {
    "name": "volume-down"
  },
  {
    "name": "volume-off-outline"
  },
  {
    "name": "volume-off"
  },
  {
    "name": "volume-up-outline"
  },
  {
    "name": "volume-up"
  },
  {
    "name": "wifi-off-outline"
  },
  {
    "name": "wifi-off"
  },
  {
    "name": "wifi-outline"
  },
  {
    "name": "wifi"
  }
]
