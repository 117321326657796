import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ApiService } from '../@core/api/api.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ManterComponent } from './manter/manter.component';
import { moveItemInArray, CdkDropListGroup, CdkDropList, CdkDragMove, CdkDrag } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import { UtilsService } from '../@core/utils';

@Component({
  selector: 'ngx-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {


  links: any;
  linksFiltrados: any;
  linksFavoritos: any = [];
  linksFavoritosFiltrados: any = [];
  inputFavoritos: EventEmitter<any> = new EventEmitter;

  filtrado = false;

  formFiltro = this.fb.group({
    busca: ['',
      [
        Validators.minLength(3),
        Validators.maxLength(45)
      ]
    ]
  });

  params: any;

  constructor(
    protected dialogRef: NbDialogRef<LinksComponent>,
    private api: ApiService,
    private fb: FormBuilder,
    private dialogService: NbDialogService,
    private viewportRuler: ViewportRuler,
    private utils: UtilsService,
  ) {
    this.target = null;
    this.source = null;
  }

  ngOnInit() {

    this.atualizarLinks();

    //Evento ao modificar o formulário
    this.formFiltro.valueChanges.subscribe(val => {
      // console.log(val);

      var searchTerm = '' + this.formFiltro.value.busca;
      if (searchTerm == '' || searchTerm.length >= 3)
        this.filtrar(searchTerm);
    });

  }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }

  iniciaHttp(url: string): string {
    if (url.substr(0, 4) == 'http') {
      return url
    } else {
      return 'http://' + url
    }
  }

  addFavorito(e) {
    // console.log(e);

    var linksIds = [...this.utils.getArrayListItems(this.linksFavoritos, 'id')];

    // console.log(linksIds);
    if (!(linksIds.includes(e.id))) {

      // Carregar favoritos
      this.api.apiPost('perfil/links/favorito', {
        geral_id: e.id

      }).subscribe(response => {
        // console.log(response);

        this.linksFavoritos.push(e);
        this.linksFavoritosFiltrados.push(e);
        // this.linksFavoritos = response.dados.link;
      },
        error => {
          console.log(error);
        }
      );
    }
  }

  removerFavorito(item) {
    // console.log(item);

    // Carregar favoritos
    this.api.apiDelete('perfil/links/favorito/delete/' + item.id).subscribe(response => {

      // console.log(response);
      this.atualizarLinks();
    },
      error => {
        console.log(error);
      }
    );
  }

  atualizarLinks() {

    // Carregar favoritos
    this.api.apiGet('perfil/links/favorito').subscribe(response => {
      this.linksFavoritos = response.dados.link;

      // Carregar links utéis
      this.api.apiGet('perfil/links').subscribe(response => {
        this.links = response.dados;

        //Filtrar resultado
        this.filtrar(this.formFiltro.value.busca);
      },
        error => {
          console.log(error);
        }
      );
    },
      error => {
        console.log(error);
      }
    );
  }

  // filtrar(searchTerm) {
  filtrar(searchTerm) {
    // console.log(searchTerm);

    // Recriar instancia
    this.linksFavoritosFiltrados = JSON.parse(JSON.stringify(this.linksFavoritos));
    this.linksFiltrados = JSON.parse(JSON.stringify(this.links));

    // Se o filtro não for vazio
    if (searchTerm != '') {

      this.filtrado = true;

      //Filtro de Favoritos
      this.linksFavoritosFiltrados = [...this.linksFavoritosFiltrados].filter(link => {
        return link.title
          .toLowerCase()
          .normalize('NFKD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            searchTerm
              .toLowerCase()
              .normalize('NFKD')
              .replace(/[\u0300-\u036f]/g, '')) > -1;
      });

      //Filtro de Links Gerais
      this.linksFiltrados.links_gerais = [...this.linksFiltrados.links_gerais].filter(link => {
        return link.title
          .toLowerCase()
          .normalize('NFKD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            searchTerm
              .toLowerCase()
              .normalize('NFKD')
              .replace(/[\u0300-\u036f]/g, '')) > -1;
      });

      //Filtro de Links Pessoais
      this.linksFiltrados.links_user = [...this.linksFiltrados.links_user].filter(link => {
        return link.title
          .toLowerCase()
          .normalize('NFKD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            searchTerm
              .toLowerCase()
              .normalize('NFKD')
              .replace(/[\u0300-\u036f]/g, '')) > -1;
      });

    } else {

      this.filtrado = false;
    }
  }

  manterLinkPessoal(action: string = "novo", params?: any) {

    var modal = this.dialogService.open(
      ManterComponent,
      {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        hasScroll: true,
        context: {
          action: action,
          params: params,
        }
      }
    );

    modal.onClose.subscribe(data => {

      if (typeof data == undefined) {
        data = {
          data: [],
          status: false
        };
      }
      if (data.status == true) {
        this.atualizarLinks();
      }
    });
  }

  @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, { static: false }) placeholder: CdkDropList;

  // public items: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;


  ngAfterViewInit() {
    let phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped(e, link) {

    console.log('dropListDropped', e, link, this.sourceIndex, this.targetIndex);

    if (!this.target)
      return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(this.linksFavoritosFiltrados, this.sourceIndex, this.targetIndex);
    }

    // Carregar favoritos
    this.api.apiPut('perfil/links/favorito/order', {
      links: [...this.utils.getArrayListItems(this.linksFavoritosFiltrados, 'id')]
    }).subscribe(response => {
      console.log('Ordem salva', response);
      this.atualizarLinks();
    },
      error => {
        console.log(error);
      }
    );
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    // console.log('dropListEnterPredicate', dragIndex, dropIndex);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }

  verificaFavoritado(id) {
    return [...this.utils.getArrayListItems(this.linksFavoritos, 'id')].includes(id);
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}