import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbIconLibraries, NbMenuService, NbDialogService } from '@nebular/theme';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { StorageService } from '../@core/api/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PdftronComponent } from './pdftron/pdftron.component';
import { EXTENSIONS, TYPES, ICONS } from './storage-mimetypes';
import { FileReaderComponent } from './file-reader/file-reader.component';
import { UtilsService } from '../@core/utils';

@Component({
  selector: 'ngx-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {

  id: number = 0;
  action: string = 'user';
  data: any = {};
  isApp: boolean = false;
  key_app: string = null;
  acceptTypes: string = "*";

  TYPES: any = TYPES;
  ICONS: any = ICONS;
  EXTENSIONS: any = EXTENSIONS;

  private STG_URL = environment.STG_URL + '/arquivo/';
  images = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => `https://picsum.photos/500/300?random&t=${Math.random()}`);
  loading = true;
  cadastrado = false;
  pasta = null;
  pastaId = null;
  arquivos: any = [];
  progressBar = {
    msg: 'Fazendo configurações.',
    value: 0,
    status: 'danger'
  };
  search = '';

  public pdfTronExt = [
    'DOCX',
    'XLSX',
    'PPTX',
    'JPG',
    'PNG',
    'PDF',
    'JPEG'
  ];

  // arrayExtImg = ['jpg', 'jpeg', 'png', 'gif'];
  arvore: any = [];
  evaIcons = [];

  constructor(
    protected dialogRef: NbDialogRef<StorageComponent>,
    iconsLibrary: NbIconLibraries,
    private apiStorage: StorageService,
    menu: NbMenuService,
    private http: HttpClient,
    private dialogService: NbDialogService,
    private utils: UtilsService,
  ) {
    this.evaIcons = Array.from(iconsLibrary.getPack('eva').icons.keys())
      .filter(icon => icon.indexOf('outline') === -1);

    iconsLibrary.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('ion', { iconClassPrefix: 'ion' });
  }

  ngOnInit() {
    this.carregarUsuario();

    // Se for ação select especifica os tipos de arquivo aceitos
    if (this.action == 'select') {

      // Se tiver extensão aplica, senão aplica das extensões do tipo
      if (this.data.extensions) {
        this.acceptTypes = '.' + this.data.extensions.join(',.');;

      } else {
        var type = this.utils.getFileTypeByName(this.data.type);
        this.acceptTypes = '.' + type.ext.join(',.');
      }
    }
  }

  carregarUsuario() {
    var extraHeaders = this.isApp ? [['Key-App', this.key_app]] : [];
    this.apiStorage.apiGetUser(extraHeaders).subscribe(
      response => {
        // console.log(response);
        // setTimeout(() => this.loading = false, 3000);
        // this.arvore = response.dados.user.pasta;

        // Define a pasta do usuário/app
        if (this.isApp) {
          localStorage.setItem('StoragePasta', JSON.stringify(response.dados.app.pasta));
        } else {
          localStorage.setItem('StoragePasta', JSON.stringify(response.dados.user.pasta));
        }

        var pastaLimpo = localStorage.getItem('StoragePasta').split(',"children":[]').join('');
        pastaLimpo = JSON.parse(pastaLimpo);

        this.pasta = pastaLimpo[0];
        this.arvore = pastaLimpo[0]['children'];
        this.cadastrado = true;
        this.carregarArquivos();
      },
      error => {
        if (error.code == 1 && error.status == 300) {
          // console.log('esta aqui');
          this.aumentaProgress();
          this.criarUsuario();
        } else {
          console.log(error);
        }
      });
  }

  carregarArquivos() {
    var extraHeaders = this.isApp ? [['Key-App', this.key_app]] : [];
    this.apiStorage.apiGet('user/arquivo', {}, extraHeaders).subscribe(response => {
      // Armazena lista completa
      this.arquivos = response.dados.arquivo;

      // Filtros por ação
      switch (this.action) {
        case 'select':

          // Se tipo de arquivo for diferente de todos
          if (this.data.type != 'all') {

            // Filtrar arquivos do tipo selecionado
            this.arquivos = this.arquivos.filter(
              arquivo => {

                // Se tiver extensão aplica, senão aplica das extensões do tipo
                if (this.data.extensions) {
                  return this.data.extensions.includes(arquivo.extensao);

                } else {
                  var type = this.utils.getFileType(arquivo.extensao);
                  return type.type == this.data.type;
                }
              }
            );
          }
          break;
      }

      this.loading = false;
    },
      error => {
        console.log(error);
      });
  }

  // Busca o icone do arquivo
  getFileIcon(arquivo) {
    // Se o arquivo for uma image retorna a imagem, senão o icone
    if (this.utils.getFileType(arquivo.extensao).type == 'image') {
      return this.STG_URL + arquivo.tb_storage_user_app_id + '/' + arquivo.id + '/' + arquivo.file_name;
    } else {
      return this.utils.getExtIcon(arquivo.extensao);
    }
  }

  criarUsuario() {
    this.apiStorage.apiCreateUser().subscribe(
      response => {

        localStorage.setItem('StoragePasta', JSON.stringify(response.dados.user.pasta));
        var pastaLimpo = localStorage.getItem('StoragePasta').split(',"children":[]').join('');
        pastaLimpo = JSON.parse(pastaLimpo);
        console.log(pastaLimpo);
        this.pasta = pastaLimpo[0];
        this.arvore = pastaLimpo[0]['children'];
        this.cadastrado = true;
        this.carregarArquivos();

      }, erros => {
        console.log(erros);
      }
    );
  }

  criadorUrl() {
    return this.STG_URL + this.arquivos[this.selectFile].tb_storage_user_app_id + '/' + this.arquivos[this.selectFile].id + '/' + this.arquivos[this.selectFile].file_name;
  }

  modalCloseOk() {
    this.dialogRef.close({
      data: {
        file: this.arquivos[this.selectFile],
        url: this.criadorUrl()
      },
      status: true
    });
  }

  // drop file

  imageSrc = [];
  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.imageSrc = [];
    // console.log(this.files);

    // Para cada arquivo ou diretorio enviado
    var contador: number = 0;
    for (const droppedFile of files) {

      // Se for arquivo
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // console.log(file);
          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);

          // testando
          // if (file.type != '') {
          // console.log(file.type);
          var srcIco = '';
          if (file.type.indexOf("image") >= 0) {

            // TODO: Corte de imagem para upload
            const reader = new FileReader();
            reader.onload = e => this.imageSrc.push({
              src: reader.result,
              file: file,
              relativePath: droppedFile.relativePath,
              loading: true,
              view: true,
              color: 'primary',
            });
            reader.readAsDataURL(file);

          } else {

            srcIco = this.utils.getExtIcon(this.utils.getFileExt(file.name));
            this.imageSrc.push({
              src: srcIco,
              file: file,
              relativePath: droppedFile.relativePath,
              loading: true,
              view: true,
              color: 'primary',
            });
          }

          // fim do teste
          this.uploadFile(contador, file, droppedFile.relativePath);
          contador = contador + 1;
        });

      } else {
        // Se for diretório (adiciona diretorios varios, do contrário apenas arquivos)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
        this.imageSrc.push({
          src: 'assets/storage/file/ico/folder.jpg',
          file: fileEntry,
          relativePath: droppedFile.relativePath,
          loading: false,
        });
      }
    }
  }

  // Enviar arquivo para o servidor
  private uploadFile(contador, file, relativePath) {

    // Cria um formulário e adiciona o arquivo
    let formData = new FormData();
    formData.append('file', file, relativePath);

    // Define o Id da pasta do arquivo
    var pastaUpload;
    if (this.pastaId != null) {
      pastaUpload = this.pastaId;
    } else {
      pastaUpload = this.pasta.id;
    }

    // Requisição de envio
    var extraHeaders = this.isApp ? [['Key-App', this.key_app]] : [];
    this.apiStorage.apiUpload(pastaUpload, formData, extraHeaders).subscribe(
      response => {
        console.log(response);
        this.imageSrc[contador].color = 'success';

        setTimeout(() => {
          this.imageSrc[contador].loading = false;
          this.imageSrc[contador].view = false;
          this.arquivos.unshift(response.dados.file);
        }, 1000);
      },
      erros => {
        this.imageSrc[contador].loading = false;
        this.imageSrc[contador].color = 'danger';
        console.warn(erros);
      }
    );
  }

  public selectFile = null;

  public selectImage(i) {
    // console.log(i);
    this.selectFile = i;
  }

  public fileOver(event) {
    console.log('fileOver', event);
  }

  public fileLeave(event) {
    console.log('fileLeave', event);
  }

  public formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  aumentaProgress() {
    setTimeout(() => {
      this.loading = false;
      if (this.progressBar.value + 25 < 100) {
        this.aumentaProgress();
      }
      this.setValue(this.progressBar.value + 25)
    }, 750);
  }

  setValue(newValue) {
    this.progressBar.value = Math.min(Math.max(newValue, 0), 100)
  }

  get progressBarStatus() {
    if (this.progressBar.value <= 25) {
      this.progressBar.msg = "Preparando usuário.";
      return 'danger';
    } else if (this.progressBar.value <= 50) {
      this.progressBar.msg = "Carregando dados do usuário.";
      return 'warning';
    } else if (this.progressBar.value <= 75) {
      this.progressBar.msg = "Preparando ambiente.";
      return 'info';
    } else {
      this.progressBar.msg = "Concluído.";
      this.loading = false;
      return 'success';
    }
  }

  viewFile(file) {
    // console.log(file);
    var modal = this.dialogService.open(
      FileReaderComponent,
      {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        hasScroll: true,
        context: {
          action: "view",
          data: file,
        }
      }
    );
    modal.onClose.subscribe(data => {
      console.log(data);
      // if(data.status) {
      //   this.loadPage(this.aplicativo.current_page);
      // }
    });
  }

  // Alterna entre publico e privado
  public togglePublic(i) {

    var file = this.arquivos[i];
    var pastaUpload;
    if (this.pastaId != null) {
      pastaUpload = this.pastaId
    } else {
      pastaUpload = this.pasta.id;
    }

    // Armazena o estado atual
    var privado = this.arquivos[i].privado;

    // Se for privado deixa publico
    if (privado == 1) {
      this.apiStorage.apiPublic(pastaUpload, file.id, {}).subscribe(
        response => {
          // Muda o status local
          this.arquivos[i].privado = 0;
        }, erros => {
          console.log(erros);
        }
      );

    } else {

      // Se for publico deixa privado
      this.apiStorage.apiPrivate(pastaUpload, file.id, {}).subscribe(
        response => {
          // Muda o status local
          this.arquivos[i].privado = 1;
        }, erros => {
          console.log(erros);
        }
      );
    }
  }

  public DeletePublico(i) {
    console.log(i);
    console.log(this.arquivos[i]);
    var file = this.arquivos[i];
    var pastaUpload;
    if (this.pastaId != null) {
      pastaUpload = this.pastaId
    } else {
      pastaUpload = this.pasta.id;
    }
    this.apiStorage.apiDeleteFile(pastaUpload, file.id).subscribe(
      response => {
        console.log(response);
        this.arquivos.splice(i, 1);
      }, erros => {
        console.log(erros);
      });
  }

  public pesquisa() {

    if (this.search != '') {
      this.apiStorage.apiGetSearch(this.search).subscribe(
        response => {
          console.log(response);
          this.loading = false;
          this.arquivos = response.dados.arquivo;
        }, erros => {
          console.log(erros);

        });
    } else {
      this.carregarArquivos();
    }

  }


  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }

  // imageChangedEvent: any = '';
  // croppedImage: any = '';

  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }
  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  // }
  // imageLoaded() {
  //   // show cropper
  // }
  // cropperReady() {
  //   // cropper ready
  // }
  // loadImageFailed() {
  //   // show message
  // }

}
