import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ngx-tarifas-avulsas',
  templateUrl: './tarifas-avulsas.component.html',
  styleUrls: ['./tarifas-avulsas.component.scss']
})
export class TarifasAvulsasComponent implements OnInit {

  loading = true;
  loadingSearch = true;
  pagina: string = 'geral';
  paginaTree: any = {};
  filtro = false;

  formFiltro = this.fb.group({
    busca: ['',
      [
        Validators.minLength(1),
        Validators.maxLength(45)
      ]
    ]
  });

  //Tabela
  settingsTarifa = {
    mode: "external",
    noDataMessage: "Não há dados.",
    actions: {
      columnTitle: "Ações",
      add: false,
      edit: false,
      delete: false,
      position: "right",
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      title: {
        title: 'Descrição'
      },
      canais: {
        title: 'Canais',
      },
      sigla: {
        title: 'Sigla',
      },
      fator: {
        title: 'Fator',
      },
      valor: {
        title: 'Valor',
        valuePrepareFunction(value, row, cell) {
          return value
        }
      }
    },
  };

  constructor(
    private fb: FormBuilder,
  ) {

  }

  tarifasBd = [{
    "tipopessoa": "PF",
    "servicos": [{
      "name": "SERVIÇOS PRIORITÁRIOS",
      "categorias": [{
        "name": "1 CADASTRO",
        "itens": [{
          "title": "1.1 Confecção e cadastro para início do relacionamento",
          "canais": null,
          "sigla": "CADASTRO",
          "valor": "ISENTO",
          "fator": "Realização da pesquisa em serviços de proteção ao crédito, base de dados e informações cadastrais, e tratamento de dados e informações necessários ao início de relacionamento decorrente da abertura de conta de depósitos a vista ou contratação de operação de crédito, não podendo ser cobrada cumulativamente."
        }]
      },
      {
        "name": "2.1 CARTÃO",
        "itens": [{
          "title": "2.1.1 Fornecimento 2ª via de cartão com função débito (mínimo R$8,50 e no máximo R$24,00)",
          "canais": "-",
          "sigla": "2ª via - CARTÃO DÉBITO",
          "valor": " R$ 24.00 ",
          "fator": "Confecção e emissão de novo cartão com função débito, restrito aos casos de pedidos de reposição formulados pelo detentor da conta, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis a instituição emitente."
        },
        {
          "title": "2.1.2 Fornecimento 2ª via de cartão com função de movimentação de conta poupança (mínimo R$8,50 e máximo R$24,00)",
          "canais": "-",
          "sigla": "2ª via - CARTÃO POUPANÇA",
          "valor": " R$ - ",
          "fator": "Confecção e emissão de novo cartão com função débito, restrito aos casos de perdidos de reposição formulados pelo detentor da conta, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis a instituição emitente."
        }
        ]
      },
      {
        "name": "2.2 CHEQUE",
        "itens": [{
          "title": "2.2.1 Exclusão do Cadastro de Emitentes de Cheques sem fundos (CCF)",
          "canais": "-",
          "sigla": "EXCLUSÃO CCF",
          "valor": " R$ 48.20 ",
          "fator": "Exclusão de registro de cheque do Cadastro de Emitentes de Cheques sem Fundos CCF por solicitação do cliente, cobrada por unidade excluída."
        },
        {
          "title": "2.2.2 Contra-ordem (ou revogação) e oposição (ou sustação) ao pagamento de cheque.",
          "canais": "-",
          "sigla": "SUSTAÇÃO/REVOGAÇÃO",
          "valor": " R$ 12.10 ",
          "fator": "Realização de contraordem (ou revogação) e oposição (ou sustação) ao pagamento de cheque, cobrada uma única vez, compreendidas as fases de solicitação provisória, de confirmação e de eventual anulação a pedido."
        },
        {
          "title": "2.2.3 Fornecimento de folhas de cheque",
          "canais": null,
          "sigla": "FOLHA DE CHEQUE",
          "valor": " R$ 1.20 ",
          "fator": "Confecção e fornecimento de folhas de cheque, cobrada por unidade que exceder as dez folhas gratuítas, fornecidas por conta de depósitos a vista independentemente do número de titulares."
        },
        {
          "title": "2.2.4 Cheque administrativo",
          "canais": "-",
          "sigla": "CHEQUE ADMINISTRATIVO",
          "valor": " R$25,00 mais 0,11% sobre o valor do cheque ",
          "fator": "Emissão de Cheque Administrativo."
        }
        ]
      },
      {
        "name": "2.3 SAQUE",
        "itens": [{
          "title": "2.3.1 Saque de conta de depósitos a vista e de poupança",
          "canais": "Presencial ou Pessoal",
          "sigla": "SAQUE pessoal",
          "valor": " R$ 2.50 ",
          "fator": "Saque em guiche de caixa além do número de saques permitidos gratuitamente por mês."
        },
        {
          "title": "2.3.1 Saque de conta de depósitos a vista e de poupança",
          "canais": "Terminal de autoatendimento",
          "sigla": "SAQUE terminal",
          "valor": " R$ 1.20 ",
          "fator": "Saque em terminal de autoatendimento além do número de saques permitidos gratuitamente por mês."
        }
        ]
      },
      {
        "name": "2.4 DEPÓSITO",
        "itens": [{
          "title": "2.4.1 Depósito identificado",
          "canais": "-",
          "sigla": "Depósito identificado",
          "valor": " R$ 3.00 ",
          "fator": "Depósito com registros de informações necessárias a identificação, a qualquer tempo, da operação e/ou do depositante, por estes solicitado."
        }]
      },
      {
        "name": "2.5 CONSULTA",
        "itens": [{
          "title": "2.5.1 Fornecimento de extrato mensal de conta de depósitos a vista",
          "canais": "Presencial ou pessoal",
          "sigla": "Extrato mês (P)",
          "valor": " R$ 3.00 ",
          "fator": "Fornecimento de extratos com a movimentação dos últimos 30 dias em guichê de caixa ou por outras formas de atendimento pessoal, tais como atendimento telefônico realizado por atendente, além do número permitindo gratuitamente por mês. Nas ''contas eletrônicas'' não há gratuidade para este canal de entrega."
        },
        {
          "title": "2.5.1 Fornecimento de extrato mensal de conta de depósitos a vista",
          "canais": "Terminal de autoatendimento e outros meios eletrônicos",
          "sigla": "Extrato mês (E)",
          "valor": " R$ 1.20 ",
          "fator": "Fornecimento de extratos com a movimentação dos últimos 30 dias em guichê de caixa ou por outras formas de atendimento pessoal, tais como atendimento telefônico realizado por atendente, além do número permitindo gratuitamente por mês. Nas ''contas eletrônicas'' não há gratuidade para este canal de entrega."
        },
        {
          "title": "2.5.2 Fornecimento de extrato mensal de conta de depósitos a vista e de poupança para um período",
          "canais": "Presencial ou pessoal",
          "sigla": "Extrato movimento (P)",
          "valor": " R$ 3.00 ",
          "fator": "Fornecimento de extrato com a movimentação de um período em guichê de caixa ou por outras formas de atendimento pessoal, tal como atendimento telefônico realizado por atendente."
        },
        {
          "title": "2.5.2 Fornecimento de extrato mensal de conta de depósitos a vista e de poupança para um período",
          "canais": "Terminal de autoatendimento e outros meios eletrônicos",
          "sigla": "Extrato movimento (E)",
          "valor": " R$ 1.20 ",
          "fator": "Fornecimento de extrato com a movimentação de um período em terminal de autoatendimento ou por outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas ''contas eletrônicas'' não pode ser cobrada tarifa para este canal de entrega."
        },
        {
          "title": "2.5.3 Fornecimento de cópia de microfilme, microficha e/ou assemelhados",
          "canais": null,
          "sigla": "MICROFILME",
          "valor": " R$ 8.00 ",
          "fator": "Fornecimento de cópia de microfilme, microficha ou assemelhado."
        }
        ]
      },
      {
        "name": "3 TRANSFERÊNCIAS DE RECURSOS",
        "itens": [{
          "title": "3.1 Transferência por meio de DOC",
          "canais": "Presencial ou Pessoal",
          "sigla": "DOC pessoal",
          "valor": " R$ 14.50 ",
          "fator": "Realização de transferência de recursos por meio de Documento de Crédito (DOC) em guichê de caixa ou mediante outras formas de atendimento pessoal."
        },
        {
          "title": "3.1 Transferência por meio de DOC",
          "canais": "Terminal de autoatendimento e outros meios eletrônicos",
          "sigla": "DOC eletrônico",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferência de recursos por meio de Documento de Crédito (DOC) em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana."
        },
        {
          "title": "3.1 Transferência por meio de DOC",
          "canais": "Internet",
          "sigla": "DOC internet",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferência de recursos por meio de DOC pela internet."
        },
        {
          "title": "3.2 Transferência por meio de TED",
          "canais": "Presencial ou Pessoal",
          "sigla": "TED pessoal",
          "valor": " R$ 14.50 ",
          "fator": "Realização de transferência de recursos por meio de Transferência Eletrônica Disponível (TED) em guichê de caixa ou mediante outras de atendimento pessoal."
        },
        {
          "title": "3.2 Transferência por meio de TED",
          "canais": "Terminal de autoatendimento e outros meios eletrônicos",
          "sigla": "TED eletrônico",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferência de recursos por meio de Transferência Eletrônica Disponível (TED) em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana."
        },
        {
          "title": "3.2 Transferência por meio de TED",
          "canais": "Internet",
          "sigla": "TED internet",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferência de recursos por meio de TED pela internet."
        },
        {
          "title": "3.3 Transferências agendada por meio de DOC/TED",
          "canais": "Presencial ou Pessoal",
          "sigla": "DOC/TED agendado (P)",
          "valor": " R$ 14.50 ",
          "fator": "Realização de transferência agendada de recursos por meio de DOC ou TED em guichê de caixa ou mediante outras formas de atendimento pessoal."
        },
        {
          "title": "3.3 Transferências agendada por meio de DOC/TED",
          "canais": "Terminal de autoatendimento e outros",
          "sigla": "DOC/TED agendado (E)",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferência agendada de recursos por meio de DOC ou TED em terminal de autoatendimento ou mediante outras formas de atendimento eletronico automatizado, sem intervenção humana."
        },
        {
          "title": "3.3 Transferências agendada por meio de DOC/TED",
          "canais": "Internet",
          "sigla": "DOC/TED agendado (I)",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferência de recursos por meio de DOC ou TED pela internet."
        },
        {
          "title": "3.3 Transferência entre contas na própria Cooperativa",
          "canais": "Presencial ou Pessoal",
          "sigla": "TRANSF. RECURSOS (P)",
          "valor": " R$ 1.20 ",
          "fator": "Realização de transferência de recursos entre contas na própria instituição em guiche de caixa ou mediante outras formas de atendimento pessoal, além do número permitido gratuitamente por mês. "
        },
        {
          "title": "3.3 Transferência entre contas na própria Cooperativa",
          "canais": "Terminal de autoatendimento e outros meios eletrônicos",
          "sigla": "TRANSF. RECURSOS (E/I)",
          "valor": " Isento ",
          "fator": "Realização de transferência de recursos entre contas na própria instituição em terminal de autoatendimento ou mediante outras formas de atendimento eletronico automatizado, sem intervenção humana, inclusive internet, além do número permitido gratuitamente por mês."
        }
        ]
      },
      {
        "name": "4 OPERAÇÕES DE CRÉDITO E ARREDAMENTO MERCANTIL",
        "itens": [{
          "title": "4.1 Concessão de Adiantamento a Depositante",
          "canais": "-",
          "sigla": "ADIANT. DEPOSITANTE",
          "valor": " R$ 48.20 ",
          "fator": "Levantamento de informações e avaliação de viabilidade e de riscos para a concessão de crédito em carácter emergencial para cobertura de saldo devedor em conta de depósitos a vista e de excesso sobre o limite previamente pactuado de cheque especial, cobrada no máximo uma vez nos últimos 30 dias."
        }]
      }
      ]
    },
    {
      "name": "SERVIÇOS DIFERENCIADOS",
      "categorias": [{
        "name": "1 ADIANTAMENTO DE CONTRATOS",
        "itens": [{
          "title": "1.1 Aditivos de contratos e cédulas de crédito (valor mínimo de R$200,00 e o valor máximo de R$500,00)",
          "canais": null,
          "sigla": "TAR. REF. ADIANTAMENTO DE CONTRATOS",
          "valor": "1% sobre o saldo devedor",
          "fator": "Realização de aditivos em contratos ou títulos de crédito, não se aplicando aos casos de contratos por adesão (exceto no caso de substituição do bem em operações de arrendamento mercantil, em que a tarifa é devida) e liquidação ou amortização antecipada, cancelamento ou rescisão de contratos ou títulos de crédito."
        }]
      },
      {
        "name": "2 AVAL E FIANÇA",
        "itens": [{
          "title": "2.1 Fornecimento de Carta Fiança",
          "canais": null,
          "sigla": "TARIFA AVAL E FIANÇA",
          "valor": "4% a.a sobre o valor afiançado",
          "fator": " Fornecimento de carta de fiança, por documentos. "
        }]
      },
      {
        "name": "3 AVALIAÇÃO, REAVALIAÇÃO E SUBSTITUIÇÃO DE BENS RECEBIDOS EM GARANTIA",
        "itens": [{
          "title": "3.1 Avaliação de imóveis urbanos e rurais (mínimo R$500,00 e máximo R$10.000,00)*",
          "canais": null,
          "sigla": "TAR. REF. AVALIAÇÃO DE BENS RECEB. EM GARANTIA",
          "valor": "0,50% sobre o valor da avaliação",
          "fator": " Avaliação de bens recebidos em garantia. "
        },
        {
          "title": "3.2 Reavaliação de imóveis urbanos e rurais (mínimo R$500,00 e máximo R$10.000,00)*",
          "canais": null,
          "sigla": "TAR. REF. REAVALIAÇÃO DE BENS RECEB. EM GARANTIA",
          "valor": "0,50% sobre o valor da reavaliação",
          "fator": " Reavaliação de bens recebidos em garantia. "
        },
        {
          "title": "3.3 Substituição de imóveis urbanos e rurais (mínimo R$200,00 e máximo R$500,00)*",
          "canais": null,
          "sigla": "TAR. REF. SUBSTITUIÇÃO DE BENS RECEB. EM GARANTIA",
          "valor": "1% sobre o saldo devedor",
          "fator": " Substituição de bens recebidos em garantia. "
        }
        ]
      },
      {
        "name": "4 ALIENAÇÃO VEÍCULOS",
        "itens": [{
          "title": "4.1 Alienação (valor mínimo de R$43,49 e máximo R$100,00)*",
          "canais": null,
          "sigla": null,
          "valor": " R$ 100.00 ",
          "fator": " Inclusão Alienação Veículos. "
        },
        {
          "title": "4.2 Baixa (valor mínimo de R$30,00 e máximo R$60,00)*",
          "canais": null,
          "sigla": null,
          "valor": " R$ 60.00 ",
          "fator": " Baixa Alienação Veículos. "
        },
        {
          "title": "4.3 Substituição (valor mínimo de R$200,00 e máximo R$500,00)",
          "canais": null,
          "sigla": "TAR. REF. AVALIAÇÃO DE BENS RECEB. EM GARANTIA",
          "valor": "1% sobre o saldo devedor",
          "fator": " Substituição Alienação Veículos. "
        }
        ]
      },
      {
        "name": "5 CARTÕES",
        "itens": [{
          "title": "5.1 Anuidade Cartão de Crédito Standard Cooperativo bandeiras Mastercard / Visa* Titular",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 99.00 ",
          "fator": " Anuidade do Titular cobrada em até 3 parcelas "
        },
        {
          "title": "5.2 Anuidade Cartão de Crédito Gold Cooperativo bandeiras Mastercard / Visa* Titular",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 129.00 ",
          "fator": " Anuidade do Titular cobrada em até 3 parcelas "
        },
        {
          "title": "5.3 Anuidade Cartão de Crédito Platinum Cooperativo bandeiras Mastercard / Visa* Titular",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 229.00 ",
          "fator": " Anuidade do Titular cobrada em até 3 parcelas "
        },
        {
          "title": "5.4 Anuidade Cartão de Crédito Standard Cooperativo bandeiras Mastercard / Visa* Adicional 50%",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 44.50 ",
          "fator": " Anuidade do Adicional cobrada em até 3 parcelas "
        },
        {
          "title": "5.5 Anuidade Cartão de Crédito Gold Cooperativo bandeiras Mastercard / Visa* Adicional 50%",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 64.50 ",
          "fator": " Anuidade do Adicional cobrada em até 3 parcelas "
        },
        {
          "title": "5.6 Anuidade Cartão de Crédito Platinum Cooperativo bandeiras Mastercard / Visa* Adicional 50%",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 114.50 ",
          "fator": " Anuidade do Adicional cobrada em até 3 parcelas "
        },
        {
          "title": "5.7 Saque na função débito na rede de autoatendimento BANCO 24 HORAS*",
          "canais": null,
          "sigla": "SAQUE",
          "valor": " R$ 3.40 ",
          "fator": " Rede 24 HORAS "
        },
        {
          "title": "5.8 Saque na função crédito na rede de autoatendimento BANCO 24 HORAS*",
          "canais": null,
          "sigla": "SAQUE",
          "valor": " R$ 3.40 ",
          "fator": " Rede 24 HORAS "
        },
        {
          "title": "5.9 Consultas - REDE BANCO 24 HORAS",
          "canais": null,
          "sigla": "CONSULTA",
          "valor": " R$ 1.50 ",
          "fator": " Rede 24 HORAS "
        },
        {
          "title": "5.10 Ocorrências / Transações Rejeitadas - REDE BANCO 24 HORAS*",
          "canais": null,
          "sigla": "OCORRÊNCIA",
          "valor": " R$ 1.50 ",
          "fator": " Rede 24 HORAS "
        },
        {
          "title": "5.11 Saque na função débito na rede Compartilhada fora da Cooperativa (bandeiras Maestro / Mastercard / Visa)*",
          "canais": null,
          "sigla": "SAQUE",
          "valor": " R$ 4.89 ",
          "fator": " Rede Compartilhada "
        },
        {
          "title": "5.12 Consultas na rede Compartilhada fora da Cooperativa (bandeiras Maestro / Mastercard / Visa)*",
          "canais": null,
          "sigla": "CONSULTA",
          "valor": " R$ 1.50 ",
          "fator": " Rede Compartilhada "
        },
        {
          "title": "5.13 Ocorrências / Transações Rejeitadas na Rede Compartilhada fora da cooperativa - (bandeira Maestro / Mastercard / Visa)*",
          "canais": null,
          "sigla": "OCORRÊNCIA",
          "valor": " R$ 1.50 ",
          "fator": " Rede Compartilhada "
        },
        {
          "title": "5.14 Saque na função débito na REDE CIRRUS (bandeira Maestro / Mastercard / Visa)*",
          "canais": null,
          "sigla": "SAQUE",
          "valor": " R$ 6.90 ",
          "fator": " Rede Cirrus "
        },
        {
          "title": "5.15 Saque na função crédito na REDE CIRRUS (bandeira Maestro / Mastercard / Visa)*",
          "canais": null,
          "sigla": "SAQUE",
          "valor": " R$ 6.90 ",
          "fator": " Rede Cirrus "
        },
        {
          "title": "5.16 Consultas na REDE CIRRUS (bandeira Maestro / Mastercard / Visa)*",
          "canais": null,
          "sigla": "CONSULTA",
          "valor": " R$ 1.50 ",
          "fator": " Rede Cirrus "
        },
        {
          "title": "5.17 Transações rejeitadas na REDE CIRRUS (bandeira Maestro / Mastercard / Visa)*",
          "canais": null,
          "sigla": "TRANSAÇÕES",
          "valor": " R$ 1.30 ",
          "fator": " Rede Cirrus "
        },
        {
          "title": "5.18 Saque no exterior na REDE CIRRUS (bandeira Maestro / Mastercard / Visa)",
          "canais": null,
          "sigla": "SAQUE",
          "valor": " R$ 6.90 ",
          "fator": " Rede Cirrus "
        },
        {
          "title": "5.19 Solicitação 2ª via Cartão Crédito* (valor mínimo R$8,50 e máximo R$24,00)",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 24.00 ",
          "fator": " Segunda via Cartão de Crédito "
        },
        {
          "title": "5.20 Solicitação 2ª via Cartão Débito* (valor mínimo R$8,50 e máximo R$24,00)",
          "canais": null,
          "sigla": "CARTÃO",
          "valor": " R$ 24.00 ",
          "fator": " Segunda via Cartão de Débito "
        },
        {
          "title": "5.21 Pacote com SMS ILIMITADO",
          "canais": null,
          "sigla": "SICOOBCARD SMS ILIMITADO",
          "valor": " R$ 3.50 ",
          "fator": " Mensalidade do pacote de mensagens "
        }
        ]
      },
      {
        "name": "6 COLETA E ENTREGA EM DOMICÍLIO OU OUTRO LOCAL",
        "itens": [{
          "title": "6.1 Entrega de documentos em domicílio (Courier Avulso)",
          "canais": null,
          "sigla": "TAR. REF. COLETA ENTR. DOMICILIO/OUT.LOCAL",
          "valor": " R$ 8.00 ",
          "fator": " Entrega de documentos em domicílio. "
        }]
      },
      {
        "name": "7 CUSTÓDIA CHEQUES",
        "itens": [{
          "title": "7.1 Entrada em custódia",
          "canais": null,
          "sigla": "TARIFA COBRANÇA",
          "valor": " R$ 1.20 ",
          "fator": " Entrada de cheque em custódia. "
        },
        {
          "title": "7.2 Prorrogação",
          "canais": null,
          "sigla": "TARIFA COBRANÇA",
          "valor": " R$ 10.00 ",
          "fator": " Prorrogação da data de depósito do cheque. "
        },
        {
          "title": "7.3 Retirada",
          "canais": null,
          "sigla": "TARIFA COBRANÇA",
          "valor": " R$ 10.00 ",
          "fator": " Retirada do cheque da custódia. "
        },
        {
          "title": "7.4 Fornecimento de 2ª via de relatórios ou aviso de movimentação",
          "canais": null,
          "sigla": "TARIFA SERVIÇOS DE COBRANÇA",
          "valor": " R$ 1.20 ",
          "fator": " Fornecimento de 2ª via de relatórios ou do aviso de movimentação, sendo a tarifa cobrada por folha de documento. "
        }
        ]
      },
      {
        "name": "8 CHEQUE DIVERSOS",
        "itens": [{
          "title": "8.1 Devolução de cheque por insuficiência de fundos",
          "canais": null,
          "sigla": "CHEQUE",
          "valor": " R$ 25.00 ",
          "fator": " Devolução de cheques "
        },
        {
          "title": "8.2 Formulário Contínuo",
          "canais": null,
          "sigla": "CENTENA",
          "valor": " R$ 30.00 ",
          "fator": " Formulário Contínuo "
        },
        {
          "title": "8.3 Talonário de cheques (a partir do 2º talonário mês)",
          "canais": null,
          "sigla": "20 FOLHAS",
          "valor": " R$ 24.00 ",
          "fator": " Talão de cheques "
        }
        ]
      },
      {
        "name": "9 ATESTADOS, CERTIFICADOS E DECLARAÇÕES",
        "itens": [{
          "title": "9.1 Atestado de Idoneidade Financeira",
          "canais": null,
          "sigla": "TAR. REF. FORNECIMENTO DE ATESTADO",
          "valor": " R$ 60.20 ",
          "fator": " Fornecimento de atestado de idoneidade financeira "
        }]
      },
      {
        "name": "10 CÓPIA OU SEGUNDA VIA DE COMPROVANTES E DOCUMENTOS",
        "itens": [{
          "title": "10.1 Fornecimento de cópia ou de segunda via de comprovantes e documentos",
          "canais": null,
          "sigla": "TAR. CÓPIA OU 2ª VIA COMPROV. E DOCUMENTOS",
          "valor": " R$ 9.70 ",
          "fator": " Fornecimento de 2º via de comprovantes e/ou documentos "
        }]
      },
      {
        "name": "11 SERVIÇO DE MALOTE",
        "itens": [{
          "title": "11.1 Assinatura mensal de serviços de malote (Courier mensal) - valor mínimo R$20,00 e máximo R$100,00)",
          "canais": null,
          "sigla": "TAR. REF. MALOTE ASSINATURA MENSAL OU OUTRAS TARIFAS",
          "valor": " R$ 100.00 ",
          "fator": " Assinatura mensal de serviços de malote para atendimento em guichê de caixa "
        }]
      },
      {
        "name": "12 RENOVAÇÃO DE CADASTRO",
        "itens": [{
          "title": "12.1 Renovação de cadastro (cobrada no máximo uma vez ao ano)",
          "canais": null,
          "sigla": "TAR. REF. RENOVAÇÃO CADASTRO",
          "valor": " R$ 25.00 ",
          "fator": " Tarifa renovação de cadastro "
        }]
      },
      {
        "name": "13 OUTROS SERVIÇOS",
        "itens": [{
          "title": "13.1 Cliente bancário desde",
          "canais": null,
          "sigla": "DÉB. TARIFA CLIENTE DESDE",
          "valor": " R$ 8.00 ",
          "fator": " Fornecimento da data de início de relacionamento com a cooperativa para outra instituição financeira "
        }]
      }
      ]
    },
    {
      "name": "SERVIÇOS ESPECIAIS",
      "categorias": [{
        "name": "1 COBRANÇA BANCÁRIA",
        "itens": [{
          "title": "1.1 Emissão de Boleto com Registro",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 4.50 ",
          "fator": " Emissão de boleto com registro de seus dados nas bases cadastrais do SICOOB, podendo a tarifa ser cobrada na entrada na entrada ou na liquidação do boleto, de acordo com as condições pactuadas entre as partes. "
        },
        {
          "title": "1.2 Emissão de Boleto sem Registro",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Entreada de boleto sem registro de seus dados nas bases cadastrais do SICOOB, podendo a tarifa ser cobrada na entrada ou na liquidação do boleto, de acordo com as condições pactuadas entre as partes. "
        },
        {
          "title": "1.3 Pré- impressão de boleto sem registro autocopiativo",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 0.25 ",
          "fator": " Pré-impressão de boleto sem registro autocopiativo com 3 ou 4 vias, de acordo com a solicitação do cedente. "
        },
        {
          "title": "1.4 Alteração de dados cadastrais do boleto com registro",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 3.00 ",
          "fator": " Alterações de dados do boleto registrado nas bases cadastrais SICOOB, a pedido do cedente. "
        },
        {
          "title": "1.5 Liquidação de boleto com inconsistênciais cadastrais",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Liquidação, mediante crédito manual em conta corrente, de boleto registrado nas bases cadastrais SICOOB, cujos dados apresentem divergênciais em relação as informações contidas no código de barras e/ou linha digitável. "
        },
        {
          "title": "1.6 Manutenção de boleto vencido",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Manutenção de dados do boleto vencido nas bases cadastrais do SICOOB, sendo a tarifa cobrada a cada período de 30 dias contados a partir do vencimento. "
        },
        {
          "title": "1.7 Baixa de boleto a pedido do cedente",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Baixa dos dados do boleto vencido das bases cadastrais do SICOOB, a pedido do cedente. "
        },
        {
          "title": "1.8 Baixa automática de boleto por decurso de prazo",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Baixa automática dos dados do boleto vencido das bases cadastrais SICOOB, após decorridos 90 dias do vencimento. "
        },
        {
          "title": "1.9 Prorrogação do vencimento de boleto",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 3.00 ",
          "fator": " Prorrogação da data de vencimento do boleto, a pedido do cedente. "
        },
        {
          "title": "1.10 Encaminhamento de título para protesto",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 6.00 ",
          "fator": "Encaminhamento de boleto a cartório para protesto de título de crédito por indicação, a pedido do cedente de acordo. Adicionalmente, é cobrado o reembolso das custas cartorais, o que não se configura no conceito de tarifa, de acordo com a regulamentação vigente."
        },
        {
          "title": "1.11 Sustação de protesto de título",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 6.00 ",
          "fator": " Sustação do protesto de título de crédito, a pedido do cedente. "
        },
        {
          "title": "1.12 Fornecimento de carta de anuência",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 10.00 ",
          "fator": " Fornecimento de 2º via de relatórios ou do aviso de movimentação, sendo a tarifa cobrada por folha de documento. "
        },
        {
          "title": "1.13 Fornecimento de 2º via de relatório ou aviso de movimentação",
          "canais": null,
          "sigla": "OCORRÊNCIA",
          "valor": " R$ 7.00 ",
          "fator": " Fornecimento de 2º via de relatórios ou do aviso de movimentação, sendo a tarifa cobrada por folha de documento. "
        },
        {
          "title": "1.14 Impressão e postagem",
          "canais": null,
          "sigla": "BOLETO",
          "valor": " R$ 1.85 ",
          "fator": " Entrada de título nas bases cadastrais do SICOOB. "
        },
        {
          "title": "1.15 Avaliação de projeto",
          "canais": null,
          "sigla": "DEB. AVALIAÇÃO DE PROJETOS",
          "valor": "0.50%",
          "fator": " Avaliação de projeto (estudo técnico), sendo a tarifa cobrada em relação ao orçamento referente a operação proposta. "
        }
        ]
      }]
    }
    ]
  },
  {
    "tipopessoa": "PJ",
    "servicos": [{
      "name": "TARIFAS AVULSAS",
      "categorias": [{
        "name": "1 CADASTRO",
        "itens": [{
          "title": "1.1 Confecção e cadastro para início do relacionamento",
          "canais": "",
          "sigla": "CADASTRO",
          "valor": "ISENTO",
          "fator": "Realização da pesquisa em serviços de proteção ao crédito, base de dados e informações cadastrais, e tratamento de dados e informações necessários ao início de relacionamento decorrente da abertura de conta de depósitos a vista ou contratação de operação de crédito, não podendo ser cobrada cumulativamente."
        },
        {
          "title": "1.2 Renovação de cadastro (cobrada, no máximo, uma vez ao ano).",
          "canais": "",
          "sigla": "CADASTRO",
          "valor": " R$ 25.00 ",
          "fator": "Realização da pesquisa em serviços de proteção ao crédito, base de dados e informações cadastrais, e tratamento de dados e informações necessários ao início de relacionamento decorrente da abertura de conta de depósitos a vista ou de poupança ou contratação de operação de crédito ou de arrendamento mercantil."
        }
        ]
      },
      {
        "name": "2.1 CARTÃO",
        "itens": [{
          "title": "2.1.1 Fornecimento 2ª via de cartão em função débito (mínimo R$ 8,50 e no máximo R$ 24,00)",
          "canais": "",
          "sigla": "2ª via - CARTÃO DÉBITO",
          "valor": " R$ 24.00 ",
          "fator": "Confecção e emissão de novo cartão com função débito, restrito aos casos de pedidos de reposição formulados pelo detentor da conta, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis a instituição emitende."
        },
        {
          "title": "2.1.2 Fornecimento 2ª via de cartão em função de movimentação de conta poupança (mínimo R$ 8,50 e máximo R$ 24,00)",
          "canais": "",
          "sigla": "2ª via - CARTÃO POUPANÇA",
          "valor": " ISENTO ",
          "fator": "Confecção e emissão de novo cartão de poupança, restrito aos casos de perdidos de reposição formulados pelo detentor da conta, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis a instituição emitente."
        }
        ]
      },
      {
        "name": "2.2 CHEQUE",
        "itens": [{
          "title": "2.2.1 Exclusão do Cadastro de Emitentes de Cheques sem fundos CCF",
          "canais": "",
          "sigla": "EXCLUSÃO CCF",
          "valor": " R$ 48.20 ",
          "fator": "Exclusão de registro de cheque do Cadastro de Emitentes de Cheques sem Fundos CCF por solicitação do cliente, cobrada por unidade excluída."
        },
        {
          "title": "2.2.2 Contra-ordem (ou revogação) e oposição (ou sustação) ao pagamento de cheque.",
          "canais": "",
          "sigla": "SUSTAÇÃO / REVOGAÇÃO",
          "valor": " R$ 12.10 ",
          "fator": "Realização de contraordem (ou revogação) e oposição (ou sustação) ao pagamento de cheque, cobrada uma única vez, compreendidas as fases de solicitação provisória, de confirmação e de eventual anulação a pedido."
        },
        {
          "title": "2.2.3 Fornecimento de folhas de cheque",
          "canais": "",
          "sigla": "FOLHA DE CHEQUE",
          "valor": " R$ 1.20 ",
          "fator": "Confecção e fornecimento de folhas de cheque, fornecidas por conta de depósitos a vista independentemente do número de titulares."
        },
        {
          "title": "2.2.4 Cheque administrativo",
          "canais": "",
          "sigla": "FOLHA DE CHEQUE",
          "valor": " 30,10 mais 0,11% sobre o valor do cheque ",
          "fator": "Emissão de Cheque Administrativo."
        },
        {
          "title": "2.2.5 Acatamento de Cheque",
          "canais": "",
          "sigla": "TARIFA ACATAMENTO CHEQUE",
          "valor": " ISENTO ",
          "fator": "Acatamento de cheque sem provisão de fundos em conta corrente."
        },
        {
          "title": "2.2.6 Devolução de cheque por motivo 11 ou 12",
          "canais": "",
          "sigla": "TARIFA CHEQUE DEVOLVIDO",
          "valor": " R$ 30.10 ",
          "fator": "Devolução de cheque sem provisão de fundos, por motivo 11 ou 12"
        },
        {
          "title": "2.2.7 Compensação de cheque de valor igual ou superior a R$ 5 mil",
          "canais": "",
          "sigla": "TARIFA PRÉ-DEPÓSITO",
          "valor": " ISENTO ",
          "fator": "Compensação de cheque de valor igual ou superior a R$ 5 mil cobrada inclusive no caso de cheque administrativo, sendo a tarifa cobrada sobre o valor do cheque."
        },
        {
          "title": "2.2.8 Entrada de cheque em custódia",
          "canais": "",
          "sigla": "TARIFA COBRANÇA",
          "valor": " R$ 1.20 ",
          "fator": "Entrada de cheque em custódia"
        },
        {
          "title": "2.2.9 Prorrogação de cheque custodiado",
          "canais": "",
          "sigla": "TARIFA COBRANÇA",
          "valor": " R$ 10.00 ",
          "fator": "Prorrogação da data de depósito do cheque"
        },
        {
          "title": "2.2.10 Retirada do cheque custodiado",
          "canais": "",
          "sigla": "TARIFA COBRANÇA",
          "valor": " R$ 10.00 ",
          "fator": "Retirada do cheque em custódia"
        },
        {
          "title": "2.2.11 Fornecimento de 2º via de relatórios ou aviso de movimentação de cheques custodiados.",
          "canais": "",
          "sigla": "TARIFA SERVIÇOS DE COBRANÇA",
          "valor": " R$ 1.20 ",
          "fator": "Fornecimento de 2º via de relatórios ou aviso de movimentação de cheques custodiados,sendo a tarifa cobrada por folha de documento."
        },
        {
          "title": "2.2.12 Fornecimento de cheque em formulário contínuo (100 folhas)",
          "canais": "",
          "sigla": "100 FOLHAS DE CHEQUE",
          "valor": " R$ 30.00 ",
          "fator": "Formulário Contínuo."
        }
        ]
      },
      {
        "name": "2.3 SAQUE",
        "itens": [{
          "title": "2.3.1 Saque de conta de depósitos a vista e de poupança",
          "canais": "",
          "sigla": "SAQUE pessoal",
          "valor": " R$ 2.50 ",
          "fator": "Saque em guiche de caixa além do número de saques permitidos gratuitamente por mês. Nas contas de depósitos cujos contratos prevejam utilizar exclusivamente meios eletrônicos (contas eletrônicas não há gratuidade para este canal de entrega)."
        },
        {
          "title": "2.3.1 Saque de conta de depósitos a vista e de poupança",
          "canais": "",
          "sigla": "SAQUE terminal",
          "valor": " R$ 1.20 ",
          "fator": "Saque em terminal de autoatendimento além do número de saques permitidos gratuitamente por mês. Nas contas eletronicas não pode ser cobrada tarifa para este canal de entrega."
        }
        ]
      },
      {
        "name": "2.4 DEPÓSITO",
        "itens": [{
          "title": "2.4.1 Depósito identificado",
          "canais": "",
          "sigla": "Depósito identificado",
          "valor": " R$ 3.00 ",
          "fator": "Depósito com registros de informações necessárias a identificação, a qualquer tempo, da operação e/ou do depositante, por estes solicitado."
        }]
      },
      {
        "name": "2.5 CONSULTA",
        "itens": [{
          "title": "2.5.1 Fornecimento de extrato mensal de conta de depósitos a vista e de poupança",
          "canais": "",
          "sigla": "Extrato mês (P)",
          "valor": " R$ 3.00 ",
          "fator": "Fornecimento de extratos com a movimentação dos últimos 30 dias em guichê de caixa ou por outras formas de atendimento pessoal, tais como atendimento telefônico realizado por atendente, além do número permitindo gratuitamente por mês. Nas ''contas eletrônicas'' não há gratuidade para este canal de entrega."
        },
        {
          "title": "2.5.1 Fornecimento de extrato mensal de conta de depósitos a vista e de poupança",
          "canais": "",
          "sigla": "Extrato mês (E)",
          "valor": " R$ 1.20 ",
          "fator": "Fornecimento de extratos com a movimentação dos últimos 30 dias em terminal de autoatendimento ou por outras formas de atendimento eletrônico automatizado, sem intervenção humana, além do numero permitido gratuitamente por mês. Nas ''contas eletrônicas'' não há gratuidade para este canal de entrega."
        },
        {
          "title": "2.5.2 Fornecimento de extrato mensal de conta de depósitos a vista e de poupança para um período",
          "canais": "",
          "sigla": "Extrato movimento mês (P)",
          "valor": " R$ 3.00 ",
          "fator": "Fornecimento de extratos com a movimentação dos últimos 30 dias em guichê de caixa ou por outras formas de atendimento pessoal, tais como atendimento realizado por atendente."
        },
        {
          "title": "2.5.2 Fornecimento de extrato mensal de conta de depósitos a vista e de poupança para um período",
          "canais": "",
          "sigla": "Extrato movimento mês (E)",
          "valor": " R$ 1.20 ",
          "fator": "Fornecimento de extrato com a movimentação de um período em terminal de autoatendimento ou por outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas ''contas eletrônicas'' não pode ser cobrada tarifa para este canal de entrega."
        },
        {
          "title": "2.5.3 Fornecimento de cópia de microfilme, microficha e/ou assemelhado",
          "canais": "",
          "sigla": "Microfilme",
          "valor": " R$ 9.70 ",
          "fator": "Fornecimento de cópia de microfilme, microficha ou assemelhado."
        }
        ]
      },
      {
        "name": "2.6 TRANSFERÊNCIA DE RECURSOS",
        "itens": [{
          "title": "2.6.1 Transferências por meio de DOC",
          "canais": "",
          "sigla": "DOC PESSOAL",
          "valor": " R$ 14.50 ",
          "fator": "Realização de transferências de recursos por meio de Documentos de Crédito (DOC) em guichê de caixa ou mediante outras formas de atendimento pessoal, incluindo atendimento telefônico."
        },
        {
          "title": "2.6.1 Transferências por meio de DOC",
          "canais": "",
          "sigla": "DOC ELETRÔNICO",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferências de recursos por meio de Documentos de Crédito (DOC) em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas contas eletrônicas, não pode ser cobrada tarifa para este canal de entrega."
        },
        {
          "title": "2.6.1 Transferências por meio de DOC",
          "canais": "",
          "sigla": "DOC INTERNET",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferências de recursos por meio de Documentos de Crédito (DOC) em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas contas eletrônicas, não pode ser cobrada tarifa para este canal de entrega."
        },
        {
          "title": "2.6.2 Transferências por meio de TED",
          "canais": "",
          "sigla": "TED PESSOAL",
          "valor": " R$ 14.50 ",
          "fator": "Realização de transferências de recursos por meio de Transferência Eletrônica Disponível (TED) em guichê de caixa ou mediante outras formas de atendimento pessoal, incluindo atendimento telefônico."
        },
        {
          "title": "2.6.2 Transferências por meio de TED",
          "canais": "",
          "sigla": "TED ELETRÔNICO",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferências de recursos por meio de Transferência Eletrônica Disponível (TED) em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas contas eletrônicas, não pode ser cobrada tarifa para este canal de entrega."
        },
        {
          "title": "2.6.2 Transferências por meio de TED",
          "canais": "",
          "sigla": "TED INTERNET",
          "valor": " R$ 7.20 ",
          "fator": "Realização de transferências de recursos por meio de Transferência Eletrônica Disponível (TED) em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas contas eletrônicas, não pode ser cobrada tarifa para este canal de entrega."
        },
        {
          "title": "2.6.3 Transferência entre contas na própria Cooperativa",
          "canais": "",
          "sigla": "TRANSF. RECURSOS (P)",
          "valor": " R$ 1.20 ",
          "fator": "Realização de transferência de recursos entre contas na própria instituição em guiche de caixa ou mediante outras formas de atendimento pessoal, além do número permitido gratuitamente por mês. Nas contas eletrônicas, não pode ser cobrada tarifa para este canal de entrega."
        },
        {
          "title": "2.6.3 Transferência entre contas na própria Cooperativa",
          "canais": "",
          "sigla": "TRANSF. RECURSOS (E/I)",
          "valor": " Isento ",
          "fator": "Realização de transferência de recursos entre contas na própria instituição em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana, inclusive internet, além do número permitido gratuitamente por mês. Nas contas eletrônicas, não pode ser cobrada tarifa para este canal de entrega."
        }
        ]
      },
      {
        "name": "3 OPERAÇÕES DE CRÉDITO E DE ARRENDAMENTO MERCANTIL",
        "itens": [{
          "title": "3.1 Abertura de crédito - Capital de giro (com garantia pessoal)",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Abertura de crédito"
        },
        {
          "title": "3.2 Abertura de crédito - Capital de giro (com garantia real, recebíveis de produção ou rating)",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Abertura de crédito"
        },
        {
          "title": "3.3 Abertura de crédito - Empréstimo para Reforma e Expansão",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Abertura de crédito"
        },
        {
          "title": "3.4 Abertura de crédito -Financiamento de Veículos Novos e Usados",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Abertura de crédito"
        },
        {
          "title": "3.5 Abertura de crédito -Financiamento de Equipamentos Profissionais",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Abertura de crédito"
        },
        {
          "title": "3.6 Abertura de Crédito - Financiamento de Imóveis Alienção/Hipoteca",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Abertura de crédito"
        },
        {
          "title": "3.7 Desconto de cheque (desconto avulso)",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Desconto"
        },
        {
          "title": "3.8 Desconto Nota Provisória",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Desconto"
        },
        {
          "title": "3.9 Contratação/Renovação de Limite de Desconto de Cheque",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Contratação/Renovação de Limite"
        },
        {
          "title": "3.10 Contratação/Renovação de Limite de Conta Garantida",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Contratação/Renovação de Limite"
        },
        {
          "title": "3.11 Implantação de Limite e Renovação de Cheque Especial",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Implantação Limite"
        },
        {
          "title": "3.12 Renegociação de Empréstimo/Financiamentos",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "ISENTO",
          "fator": "Renegociação"
        },
        {
          "title": "3.13 Concessão de Adiantamento a Depositante (cobertura de saldo devedor ou limite ultrapassado)",
          "canais": "",
          "sigla": "ADIANT. DEPOSITANTE",
          "valor": "R$ 48.20",
          "fator": "Concessão de Adiantamento a Depositante"
        },
        {
          "title": "3.14 Avaliação de garantias - imóveis urbanos (valor minimo R$ 500,00 e valor máximo R$ 10.000,00)",
          "canais": "",
          "sigla": "IMÓVEL",
          "valor": "0,50% do valor da avaliação",
          "fator": "Avaliação"
        },
        {
          "title": "3.15 Avaliação de garantias - imóveis rurais ( valor minimo R$ 500,00 e valor máximo R$ 10.000,00)",
          "canais": "",
          "sigla": "IMÓVEL",
          "valor": "0,50% do valor da avaliação",
          "fator": "Avaliação"
        },
        {
          "title": "3.16 Baixa de quitação do imóvel",
          "canais": "",
          "sigla": "EVENTO",
          "valor": "ISENTO",
          "fator": "Baixa Quitação"
        },
        {
          "title": "3.17 Baixa de Alienação de Veículos (valor mínimo R$ 30,00 e máximo R$ 60,00)",
          "canais": "",
          "sigla": "VEÍCULO",
          "valor": "R$ 60.00",
          "fator": "Baixa de Alienação veículos"
        },
        {
          "title": "3.18 Registro de Contrato com Alienação do veículo - DETRAN MT (valor mínimo R$ 200,00 e máximo R$ 273,00)",
          "canais": "",
          "sigla": "VEÍCULO",
          "valor": "R$ 273.00",
          "fator": "Registro Contrato"
        },
        {
          "title": "3.19 Registro de Contrato com Alienação do veículo - DETRAN Outros Estados (valor mínimo R$ 70,00 e máximo R$ 1.000,00)",
          "canais": "",
          "sigla": "OPERAÇÃO",
          "valor": "R$ 1,000.00",
          "fator": "Registro Contrato"
        },
        {
          "title": "3.20 Substituição de Garantia (valor mínimo R$ 200,00 e máximo R$ 1.000,00)",
          "canais": "",
          "sigla": "EVENTO",
          "valor": "1% sobre o saldo devedor",
          "fator": "Substituição de Garantias"
        },
        {
          "title": "3.21 Aditivos em geral (valor mínimo de R$ 200,00 e máximo R$ 500,00)",
          "canais": "",
          "sigla": "EVENTO",
          "valor": "1% sobre o saldo devedor",
          "fator": "Aditivos"
        }
        ]
      },
      {
        "name": "4 COBRANÇA BANCÁRIA",
        "itens": [{
          "title": "4.1 Emissão de Boleto com Registro",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 4.50 ",
          "fator": " Emissão de boleto com registro de seus dados nas bases cadastrais do SICOOB, podendo a tarifa ser cobrada na entrada ou na liquidação do boleto, de acordo com as condições pactuadas entre as partes. "
        },
        {
          "title": "4.2 Emissão de Boleto sem Registro",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Emissão de boleto com registro de seus dados nas bases cadastrais do SICOOB, podendo a tarifa ser cobrada na entrada ou na liquidação do boleto, de acordo com as condições pactuadas entre as partes. "
        },
        {
          "title": "4.3 Pré- impressão de boleto sem registro autocopiativo",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 0.25 ",
          "fator": " Pré-impressão de boleto sem registro autocopiativo com 3 ou 4 vias, de acordo com a solicitação do cedente. "
        },
        {
          "title": "4.4 Alteração de dados cadastrais do boleto com registro",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 3.00 ",
          "fator": " Alterações de dados do boleto registrado nas bases cadastrais SICOOB, a pedido do cedente. "
        },
        {
          "title": "4.5 Liquidação de boleto com inconsistênciais cadastrais",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Liquidação, mediante crédito manual em conta corrente, de boleto registrado nas bases cadastrais SICOOB, cujos dados apresentem divergênciais em relação as informações contidas no código de barras e/ou linha digitável. "
        },
        {
          "title": "4.6 Manutenção de boleto vencido",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Manutenção de dados do boleto vencido nas bases cadastrais do SICOOB, sendo a tarifa cobrada a cada período de 30 dias contados a partir do vencimento. "
        },
        {
          "title": "4.7 Baixa de boleto a pedido do cedente",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Baixa dos dados do boleto vencido das bases cadastrais do SICOOB, a pedido do cedente. "
        },
        {
          "title": "4.8 Baixa automática de boleto por decurso de prazo",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 4.00 ",
          "fator": " Baixa automática dos dados do boleto vencido das bases cadastrais SICOOB, após decorridos 90 dias do vencimento. "
        },
        {
          "title": "4.9 Prorrogação do vencimento de boleto",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 3.00 ",
          "fator": " Prorrogação da data de vencimento do boleto, a pedido do cedente. "
        },
        {
          "title": "4.10 Encaminhamento de título para protesto",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 6.00 ",
          "fator": "Encaminhamento de boleto a cartório para protesto de título de crédito por indicação, a pedido do cedente de acordo. Adicionalmente, é cobrado o reembolso das custas cartoriais, o que não se configura no conceito de tarifa de acordo com a regulamentação vigente."
        },
        {
          "title": "4.11 Sustação de protesto de título",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 6.00 ",
          "fator": " Sustação do protesto de título de crédito, a pedido do cedente. "
        },
        {
          "title": "4.12 Fornecimento de carta de anuência",
          "canais": "",
          "sigla": "OCORRÊNCIA",
          "valor": " R$ 10.00 ",
          "fator": " Fornecimento de 2º via de relatórios ou do aviso de movimentação, sendo a tarifa cobrada por folha de documento. "
        },
        {
          "title": "4.13 Fornecimento de 2º via de relatório ou aviso de movimentação",
          "canais": "",
          "sigla": "OCORRÊNCIA",
          "valor": " R$ 7.00 ",
          "fator": " Fornecimento de 2º via de relatórios ou do aviso de movimentação, sendo a tarifa cobrada por folha de documento. "
        },
        {
          "title": "4.14 Impressão e postagem",
          "canais": "",
          "sigla": "BOLETO",
          "valor": " R$ 1.85 ",
          "fator": " Entrada de título nas bases cadastrais do SICOOB com o tipo de distribuição pela cooperativa. "
        }
        ]
      },
      {
        "name": "5 PAGAMENTO DE FUNCIONÁRIOS",
        "itens": [{
          "title": "5.1 Pagamento de Folha de Funcionários (via relação - caixa) na Cooperativa",
          "canais": "",
          "sigla": "TARIFA PAG SALÁRIO",
          "valor": "R$ 12.00",
          "fator": "Pagamento em folha de funcionários efetuados manualmente no guichê de caixa (por lançamento)."
        },
        {
          "title": "5.2 Pagamento de Folha de Funcionários via Crédito de Produção",
          "canais": "",
          "sigla": "TARIFA PAG SALÁRIO",
          "valor": "R$ 6.00",
          "fator": "Pagamento em folha de funcionários efetuados manualmente (por lançamento) pela área de processamento da cooperativa."
        },
        {
          "name": "5 PAGAMENTO DE FUNCIONÁRIOS",
          "itens": [{
            "title": "5.3 Pagamento de Folha de Funcionários/fornecedores via arquivo CNAB240",
            "canais": "",
            "sigla": "FUNCIONÁRIO / FORNECEDOR",
            "valor": "R$ 6.00",
            "fator": "Pagamento de folha de funcionários e fornecedores efetuado por meio do SicoobNet CNAB240 ou outros meios eletrônicos(por lançamento)."
          }]
        }
        ]
      },
      {
        "name": "6 CRÉDITO RURAL",
        "itens": [{
          "title": "6.1 Avaliação de projeto",
          "canais": "",
          "sigla": "DEB. AVALIAÇÃO DE PROJETOS",
          "valor": "0.50%",
          "fator": " Avaliação de projeto (estudo técnico), sendo a tarifa cobrada em relação ao orçamento referente a operação proposta. "
        }]
      },
      {
        "name": "7 AVAL E FIANÇA",
        "itens": [{
          "title": "7.1 Fornecimento de carta de fiança",
          "canais": "",
          "sigla": "TARIFA AVAL E FIANÇA",
          "valor": "4% a.a sobre o valor afiançado",
          "fator": "Fornecimento de carta de fiança, por documento."
        }]
      },
      {
        "name": "8 CARTÕES DE CRÉDITO",
        "itens": [{
          "title": "8.1 SicoobCard Mastercard Empresarial",
          "canais": "",
          "sigla": "ANUIDADE DIFERENCIADA",
          "valor": "R$ 99.00",
          "fator": "Anuidade do titular em 3 parcelas."
        },
        {
          "title": "8.2 SicoobCard Mastercard Empresarial adicional",
          "canais": "",
          "sigla": "ANUIDADE DIFERENCIADA",
          "valor": "R$ 99.00",
          "fator": "Anuidade do adicional em 3 parcelas."
        },
        {
          "title": "8.3 Fornecimento de 2ª via de cartão com função crédito (valor mínimo R$ 8,50 e máximo R$ 24,00)",
          "canais": "",
          "sigla": "2ª via - CARTÃO DE CRÉDITO",
          "valor": "R$ 24.00",
          "fator": "Confecção e emissão de novo cartão com função crédito, restrito a casos de pedidos de reposição formulados pelo detendor do cartão, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis a instituição emitente."
        }
        ]
      },
      {
        "name": "9 UTILIZAÇÃO DE CANAIS DE ATENDIMENTO PARA RETIRADA EM ESPÉCIE",
        "itens": [{
          "title": "9.1 No País",
          "canais": "",
          "sigla": "RETIRADA - País",
          "valor": "R$ 7.40",
          "fator": "Utilização de canais de atendimento disponíveis no País para retirada em espécie na função crédito."
        },
        {
          "title": "9.2 No exterior",
          "canais": "",
          "sigla": "RETIRADA - Exterior",
          "valor": "R$ 7.40",
          "fator": "Utilização de canais de atendimento disponíveis no Exterior para retirada em espécie na função crédito ou débito."
        },
        {
          "title": "9.3 Pacote com SMS Ilimitado",
          "canais": "",
          "sigla": "SICOOBCARD SMS ILIMITADO",
          "valor": "R$ 3.50",
          "fator": "Mensalidade do pacote de mensagens automáticas relativas a movimentação ou lançamento em conta de depósitos ou de cartão de crédito."
        },
        {
          "title": "9.4 Cartão BNDES - Tarifa de Abertura de Crédito",
          "canais": "",
          "sigla": "T.A.C",
          "valor": "1% do limite de crédito (max. R$ 400,00)",
          "fator": "Cobrança de tarifa sobre o valor do limite de crédito concedido, limitado ao valor máximo de R$ 400,00, por cliente a cada 12 meses."
        }
        ]
      },
      {
        "name": "10 COLETA E ENTREGA EM DOMICÍLIO OU OUTRO LOCAL",
        "itens": [{
          "title": "10.1 Documentos em domicílio (Courier avulso)",
          "canais": "",
          "sigla": "TAB. REF. COLETA ENTR. DOMICILIO/OUT LOCAL",
          "valor": "R$ 8.00",
          "fator": "Entrega de documentos a domicílio."
        }]
      },
      {
        "name": "11 ATESTADOS, CERTIFICADOS E DECLARAÇÕES",
        "itens": [{
          "title": "11.1 Atestado de idoneidade financeira",
          "canais": "",
          "sigla": "TAR. REF. FORNECIMENTO DE ATESTADO",
          "valor": "R$ 50.00",
          "fator": "Fornecimento de atestado de idoneidade."
        }]
      },
      {
        "name": "12 CÓPIA OU SEGUNDA VIA DE COMPROVANTES E DOCUMENTOS",
        "itens": [{
          "title": "12.1 Fornecimento de cópia ou de segunda via de comprovantes e documentos",
          "canais": "",
          "sigla": "TAR. CÓPIA OU 2ª VIA COMPROV. E DOCS",
          "valor": "R$ 9.70",
          "fator": "Fornecimento de cópia de 2ª via de comprovantes e/ou documentos."
        }]
      },
      {
        "name": "13 SERVIÇO DE MALOTE",
        "itens": [{
          "title": "13.1 Assinatura mensal de serviços de malote (Courier Mensal) - valor mínimo R$ 40,00 e máximo R$ 150,00)",
          "canais": "",
          "sigla": "TAR. REF. MALOTE ASSINATURA MENSAL OU OUTRAS TARIFAS",
          "valor": "R$ 150.00",
          "fator": "Assinatura mensal de serviços de malote para atendimento em guichê de caixa."
        }]
      },
      {
        "name": "14 OUTROS SERVIÇOS",
        "itens": [{
          "title": "14.1 Cliente Bancário desde",
          "canais": "",
          "sigla": "DEB. TARIFA CLIENTE DESDE",
          "valor": "R$ 8.00",
          "fator": "Fornecimento da data de início de relacionamento com a cooperativa para outra instituição financeira."
        }]
      }
      ]
    }]
  }
  ];
  tarifas: any = [];

  ngOnInit() {
    // console.log(this.tarifas);
    this.carregarTarifas();

    //Evento ao modificar o formulário
    this.formFiltro.valueChanges.subscribe(val => {
      this.aguardarDigitacao();
    });

  }

  waitTime: any;
  waitingType = false;
  aguardarDigitacao() {
    // console.log('aguardar digitação...');
    this.waitingType = true;

    clearTimeout(this.waitTime);
    this.waitTime = setTimeout((a) => {
      // console.log('carregar');

      this.waitingType = false;
      this.carregarTarifas();

    }, 500);
  }


  carregarTarifas() {
    this.loadingSearch = true;

    if (this.formFiltro.value.busca == "") {
      this.filtro = false;
    } else {
      this.filtro = true;
    }

    // Prepara JSON
    var categoria = {
      nome: "",
      itens: [],
    };

    this.tarifas = [];

    // console.log("this.tarifasBd", this.tarifasBd);
    // console.log("this.tarifasBd[0].tipopessoa", this.tarifasBd[0].tipopessoa);

    // this.tarifas = this.tarifasBd;
    this.tarifas = this.getTarifas();

    //Para cada tipo de pessoa
    [...this.tarifas].forEach(
      (tipopessoa) => {
        // console.log('tipopessoa', tipopessoa);

        tipopessoa.exibir = false;

        // para cada servico
        [...tipopessoa.servicos].forEach(
          (servico) => {
            // console.log('servico', servico);
            servico.exibir = false;

            // para cada categoria
            [...servico.categorias].forEach(
              (categoria) => {
                // console.log('categoria', categoria);

                categoria.exibir = false;

                //Filtrar cada item
                categoria.itens = categoria.itens.filter(
                  (item) => {

                    // console.log("item", item);

                    var retorno = false;

                    retorno = retorno || (
                      (item.title || "")
                        .toLowerCase()
                        .normalize('NFKD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .indexOf(
                          this.formFiltro.value.busca
                            .toLowerCase()
                            .normalize('NFKD')
                            .replace(/[\u0300-\u036f]/g, '')) > -1);

                    retorno = retorno || (
                      (item.canais || "")
                        .toLowerCase()
                        .normalize('NFKD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .indexOf(
                          this.formFiltro.value.busca
                            .toLowerCase()
                            .normalize('NFKD')
                            .replace(/[\u0300-\u036f]/g, '')) > -1);

                    retorno = retorno || (
                      (item.sigla || "")
                        .toLowerCase()
                        .normalize('NFKD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .indexOf(
                          this.formFiltro.value.busca
                            .toLowerCase()
                            .normalize('NFKD')
                            .replace(/[\u0300-\u036f]/g, '')) > -1);

                    retorno = retorno || (
                      (item.fator || "")
                        .toLowerCase()
                        .normalize('NFKD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .indexOf(
                          this.formFiltro.value.busca
                            .toLowerCase()
                            .normalize('NFKD')
                            .replace(/[\u0300-\u036f]/g, '')) > -1);

                    tipopessoa.exibir = tipopessoa.exibir || retorno;
                    servico.exibir = servico.exibir || retorno;
                    categoria.exibir = categoria.exibir || retorno;

                    return retorno;
                  });

              });

          });

      });

    // [...this.tarifasBd[0].servicos[0].categorias].forEach(
    //   (tarifa) => {

    //     // console.log("tarifa", tarifa, "categoria", categoria);

    //     if (tarifa.name != categoria.nome) {

    //       if (categoria.nome != "") {
    //         this.tarifas.push(JSON.parse(JSON.stringify(categoria)));
    //       }

    //       categoria.nome = tarifa.name;
    //       categoria.itens = [];
    //     }

    //     categoria.itens = [...categoria.itens, ...tarifa.itens];

    //     // console.log("fim tarifa", tarifa, "fim categoria", categoria);

    //   }
    // );

    // console.log(this.tarifas);

    // Cancela o carregamento
    this.loading = false;
    this.loadingSearch = false;
  }


  getTarifas() {
    return JSON.parse(JSON.stringify(this.tarifasBd));
  }

}
