import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { environment } from '../../../environments/environment';

declare const WebViewer: any;

@Component({
  selector: 'ngx-pdftron',
  templateUrl: './pdftron.component.html',
  styleUrls: ['./pdftron.component.scss']
})
export class PdftronComponent implements OnInit {


  private STG_URL = environment.STG_URL + '/arquivo/';
  user = JSON.parse(localStorage.getItem('user'));

  @ViewChild('viewer', { static: false }) viewer: ElementRef;
  wvInstance: any;
  action: string = "novo";
  url: string = "https://user-images.githubusercontent.com/24848110/33519396-7e56363c-d79d-11e7-969b-09782f5ccbab.png";
  id: number = 0;
  data: any;
  fileName = '';
  fileURl = '';
  fileExte = '';
  constructor(
    protected dialogRef: NbDialogRef<PdftronComponent>
  ) {

  }

  ngAfterViewInit(): void {

    WebViewer({
      path: '../lib',
      initialDoc: this.fileURl,
      annotationUser: this.user.name,
      enableAnnotations: false,
      isReadOnly: true,
      extension: this.fileExte,
      documentType: this.fileExte,
    }, this.viewer.nativeElement).then(instance => {
      this.wvInstance = instance;

      // now you can access APIs through this.webviewer.getInstance()
      instance.openElement('notesPanel');
      // see https://www.pdftron.com/documentation/web/guides/ui/apis for the full list of APIs

      // or listen to events from the viewer element
      this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
        const [pageNumber] = e.detail;
        console.log(`Current page is ${pageNumber}`);
      });

      // or from the docViewer instance
      instance.docViewer.on('annotationsLoaded', () => {
        console.log('annotations loaded');
      });

      instance.docViewer.on('documentLoaded', this.wvDocumentLoadedHandler)
    })
  }

  ngOnInit() {


    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
    if (this.action == "novo") {
      console.log(this.data);
      this.fileName = this.data.nome;
      this.fileURl = this.STG_URL + this.data.tb_storage_user_app_id + '/' + this.data.id + '/' + this.data.file_name;

      if (this.data.extensao == 'csv') {
        this.fileExte = 'xls';
      } else {
        this.fileExte = this.data.extensao;
      }
    } else if (this.action == "blog") {
      console.log(this.url);
      this.fileName = this.data.titulo;
      this.fileURl  = this.data.link;
      console.log(this.data);
      console.log(this.id);
      
    }
    
  }

  wvDocumentLoadedHandler(): void {
    // you can access docViewer object for low-level APIs
    const docViewer = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;
    // and access classes defined in the WebViewer iframe
    const { Annotations } = this.wvInstance;
    // const rectangle = new Annotations.RectangleAnnotation();
    // rectangle.PageNumber = 1;
    // rectangle.X = 100;
    // rectangle.Y = 100;
    // rectangle.Width = 250;
    // rectangle.Height = 250;
    // rectangle.StrokeThickness = 5;
    // rectangle.Author = annotManager.getCurrentUser();
    // annotManager.addAnnotation(rectangle);
    // annotManager.drawAnnotations(rectangle.PageNumber);
    // see https://www.pdftron.com/api/web/WebViewer.html for the full list of low-level APIs
  }

  modalClose() {
    this.dialogRef.close({
      data: [],
      status: false
    });
  }

}
