import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nb-tabset-footer',
  templateUrl: './nb-tabset-footer.component.html',
  styleUrls: ['./nb-tabset-footer.component.scss']
})
export class NbTabsetFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
