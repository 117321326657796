import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ApiColaboradores } from '../../../../../../@core/api/colaboradores.service';
import { UtilsService } from '../../../../../../@core/utils';
import { DatePt } from '../../../../../../@core/utils/util-service.service';

@Component({
  selector: 'ngx-colaboradores-transferir',
  templateUrl: './colaboradores-transferir.component.html',
  styleUrls: ['./colaboradores-transferir.component.scss']
})
export class ColaboradoresTransferirComponent implements OnInit {

  lista_agencias: any;

  @Input() data: any;

  formTransferencia = this.fb.group({
    codigo: [{ value: '', disabled: true }],
    nome: [{ value: '', disabled: true }],
    centro_custo: [{ value: '', disabled: true }],
    novo_centro_custo: [null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(3)]],
    nome_novo_centro_custo: [{ value: '', disabled: true }],
    data_transferencia: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder, protected ref: NbDialogRef<ColaboradoresTransferirComponent>, private utils: UtilsService, private api: ApiColaboradores, private datePipe: DatePipe) { }

  ngOnInit() {

    this.api.apiGet('ficticio/todos-pas', '').subscribe(result => {
      this.lista_agencias = result.dados;
    });

    this.formTransferencia.get('codigo').patchValue(this.data.codigo);
    this.formTransferencia.get('nome').patchValue(this.data.nome);
    this.formTransferencia.get('centro_custo').patchValue(this.data.nome_pa + ' (PA ' + this.data.centro_custo + ')');

    // this.formTransferencia.get('data_transferencia').patchValue(new DatePt().getPtDayString(new Date()));
  }

  close() {
    this.ref.close();
  }

  realocar() {

    let formValue = this.formTransferencia.getRawValue();

    
    let data = {
      codigo: formValue.codigo,
      centro_custo_old: this.data.centro_custo,
      centro_custo_novo: formValue.novo_centro_custo,
      transferencia: 1,
      data_transferencia: this.datePipe.transform(formValue.data_transferencia, 'yyyy-MM-dd')
    };
    
    this.api.apiPost('realocados/novo', data).subscribe(response => {

      if(response.status == 200){

        this.ref.close();
        this.utils.showToast('top-right', 'success', 'Yeah!', 'Colaborador transferido com sucesso!');

      } else {
        this.utils.showToast('top-right', 'danger', 'Ops...', 'Erro na transferência do colaborador.');
      }

    })

  }

  get novo_centro_custo() {
    return this.formTransferencia.get('nome_novo_centro_custo').value;
  }

  old = 0;

  selecionarPa($event) {

    let registry = this.formTransferencia.get('novo_centro_custo');

    if (registry.valid) {

      if (this.old != $event.target.value) {
        this.old = registry.value;

        let agencia = this.lista_agencias[$event.target.value];

        if (agencia != undefined && agencia != null) {
          this.formTransferencia.get('nome_novo_centro_custo').patchValue(agencia[0].nome_pa);
        } else {
          this.formTransferencia.get('nome_novo_centro_custo').patchValue('');
        }

      }
    } else {
      this.formTransferencia.get('nome_novo_centro_custo').patchValue('');
    }

  }

}
