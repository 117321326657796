import { Component, OnInit } from '@angular/core';
import { ApiBase } from '../../../@core/api/api-base.service';
import { EnvironmentRoles } from '../../../@core/auth/roles.service';
import { NbThemeService } from '@nebular/theme';
import { UtilsService } from '../../../@core/utils';

@Component({
  selector: 'ngx-selic',
  templateUrl: './selic.component.html',
  styleUrls: ['./selic.component.scss']
})
export class SelicComponent implements OnInit {
  roles: any = new EnvironmentRoles();

  graficoDados = {
    labels: [],
    series: [],
    grafico: [],
  };


  //EChart
  options: any = {};
  themeSubscription: any;

  constructor(
    private theme: NbThemeService,
    private util: UtilsService,
    private api: ApiBase) {
  }

  ngOnInit() {

    // console.log(this.graficoDados);
    this.atualizarGrafico();

  }

  atualizarGrafico(params: any = {}) {

    //POST    '/api/geral/reclassificacao/'
    this.api.apiGetExternal("http://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarPeriodo(dataInicial=@dataInicial,dataFinalCotacao=@dataFinalCotacao)?", {
      '@dataInicial': "'01/01/2019'",
      '@dataFinalCotacao': "'29/01/2019'",
      '$top': '100',
      '$format': 'json',
      '$orderby': 'dataHoraCotacao',
      '$select': 'cotacaoCompra,cotacaoVenda,dataHoraCotacao' 
    }).subscribe(
      response => {

        console.log(response);

        // this.graficoDados.labels = this.util.getArrayListItems(this.inputGraficoDados.grafico, 'titulo');
        // this.graficoDados.series[0] = this.util.getArrayListItems(this.inputGraficoDados.grafico, 'quantidade');
        // this.graficoDados.series[1] = this.util.getArrayListItems(this.inputGraficoDados.grafico, 'valor');

        this.carregarGrafico();
      },
      error => {
        console.log(error);
      }
    );
  }

  tooltipFormatter(params) {
    var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
    let rez = params[0].axisValue + '<br />';
    // console.log(params); //quite useful for debug

    params.forEach(item => {
      // console.log(item); //quite useful for debug

      if (item.seriesName == 'Qtd') {
        var xx = colorSpan(item.color) + ' ' + item.seriesName + ': ' + this.util.numFormat(item.value, 2, false, 'a', 0, 0) + '<br />';

      } else if (item.seriesName == 'Valor') {
        var xx = colorSpan(item.color) + ' ' + item.seriesName + ': ' + this.util.numFormat(item.value, 2, true, 1, 1, 2) + '<br />';
      }

      rez += xx;
    });

    return rez;
  }

  //Linhas do gráfico
  serieLabelFormatter(params) {

    if (params.seriesName == 'Qtd') {
      // console.log(params.value);

      return this.util.numFormat(params.value, 0, false, 'a', 0, 0);

    } else if (params.seriesName == 'Valor') {
      // console.log(params.value);

      return this.util.numFormat(params.value, 0, true, 'a', 1, 0);
    }

  }

  //Barra de titulos Horizontal
  xAxisLabelFormatter(value) {
    // return this.util.getNomeMes(value);
    return value;
  }

  //Barra de titulos Vertical
  yAxisLabelFormatter(value) {
    return this.util.numFormat(value, 0, false, 'a', 0, 0);
  }

  getSeriesDataList(index) {
    return this.graficoDados.series[index];
  }

  ngAfterViewInit() {
  }

  carregarGrafico() {

    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      const colors: any = config.variables;
      const echarts: any = config.variables.echarts;

      this.options = {
        backgroundColor: echarts.bg,
        color: [
          colors.success,
          colors.info
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: this.tooltipFormatter.bind(this),
        },
        toolbox: {
          feature: {
            // dataView: {show: true, readOnly: false},
            // magicType: {show: true, type: ['line', 'bar']},
            // restore: {show: true, title: 'Restaurar'},
            // saveAsImage: { show: true, title: 'Baixar' }
          }
        },
        legend: {
          data: [
            'Qtd',
            'Valor'
          ],
          textStyle: {
            color: echarts.textColor,
          },
        },
        // grid: {
        //   top: 70,
        //   bottom: 50,
        // },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: false,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colors.info,
              },
            },
            axisLabel: {
              rotate: -25,
              // align: 'left',
              verticalAlign: 'middle',
              textStyle: {
                color: echarts.textColor,
              },
              formatter: this.xAxisLabelFormatter.bind(this)
            },
            axisPointer: {
              label: {
                formatter: params => {
                  return (
                    params.value + (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                  );
                },
              },
            },
            data: this.graficoDados.labels,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Quantidade',
            axisLine: {
              lineStyle: {
                color: echarts.axisLineColor,
              },
            },
            splitLine: {
              lineStyle: {
                color: echarts.splitLineColor,
              },
            },
            axisLabel: {
              textStyle: {
                color: echarts.textColor,
              },
              formatter: this.yAxisLabelFormatter.bind(this),
            },
          },
          {
            type: 'value',
            name: 'Valor',
            axisLine: {
              lineStyle: {
                color: echarts.axisLineColor,
              },
            },
            splitLine: {
              lineStyle: {
                color: echarts.splitLineColor,
              },
            },
            axisLabel: {
              textStyle: {
                color: echarts.textColor,
              },
              formatter: this.yAxisLabelFormatter.bind(this),
            },
          },
        ],
        series: [
          {
            name: 'Qtd',
            type: 'bar',
            smooth: true,
            label: {
              normal: {
                show: true,
                rotate: 0,
                formatter: this.serieLabelFormatter.bind(this),
                align: 'left',
                verticalAlign: 'middle',
                position: 'insideBottom',
                distance: 15,
                fontSize: 20,
              }
            },
            data: this.graficoDados.series[0],
          },
          {
            name: 'Valor',
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            label: {
              normal: {
                show: true,
                rotate: 0,
                formatter: this.serieLabelFormatter.bind(this),
                align: 'left',
                verticalAlign: 'middle',
                position: 'insideBottom',
                distance: 15,
                fontSize: 20,
              }
            },
            data: this.graficoDados.series[1],
          },
        ],
      };
    });
  }

}