import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Optional, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ApiPortalInovacao } from '../../../../../@core/api/portal-inovacao';
import { UtilsService } from '../../../../../@core/utils';
import { ModelPostComponent } from './model-post/model-post.component';

@Component({
  selector: 'ngx-create-edit',
  templateUrl: './create-edit.component.html',
  styleUrls: ['./create-edit.component.scss']
})
export class CreateEditComponent implements OnInit {

  @Input() form;
  @Input() data;
  @Input() categorys;

  @Input() author;

  @Output() updateTable: EventEmitter<any> = new EventEmitter();

  saving: boolean = false;

  @ViewChild('tags', { static: true }) tags;
  @ViewChild('tumb', { static: true }) tumb;
  @ViewChild('attachment', { static: true }) attachment;

  constructor(private cdr: ChangeDetectorRef, private dialog: NbDialogService, @Optional() private dialogRef: NbDialogRef<any>, private fb: FormBuilder, private utils: UtilsService, private api: ApiPortalInovacao) { }

  ngOnInit() {
    if (this.form == null) {
      const tags = JSON.parse(this.data.tags);

      this.form = this.fb.group({
        title: [this.data.title, Validators.required],
        resume: [this.data.resume, Validators.required],
        content: [this.data.content, Validators.required],
        category_id: [this.data.category_id, Validators.required],
        origin: [this.data.origin, Validators.required],
        branch: [this.data.branch, Validators.required],
        status: [this.data.status, Validators.required],
        tags: [[...tags], Validators.required],
        subcategory_id: [this.data.subcategory_id],
        children_id: [],
        tumbnail: [[]],
        attachment: [],
      });

      tags.forEach(tag => {
        this.tags.tags.push({ displayValue: tag });
      });
    }
  }

  get category() {
    if (typeof this.form.get('category_id').value == 'object') {
      return this.form.get('category_id').value;
    }
    return this.categorys ? this.categorys.filter(category => category.id == this.form.get('category_id').value)[0] : null;
  }

  get subcategorys() {
    const category = this.category;
    if (category != null && category.category.length && this.form.get('children_id') != '' && this.form.get('children_id') != null) {

      const category = this.category.category.filter(subcategory => subcategory.id == this.form.get('children_id').value.id)[0];

      if (category != null && category.sub_categorys.length) {
        return category.sub_categorys;
      }

      return [];
    }
    return category != null ? category.children : [];
  }

  changeCategory(event) {

    if (event.children.length) {
      this.form.get('subcategory_id').setValidators([Validators.required]);
    } else {
      this.form.get('subcategory_id').setValidators(null);
    }

    this.form.get('category_id').patchValue(event);

    this.form.get('subcategory_id').updateValueAndValidity();
    this.cdr.detectChanges();
  }

  selectSubCategory(event) {
    this.form.get('subcategory_id').patchValue(event);
  }

  selectChildren(event) {
    this.form.get('children_id').patchValue(event);
  }

  onSelectfile(event, control) {

    if (event.target.files != null) {
      const archive = event.target.files.item(0);

      let reader = new FileReader();
      reader.readAsDataURL(archive);

      reader.onload = (e: any) => {

        switch (control) {
          case 'tumbnail':
            const image = new Image();
            image.src = e.target.result;
            image.onload = rs => {
              const img_height = parseInt(rs.currentTarget['height']);
              const img_width = parseInt(rs.currentTarget['width']);

              if (img_height == 520 && img_width == 1080) {
                this.form.get(control).patchValue(archive);
                this.form.updateValueAndValidity();
              } else {
                this.utils.showInfo('Atenção', 'A imagem deve ter a proporção 1080x520 pixels', 'warning');
                this.removeFile(control, event.target);
              }

            }
            break;
          case 'attachment':
            this.form.get(control).patchValue(archive);
            this.form.updateValueAndValidity();
            break;
        }

      };

    }
  }

  createPost() {
    this.dialog.open(ModelPostComponent, {
      context: {
        form: this.form
      }
    });
  }

  buildPayload() {
    const formData = new FormData();

    formData.append('author', this.author.name);

    const category = this.form.get('category_id').value;
    const subcategory = this.form.get('subcategory_id').value;
    const children = this.form.get('children_id').value;

    if (typeof category == 'object') {
      if (children) {
        this.form.get('category_id').patchValue(children.id);
      } else {
        this.form.get('category_id').patchValue(category.id);
      }
    }

    if (subcategory) {
      this.form.get('subcategory_id').patchValue(subcategory.id);
    }

    for (const field in this.form.controls) {
      const fieldValue = this.form.get(field).value;
      formData.append(field, field == 'tags' ? JSON.stringify(fieldValue) : fieldValue);
    }

    return formData;
  }

  save() {
    const payload = this.buildPayload();

    this.api.apiPost('posts', payload).subscribe(response => {

      this.utils.showToast('top-right', response.message, response.dados)

      if (response.status == 200 || response.status == 201) {

        this.form.reset();
        this.updateTable.emit({});

        this.attachment.nativeElement.value = null;
        this.tumb.nativeElement.value = null;
        this.form.get('tags').patchValue([]);
        this.tags.tags = [];
        this.saving = false;
      }

      this.saving = false;
    })
  }

  update() {
    this.saving = true;

    const payload = this.buildPayload();
    payload.append('id', this.data.id);

    this.api.apiPost('posts/update', payload).subscribe(response => {

      this.utils.showToast('top-right', response.message, response.dados)

      if (response.status == 200 || response.status == 201) {
        this.form.reset();
        this.form.get('tags').patchValue([]);
        this.dialogRef.close({ update: true });

        this.attachment.nativeElement.value = null;
        this.tumb.nativeElement.value = null;
        this.tags.tags = [];
      }

      this.saving = false;
    })
  }

  onTagsChanged(event) {

    if (event.change == 'add') {
      const tags = this.form.get('tags').value;
      tags.push(event.tag.displayValue);
    } else {
      const tags = this.form.get('tags').value;
      this.form.get('tags').patchValue(this.utils.arrayRemove(tags, event.tag.displayValue));
    }

    this.form.get('tags').updateValueAndValidity();
  }

  removeFile(controller, element) {
    this.form.get(controller).patchValue(null);
    element.value = null;
  }

  close() {
    this.tags.tags = [];
    this.dialogRef.close();
  }

  teste(log) {
    //render the new componen
    console.log('category_id', this.category);
  }

}
