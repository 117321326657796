import { Injectable } from '@angular/core';
import { ApiBase } from './api-base.service';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ApiColaboradores extends ApiBase {

    API_URL = environment.COL_URL + '/';

}