import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { LinksComponent } from './links.component';
import { RouterModule } from '@angular/router';
import { NbCardModule, NbIconModule, NbListModule, NbLayoutModule, NbActionsModule, NbSelectModule, NbInputModule, NbButtonModule } from '@nebular/theme';
import { FavoritosModule } from './favoritos/favoritos.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { DndModule } from 'ngx-drag-drop';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManterModule } from './manter/manter.module';



@NgModule({
  declarations: [LinksComponent],
  imports: [
    CommonModule,
    RouterModule,
     
    FormsModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbInputModule,

    NbButtonModule,
    NbLayoutModule,
    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,
    NbActionsModule,

    NbListModule,
    
    DndModule,

    FavoritosModule,

    ManterModule,

    DragDropModule,
  ],
  exports: [
    LinksComponent,
  ],
  entryComponents: [
    LinksComponent,
  ]
})
export class LinksModule { }
