import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  params: any;

  // formPagamentos = this.fb.group({
  //   data_movimento: ['',
  //   [
  //     Validators.required,
  //   ]
  // ],
  //   valor_conta_capital: ['',
  //     [
  //       Validators.required,
  //       Validators.min(0),
  //     ]
  //   ],
  //   valor_baixado_conta_capital: ['',
  //     [
  //       Validators.required,
  //       Validators.min(0),
  //     ]
  //   ],
  //   valor_pago_associado: ['',
  //     [
  //       Validators.required,
  //       Validators.min(0),
  //     ]
  //   ],
  //   valor_devolvido: ['',
  //     [
  //       Validators.required,
  //       Validators.min(0),
  //     ]
  //   ],
  //   status_tipos_id: ['',
  //     [
  //       Validators.required,
  //     ]
  //   ],
  //   observacao: ['',
  //     [
  //       Validators.maxLength(500),
  //     ]
  //   ],
  // });

  // public tipoStatus: any;

  constructor(
    // private fb: FormBuilder,
    protected dialogRef: NbDialogRef<ErrorDialogComponent>,
    // private apiDemissionario: ApiDemissionarios
  ) {

  }

  ngOnInit() {
    

    console.log(this.params);

    // console.log(this.action, this.params);
  }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }

  // get data_movimento() { return this.formPagamentos.get('data_movimento'); }

  // submit() {

  // }
}
