import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'ngx-auth';
import * as jwt_decode from 'jwt-decode';


import { TokenStorage } from './token-storage.service';
import { Credential } from './credential';
import { AccessData } from './access-data';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationService implements AuthService {

  API_URL = environment.API_URL + '/auth';
  // API_URL = 'http://10.151.2.149:8094/api/auth';
  API_ENDPOINT_LOGIN = '/login';
  API_ENDPOINT_REFRESH = '/refresh';
  API_ENDPOINT_REGISTER = '/register';

  public onCredentialUpdated$: Subject<AccessData>;

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage
  ) {
    this.onCredentialUpdated$ = new Subject();
  }

  /**
 * Check, if user already authorized.
 * @description Should return Observable with true or false values
 * @returns {Observable<boolean>}
 * @memberOf AuthService
 */
  public isAuthorized(): Observable<boolean> {
    console.log(this.isTokenExpired());
    return this.tokenStorage.getToken().pipe(map(token => !!token));
  }

  public getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    // console.log(decoded);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    // console.log(date);

    return date;
  }

  // pergunta se o tokem esta espirado true expirado e false valido
  public isTokenExpired(token?: string): boolean {
    if (!token) token = localStorage.getItem('Token');
    if (!token) return true;

    // console.log("service", token);

    const date = this.getTokenExpirationDate(token);
    // console.log(date);

    if (date === undefined) return false;
    // console.log("token expired",date.valueOf() > new Date().valueOf());

    return !(date.valueOf() > new Date().valueOf());
  }


  /**
   * Get access token
   * @description Should return access token in Observable from e.g. localStorage
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    return this.tokenStorage.getToken();
  }

  /**
 * Get user roles
 * @returns {Observable<any>}
 */
  public getUserRoles(): Observable<any> {
    return this.tokenStorage.getUserRoles();
  }

  /**
   * Function, that should perform refresh token verifyTokenRequest
   * @description Should be successfully completed so interceptor
   * can execute pending requests or retry original one
   * @returns {Observable<any>}
   */
  public refreshToken(): Observable<AccessData> {
    return this.tokenStorage.getRefreshToken().pipe(
      switchMap((refreshToken: string) => {
        return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?', {});
      }),
      tap(this.saveAccessData.bind(this)),
      catchError(err => {
        this.logout();
        return throwError(err);
      })
    );
  }

  /**
 * Function, checks response of failed request to determine,
 * whether token be refreshed or not.
 * @description Essentialy checks status
 * @param {Response} response
 * @returns {boolean}
 */
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  public verifyTokenRequest(url: string): boolean {
    return url.endsWith(this.API_ENDPOINT_REFRESH);
  }

  /*
 * Submit login request
 * @param {Credential} credential
 * @returns {Observable<any>}
 */
  public login(credential: Credential): Observable<any> {

    credential.email = credential.email.replace("@sicoob.com.br", "");

    // Expecting response from API
    // tslint:disable-next-line:max-line-length
    // {"id":1,"username":"admin","password":"demo","email":"admin@demo.com","accessToken":"access-token-0.022563452858263444","refreshToken":"access-token-0.9348573301432961","roles":["ADMIN"],"pic":"./assets/app/media/img/users/user4.jpg","fullname":"Mark Andre"}
    return this.http.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN, credential).pipe(
      map((result: any) => {
        //console.log(result);

        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      tap(this.saveAccessData.bind(this)),
      catchError(this.handleError('login', []))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      // return from(result);

      // const router = this.injector.get(Router);
      // if (error.rejection.status === 401 || error.rejection.status === 403) {
      // if (error.status === 401) {
      // 	this.router.navigate(['/login']);
      // }

      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('Um erro ocorreu:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Código retornado da back-end ${error.status}, ` +
          `corpo foi: ${error.error.message}`);
      }
      // return an observable with a user-facing error message
      return throwError(error.error);
    };

  }

  /**
   * Logout
   */
  public logout(refresh?: boolean): void {
    this.tokenStorage.clear();
    if (refresh) {
      location.reload(true);
    }
  }

  /**
   * Save access data in the storage
   * @private
   * @param {AccessData} data
   */
  private saveAccessData(accessData: AccessData) {

    // console.log('accessData.user',accessData.user);

    if (typeof accessData !== 'undefined') {
      // Salva as permissões do back no environment
      environment.roles = accessData.roles;

      // Registra no Storage
      this.tokenStorage
        .setToken(accessData.token)
        .setRefreshToken(accessData.refreshToken)
        .setUserRoles(accessData.roles)
        .setUser(accessData.user);
      this.onCredentialUpdated$.next(accessData);
    }
  }
}
