import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../../@core/utils';

@Component({
  selector: 'ngx-cartao-de-visita',
  templateUrl: './cartao-de-visita.component.html',
  styleUrls: ['./cartao-de-visita.component.scss']
})
export class CartaoDeVisitaComponent implements OnInit {

  action: string = "";
  data: any;
  params: any;
  STG_URL: string = environment.STG_URL + '/';
  
  // public tipoStatus: any;
  constructor(
    public utils: UtilsService,
    protected dialogRef: NbDialogRef<any>,
  ) {

  }

  ngOnInit() {
    // console.log(this.data);
  }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: data,
      status: status
    });
  }
}
