import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAccordionModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbDialogModule, NbIconModule, NbInputModule, NbRadioModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CarregarCamposComponent } from './carregar-campos.component';

@NgModule({
  declarations: [CarregarCamposComponent],
  imports: [
    CommonModule,

    NbCardModule,
    NbInputModule,
    NbIconModule,
    NbAccordionModule,
    NbDatepickerModule,
    NbCheckboxModule,
    NbRadioModule,
    NbSelectModule,
    NbIconModule,
    NbButtonModule,
    NbDialogModule,
    NbTooltipModule,

    FormsModule,
    ReactiveFormsModule,

    NgxFileDropModule,
    NgxMaskModule
  ],
  exports: [CarregarCamposComponent],
  entryComponents: [CarregarCamposComponent]
})
export class CarregarCamposModule { }
