import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { RestformService } from '../../../../../@core/api/restform.service';
import { UtilsService } from '../../../../../@core/utils';

@Component({
  selector: 'ngx-editar-campo',
  templateUrl: './editar-campo.component.html',
  styleUrls: ['./editar-campo.component.scss']
})
export class EditarCampoComponent implements OnInit {

  @Input() component: any;
  @Input() tipo_api: any;

  loading = false;

  formConfig = this.fb.group({
    titulo: null,
    placeholder: null,
    obrigatorio: false,

    tamanho_campo: null,
    min_caracteres: null,
    max_caracteres: null,

    min_val: null,
    max_val: null,

    min_data: null,

    tipo_ordenacao: null,
    opcoes: this.fb.array([]),

    max_tamanho: null,

    divisao: null,

    indicador: null,
    serie: null,
    linha: null
  });

  selectItems: any = {
    obrigatorio: [
      { id: true, name: 'Sim' },
      { id: false, name: 'Não' }
    ],
    tamanho: [
      { id: 'col-md-1', name: '1' },
      { id: 'col-md-2', name: '2' },
      { id: 'col-md-3', name: '3' },
      { id: 'col-md-4', name: '4' },
      { id: 'col-md-5', name: '5' },
      { id: 'col-md-6', name: '6' },
      { id: 'col-md-7', name: '7' },
      { id: 'col-md-8', name: '8' },
      { id: 'col-md-9', name: '9' },
      { id: 'col-md-10', name: '10' },
      { id: 'col-md-11', name: '11' },
      { id: 'col-md-12', name: '12' },
    ],
    tipo_ordenacao: [
      { id: 'list', name: 'Lista' },
      { id: 'column', name: 'Coluna' },
    ]
  };

  divisoes = [];
  indicadores = [];
  informacao_indicador = [];

  constructor(protected dialogRef: NbDialogRef<EditarCampoComponent>, private fb: FormBuilder, private utils: UtilsService, public api: RestformService) {

  }

  ngOnInit() {


    this.formConfig.get('titulo').patchValue(this.component.title);
    this.formConfig.get('placeholder').patchValue(this.component.placeholder);

    this.formConfig.get('min_caracteres').patchValue(this.component.min_caracteres);
    this.formConfig.get('max_caracteres').patchValue(this.component.max_caracteres);

    this.formConfig.get('min_val').patchValue(this.component.min_val);
    this.formConfig.get('max_val').patchValue(this.component.max_val);

    this.formConfig.get('min_data').patchValue(this.component.min_data);
    this.formConfig.get('max_tamanho').patchValue(this.component.max_tamanho);

    if (this.component.obrigatorio != null) {
      this.formConfig.get('obrigatorio').patchValue(this.component.obrigatorio);
    } else {
      this.formConfig.get('obrigatorio').patchValue(false);
    }

    if (this.component.tamanho_campo != null) {
      this.formConfig.get('tamanho_campo').patchValue(this.component.tamanho_campo);
    } else {
      this.formConfig.get('tamanho_campo').patchValue('col-md-12');
    }

    if (this.component.tipo_ordenacao != null) {
      this.formConfig.get('tipo_ordenacao').patchValue(this.component.tipo_ordenacao);
    } else {
      this.formConfig.get('tipo_ordenacao').patchValue('list');
    }



    // console.log("Passou por aqui");

    switch (this.tipo_api) {
      case 'scopi':



        this.loading = true;

        this.api.apiGet('registros/externos/divisoes').subscribe(response => {
          this.divisoes = response.dados;

          this.divisoes.shift();
        });

        if (this.component.api_data != undefined) {
          let data = this.component.api_data;

          this.divisao(data.divisao);
          this.detalheIndicador(data.indicador);

          this.formConfig.get('divisao').patchValue(data.divisao);

          this.formConfig.get('indicador').patchValue(data.indicador);
          this.formConfig.get('serie').patchValue(data.serie);
          this.formConfig.get('linha').patchValue(data.linha);
        } else {
          this.formConfig.get('indicador').disable();
          this.formConfig.get('serie').disable();
          this.formConfig.get('linha').disable();
        }

        this.formConfig.get('divisao').setValidators([Validators.required]);
        this.formConfig.get('indicador').setValidators([Validators.required]);
        this.formConfig.get('serie').setValidators([Validators.required]);
        this.formConfig.get('linha').setValidators([Validators.required]);

        this.formConfig.updateValueAndValidity();

        break;
    }



    if (this.component.options != null) {
      this.component.options.forEach(option => {
        this.addOption(option.id, option.name);
      });
    }

  }

  get size() {
    return this.formConfig.get('tamanho_campo').value;
  }

  get min_val() {
    return this.formConfig.get('min_val').value;
  }

  get max_val() {
    return this.formConfig.get('max_val').value;
  }

  get min_data() {
    return this.formConfig.get('min_data').value;
  }

  get controls() {
    return this.formConfig.controls;
  }

  get formArray() {
    return this.controls.opcoes as FormArray;
  }

  get opcoes() {
    return this.formConfig.get('opcoes').value;
  }

  get hasOption() {
    return this.formArray.length > 0;
  }

  addOption(id?, nome?) {
    if (id == null) {
      this.formArray.push(this.fb.group({
        id: [this.utils.uuid, [Validators.required]],
        name: ['Título da Opção', [Validators.required]]
      }));
    } else {
      this.formArray.push(this.fb.group({
        id: [id, [Validators.required]],
        name: [nome, [Validators.required]]
      }));
    }
  }

  removeOption(index) {
    this.formArray.removeAt(index);
  }

  close() {
    this.dialogRef.close();
  }

  salvar() {

    if (this.min_val >= this.max_val) {
      this.utils.showToast('top-right', 'danger', 'O valor mínimo não pode ser maior ou igual ao valor máximo')
      return;
    }

    this.close();

    this.component.title = this.formConfig.get('titulo').value;
    this.component.obrigatorio = this.formConfig.get('obrigatorio').value;
    this.component.placeholder = this.formConfig.get('placeholder').value;
    this.component.tamanho_campo = this.formConfig.get('tamanho_campo').value;

    this.component.min_caracteres = this.formConfig.get('min_caracteres').value
    this.component.max_caracteres = this.formConfig.get('max_caracteres').value;

    this.component.min_data = this.formConfig.get('min_data').value;
    this.component.tipo_ordenacao = this.formConfig.get('tipo_ordenacao').value;

    this.component.max_tamanho = this.formConfig.get('max_tamanho').value;

    if (this.min_val > 0) {
      this.component.min_val = this.formConfig.get('min_val').value;
    }

    if (this.max_val > 0) {
      this.component.max_val = this.formConfig.get('max_val').value;
    }

    switch (this.tipo_api) {
      case 'scopi':
        this.component.api_data = { divisao: this.formConfig.get('divisao').value, indicador: this.formConfig.get('indicador').value, serie: this.formConfig.get('serie').value, linha: this.formConfig.get('linha').value };
        break;
    }

    let options = [];

    this.formArray.controls.forEach(control => {
      options.push(control.value)
    });

    this.component.options = options;
  }

  enviar(value) {
    console.log(value);

  }

  divisao(value) {
    this.api.apiGet('registros/externos', {id: (value.hasOwnProperty('id') ? value.id : value)}).subscribe(response => {
      this.indicadores = response.dados;
      this.formConfig.get('indicador').enable();
    });
  }

  detalheIndicador(value) {
    this.loading = true;
    this.api.apiGet('registros/externos/' + (value.hasOwnProperty('id') ? value.id : value), {}).subscribe(response => {
      this.informacao_indicador = response.dados.series;
      this.loading = false;
      this.formConfig.get('serie').enable();
    });
  }

  serieIndicador(value) {
    this.formConfig.get('linha').enable();
  }

}
