import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiBase } from '../../../@core/api/api-base.service';

@Component({
  selector: 'ngx-tarifas-pessoa-fisica',
  templateUrl: './tarifas-pessoa-fisica.component.html',
  styleUrls: ['./tarifas-pessoa-fisica.component.scss']
})
export class TarifasPessoaFisicaComponent implements OnInit {

  //Tabela
  settingsServicos = {
    mode: "external",
    noDataMessage: "Não há dados.",
    actions: {
      columnTitle: "Ações",
      add: false,
      edit: false,
      delete: false,
      position: "right",
    },
    hideSubHeader: true,
    columns: {
      pacote: {
        title: 'Pacote',
      },
      valor: {
        title: 'Valor',
        valuePrepareFunction(value, row, cell) {
          if (value == 0) {
            return 'isento'
          } else {
            return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
          }
        }
      },
      ted: {
        title: 'TED'
      },
      cheques: {
        title: 'Cheques',
      },
      extratos_30_dias: {
        title: 'Extratos 30 dias',
      },
      extratos_anteriores: {
        title: 'Extratos anteriores',
      },
      saque_atm: {
        title: 'Saques ATM'
      },
      saque_presencial: {
        title: 'Saques (P)'
      },
      transferencia_atm: {
        title: 'Transf. ATM',
        valuePrepareFunction(value, row, cell) {
          if (value == -1) {
            return 'ilimitado'
          } else {
            return value;
          }
        }
      },
      sustacao: {
        title: 'Sustação'
      },
      courier: {
        title: 'Courier'
      },
      cadastro: {
        title: 'Cadastro',
        valuePrepareFunction(value, row, cell) {
          if (value == 0) {
            return 'isento'
          } else {
            return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
          }
        }
      },
    },
  };

  public sourceServicos: LocalDataSource;

  constructor(
    private api: ApiBase,
  ) {
    this.sourceServicos = new LocalDataSource();
  }

  ngOnInit() {
    this.updateSource();
  }

  updateSource() {

    //Buscar Id do Tipo de Contrato
    this.api.apiGet("permissoes", []).subscribe(
      response => {

        // console.log(response);
        console.log('Pacotes Pessoa Fisica');

        this.sourceServicos.load(
          [
            {
              pacote: 'Essencial /Bancoob',
              valor: 0,
              cadastro: 0,
              cheques: '10',
              extratos_30_dias: '2',
              extratos_anteriores: '0',
              saque_atm: '4',
              saque_presencial: '0',
              transferencia_atm: '2',
              ted: '0',
              sustacao: '0',
              courier: '0'
            },
            {
              pacote: 'I',
              valor: 12.5,
              cadastro: 0,
              cheques: '20',
              extratos_30_dias: '2',
              extratos_anteriores: '2',
              saque_atm: '4',
              saque_presencial: '0',
              transferencia_atm: '-1',
              ted: '0',
              sustacao: '0',
              courier: '0'
            },
            {
              pacote: 'II',
              valor: 19,
              cadastro: 0,
              cheques: '20',
              extratos_30_dias: '4',
              extratos_anteriores: '2',
              saque_atm: '4',
              saque_presencial: '0',
              transferencia_atm: '-1',
              ted: '1',
              sustacao: '0',
              courier: '0'
            },
            {
              pacote: 'III',
              valor: 25.5,
              cadastro: 0,
              cheques: '20',
              extratos_30_dias: '6',
              extratos_anteriores: '4',
              saque_atm: '6',
              saque_presencial: '0',
              transferencia_atm: '-1',
              ted: '2',
              sustacao: '0',
              courier: '0'
            },
            {
              pacote: 'IV',
              valor: 37,
              cadastro: 0,
              cheques: '20',
              extratos_30_dias: '6',
              extratos_anteriores: '4',
              saque_atm: '8',
              saque_presencial: '0',
              transferencia_atm: '-1',
              ted: '3',
              sustacao: '0',
              courier: '0'
            },
            {
              pacote: 'Premium',
              valor: 48,
              cadastro: 0,
              cheques: '60',
              extratos_30_dias: '16',
              extratos_anteriores: '8',
              saque_atm: '12',
              saque_presencial: '0',
              transferencia_atm: '-1',
              ted: '6',
              sustacao: '2',
              courier: '0'
            },
            {
              pacote: 'Universitário',
              valor: 6.5,
              cadastro: 0,
              cheques: '10',
              extratos_30_dias: '2',
              extratos_anteriores: '2',
              saque_atm: '0',
              saque_presencial: '12',
              transferencia_atm: '0',
              ted: '2 (P)',
              sustacao: '0',
              courier: '0'
            },
          ]
        );

      },
      error => {
        console.log(error);
      }
    );
  }

}
