import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../@core/auth/auth.guard';
import { PlanEstrategicoComponent } from './plan-estrategico.component';
import { BannerComponent } from './banner/banner.component';


const routes: Routes = [{
  path: '',
  component: PlanEstrategicoComponent,
  canActivate: [
    AuthGuard,
  ],
  children: [
    {
      path: '',
      component: BannerComponent,
    },
    // {
    //   path: '**',
    //   // component: NotFoundComponent,
    // },
  ],
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlanEstrategicoRoutingModule { }
