import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ApiPortalInovacao } from '../../../../../@core/api/portal-inovacao';
import { UtilsService } from '../../../../../@core/utils/util-service.service';

@Component({
  selector: 'ngx-create-edit',
  templateUrl: './create-edit.component.html',
  styleUrls: ['./create-edit.component.scss']
})
export class CreateEditComponent implements OnInit {

  @Input() data: any;

  form = this.fb.group({
    image: ['', Validators.required],
    description: ['', Validators.required],
    type_redirect: [''],
    url: [''],
  });

  loading: boolean = false;

  constructor(private fb: FormBuilder, private api: ApiPortalInovacao, private ref: NbDialogRef<CreateEditComponent>, private utils: UtilsService) { }

  ngOnInit() {
    if (this.data != null && this.data != undefined) {
      this.form.get('description').patchValue(this.data.description);
      this.form.get('url').patchValue(this.data.url);
      this.form.get('type_redirect').patchValue(this.data.type_redirect);

      if(this.data.type_redirect == null) {
        this.form.get('url').disable();
      }

    }
  }

  //Function to save image in form
  onSelectfile(event) {
    if (event.target.files != null) {
      const archive = event.target.files.item(0);

      let reader = new FileReader();
      reader.readAsDataURL(archive);

      reader.onload = () => {
        this.form.get('image').patchValue(archive);
        this.form.updateValueAndValidity();
      }

    }
  }

  //function to remove image file from the form
  removeFile(element) {
    this.form.get('image').patchValue(null);
    element.value = null;
  }

  //function to set type_redirect value
  setTypeRedirect() {
    this.form.get('url').setValidators([Validators.required]);
    this.form.get('url').enable();
  }

  //function to close the dialog
  close() {
    this.ref.close();
  }

  //function to save the banner
  //use api.post to save banner
  save() {
    this.loading = true;

    const payload = this.buildPayload();
    this.api.apiPost('banner', payload).subscribe(response => {

        if(response.status == 'success') {
          this.utils.showToast('top-right', 'success', 'Banner salvo com sucesso!');
          this.ref.close({response: response, update: true});
        } else {
          this.utils.showToast('top-right', 'danger', 'Limite de banners atingido!');
        }

      },
      (error) => {
        console.log(error);
        this.utils.showToast('top-right', 'danger', 'Erro ao salvar o banner!');
      }
    );
  }

  //function to update the banner
  //use api.apiPost to update banner
  update() {
    const payload = this.buildPayload();
    payload.append('id', this.data.id);

    this.api.apiPost('banner/update', payload).subscribe(
      (response) => {
        this.ref.close({response: response, update: true});
      },
      (error) => {
        console.log(error);
      }
    );
  }


  buildPayload() {
    const formData = new FormData();

    for (const field in this.form.controls) {
      const fieldValue = this.form.get(field).value;
      formData.append(field, field == 'tags' ? JSON.stringify(fieldValue) : fieldValue);
    }

    return formData;
  }

}
