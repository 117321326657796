import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { TokenStorage } from '../auth/token-storage.service';
// import { UtilsService } from '../services/utils.service';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../utils/util-service.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBase {

  // @ViewChild('disabledEsc', { read: TemplateRef, static: false }) disabledEscTemplate: TemplateRef<HTMLElement>;

  API_URL = environment.API_URL + '/';

  private TOKEN;
  public httpOptions: any;
  private user = JSON.parse(localStorage.getItem('user'));

  promiseReturn: any;

  constructor(
    public http: HttpClient,
    private tokenStorage: TokenStorage,
    private util: UtilsService
  ) {
    // console.log('entrou', JSON.parse(localStorage.getItem('user')));
  }

  updateHeaders(extraHeader: any = []) {
    // console.log('user', this.user);
    this.user = JSON.parse(localStorage.getItem('user'));
    this.tokenStorage.getToken().subscribe(
      token => {

        this.TOKEN = token;
        let headers: HttpHeaders = new HttpHeaders();
        // console.log(this.user);
        if (token != null) {
          // headers = headers.append('Content-Type', 'application/json');
          headers = headers.append('Accept', 'application/json, text/plain, */*');
          headers = headers.append("Api-Token", this.user.key_access);
          headers = headers.append("Authorization", this.TOKEN);
          extraHeader.forEach(
            (header) => {
              headers = headers.append(header[0], header[1]);
            }
          );
        }
        this.httpOptions = {
          headers: headers
        };
      }
    );
  }

  public getToken() {
    return this.TOKEN.value;
  }
  public getUrl() {
    return this.API_URL;
  }
  public getUrlFavicon(url: string) {
    return "https://www.google.com/s2/favicons?domain=" + url;
  }
  public getUser() {
    return this.user;
  }

  public apiRequest(metodo: string, ROTA: string, data: any): Observable<any> {
    this.updateHeaders();

    const url = metodo == 'get' ? ROTA + "?" + this.util.urlParam(data) : ROTA;

    return this.http.request(metodo, this.API_URL + url, { headers: this.httpOptions['headers'], body: data }).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} ROTA
   * @returns {Observable<any>}
   */
  public apiGet(ROTA: string, PARAMS?: any, extraHeader: any = []): Observable<any> {
    this.updateHeaders(extraHeader);

    // Expecting response from API
    return this.http.get(this.API_URL + ROTA + (!this.util.isEmpty(PARAMS) ? "?" + this.util.urlParam(PARAMS) : ""), this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  public apiGetae(ROTA: string, PARAMS: any, extraHeader: any = []): Observable<any> {
    this.updateHeaders(extraHeader);

    // Expecting response from API
    return this.http.get(ROTA + (!this.util.isEmpty(PARAMS) ? "?" + this.util.urlParam(PARAMS) : ""), this.httpOptions).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} ROTA
   * @returns {Observable<any>}
   */
  public apiGetFullPath(ROTA: string, PARAMS?: any): Observable<any> {
    this.updateHeaders();
    this.httpOptions.headers.append("Access-Control-Allow-Origin", "*");

    // Expecting response from API
    ROTA += (typeof PARAMS != "undefined" ? (ROTA.includes("?") ? "&" : "?") + this.util.urlParam(PARAMS) : "");

    return this.http.get(ROTA, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} ROTA
   * @returns {Observable<any>}
   */
  public apiGetExternal(ROTA: string, PARAMS?: any): Observable<any> {
    this.updateHeaders();
    // Expecting response from API
    ROTA += (typeof PARAMS != "undefined" ? (ROTA.includes("?") ? "&" : "?") + this.util.urlParam(PARAMS) : "");

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Access-Control-Allow-Origin", "*");

    return this.http.get(ROTA, { headers: headers }).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} ROTA
   * @returns {Promise<any>}
   */
  apiGetPromise(ROTA: string, PARAMS: any): Promise<any> {
    this.updateHeaders();

    // console.log('promessa');

    return this.http.get(this.API_URL + ROTA + "?" + this.util.urlParam(PARAMS), this.httpOptions).toPromise().then(a => { return a });

    // console.log('retorno promessa', JSON.stringify(promise), promise);

    // environment.roles = Object(promise).roles;

    // // Expecting response from API
    // return promise;
  }

  /**
   * Method is use to download file.
   * @param {String} ROTA
   * @param {Array} PARAMS - type of the document.s
   * @returns {String} Url
   */
  public apiGetFile(ROTA: string, PARAMS: any): Observable<any> {
    this.updateHeaders();

    // Expecting response from API
    return this.http.get(this.API_URL + ROTA + "?" + this.util.urlParam(PARAMS),
      {
        responseType: 'arraybuffer',
        headers: this.httpOptions.headers
      }
    ).pipe(
      map((result: any) => {
        let blob = new Blob([result], { type: "application/octet-stream;" });
        let url = window.URL.createObjectURL(blob);
        return url;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Method is use to download file.
   * @param {String} ROTA
   * @param {Array} PARAMS - type of the document.s
   * @returns {String} Url
   */
  public apiGetFileFullPath(ROTA: string, PARAMS: any): Observable<any> {
    this.updateHeaders();

    // Expecting response from API
    return this.http.get(ROTA + "?" + this.util.urlParam(PARAMS),
      {
        responseType: 'arraybuffer',
        headers: this.httpOptions.headers
      }
    ).pipe(
      map((result: any) => {
        let blob = new Blob([result], { type: "application/octet-stream;" });
        let url = window.URL.createObjectURL(blob);
        return url;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }


  /**
     * Submit login request
     * @param {String} ROTA
     * @returns {Observable<any>}
     */
  public apiPost(ROTA: string, data: any): Observable<any> {
    this.updateHeaders();
    // Expecting response from API
    return this.http.post(this.API_URL + ROTA, data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  public apiPosta(ROTA: string, data: any): Observable<any> {
    this.updateHeaders();
    // Expecting response from API

    return this.http.post(ROTA, data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
   * Submit login request
   * @param {String} ROTA
   * @returns {Observable<any>}
   */
  public apiPostExternal(ROTA: string, data: any): Observable<any> {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Access-Control-Allow-Origin", "*");

    return this.http.post(ROTA, data, { headers: headers }).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
     * Submit login request
     * @param {String} credential
     * @returns {Observable<any>}
     */
  public apiPut(ROTA: string, data: any): Observable<any> {
    this.updateHeaders();
    // Expecting response from API
    return this.http.put(this.API_URL + ROTA, data, this.httpOptions).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
     * Submit login request
     * @param {String} ROTA
     * @returns {Observable<any>}
     */
  public apiDelete(ROTA: string, data?: any): Observable<any> {
    this.updateHeaders();

    let headers = { ...this.httpOptions };

    if (data != null) {
      Object.assign(headers, { body: data });
    }

    // Expecting response from API
    return this.http.delete(this.API_URL + ROTA, headers).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      // tap(this.saveAccessData.bind(this)),
      catchError(this.handleError)
    );
  }

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  public handleError(error: HttpErrorResponse) {

    console.log("Erro encontrado:", error);

    var alertText = "Alerta de Erro: " + error.status + "\n" +
      error.statusText + "\n" +
      error.message + "\n" +
      "Erros: \n";

    if (typeof error.error.message !== undefined) {
      if (error.error.message == 'Provided token is expired.') {
        window.location.reload();
      }

      alertText += "Mensagem: " + error.error.message + "\n";
    }

    if (typeof error.error.observacao !== undefined) {
      alertText += "Observação: " + error.error.observacao + "\n";
    }

    if (typeof error.error == 'string' ||
      error.status == 500) {
      var janelaErro = window.open("", "Erro", "width=700,height=900");
      janelaErro.document.write(error.error);

    } else {
      console.error(alertText);

      // this.util.showToast('center', alertText, 'danger');
    }

    // // Modal de demostração dos erros
    // var modal = this.dialogService.open(
    //   ErrorDialogComponent,
    //   {
    //     hasBackdrop: true,
    //     closeOnBackdropClick: false,
    //     closeOnEsc: false,
    //     hasScroll: true,
    //     context: {
    //       params: error
    //     }
    //   }
    // );

    // //onClose modal pelo botão close
    // modal.onClose.subscribe(data => {
    //   if (typeof data == undefined) {
    //     data = {
    //       data: [],
    //       status: false
    //     };
    //   }
    // });



    // const router = this.injector.get(Router);
    // if (error.rejection.status === 401 || error.rejection.status === 403) {
    if (error.status === 401) {
      console.log("Erro 401: ", error);

      // this.router.navigate(['/login']);
    }

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Um erro ocorreu:', error.error.message);

    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Código retornado de back-end ${error.status}, ` +
        `corpo foi: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }

}
