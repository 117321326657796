import { Component, OnDestroy, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbMenuItem, NbDialogService, NbSearchModule, NbSearchService } from '@nebular/theme';

import { LayoutService, UtilsService } from '../../../@core/utils';
import { map, takeUntil, filter, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StorageComponent } from '../../../storage/storage.component';
import { RamaisComponent } from '../../../ramais/ramais.component';
import { FormControl } from '@angular/forms';

import { environment } from '../../../../environments/environment';
import { EnvironmentRoles } from '../../../@core/auth/roles.service';
import { ApiBase } from '../../../@core/api/api-base.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  roles: any = new EnvironmentRoles();
  STG_URL: string = environment.STG_URL + '/';

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user = JSON.parse(localStorage.getItem('user'));

  toggleDarkTheme = new FormControl();
  toggleDarkThemeChecked: any;
  // user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    // {
    //   value: 'cosmic',
    //   name: 'Cosmic',
    // },
    // {
    //   value: 'corporate',
    //   name: 'Corporate',
    // },
  ];

  currentTheme = 'default';

  userMenu = [
    {
      title: 'Perfil',
      link: '/perfil/usuario'
    },
    {
      title: 'Sair',
      link: '/auth/logout'
    }
  ];

  globalSearch: any;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private dialogService: NbDialogService,
    private api: ApiBase,
    private utils: UtilsService,
    private searchService: NbSearchService,
  ) {
    this.roles = new EnvironmentRoles();

    // Busca Global Digitada
    this.searchService
      .onSearchInput()
      .subscribe((data: any) => {
        this.globalSearch = data.term;
        console.log('input', this.globalSearch);
      })

    // Busca Global Enviada
    this.searchService
      .onSearchSubmit()
      .subscribe((data: any) => {
        this.globalSearch = data.term;
        console.log('submit', this.globalSearch);
      })

    // console.log('2', this.roles);

    //Defini o switch do tema Dark para o armazenado
    this.toggleDarkTheme.setValue(this.currentTheme == 'dark');
  }

  async getPermissoes() {
    var permissoesJson = await this.api.apiGetPromise("permissoes", {}).then(a => { return a });

    // console.log(permissoesJson);
    // console.log(Object(permissoesJson));
    environment.roles = Object(permissoesJson).roles;
    this.roles = environment.roles;
  }

  ngOnInit() {
    // Atualizar permissões
    this.getPermissoes();

    // Instancia o tema
    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();

    // Mudança de telas
    this.themeService.onMediaQueryChange().pipe(
      map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
      takeUntil(this.destroy$),

    ).subscribe((isLessThanXl: boolean) => {
      this.userPictureOnly = isLessThanXl
    });

    // Mudanças de tema
    this.themeService.onThemeChange().pipe(
      map(({ name }) => name),
      takeUntil(this.destroy$),

    ).subscribe(themeName => {
      this.currentTheme = themeName;

      //Atualiza o switch de tema Dark
      this.toggleDarkTheme.setValue(this.currentTheme == 'dark');
    });

    // Busca tema do usuário e carrega
    this.api.apiGet('perfil/theme', {}).subscribe(
      response => {
        localStorage.setItem('themeName', response.dados.theme);
      },
      error => {
        console.log(error);
      },
      () => {
        // Fallback de tema padrão
        var themeStorange = localStorage.getItem('themeName');
        if (themeStorange == null) {
          localStorage.setItem('themeName', 'default');
        }

        // Muda o tema
        this.changeTheme(themeStorange);
      }
    );
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    // console.log('4', this.roles);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    localStorage.setItem('themeName', themeName);

    // Busca tema do usuário e carrega
    this.api.apiPut('perfil/theme', {
      theme: themeName
    }).subscribe(
      response => {
        localStorage.setItem('themeName', themeName);
      },
      error => {
        console.log(error);
        this.utils.showToast('top-left', 'warning', 'Erro ao salvar tema.', 'Alteração apenas temporária.');
      },
    );

  }

  activateDarkTheme(e) {
    //Verifica se o tema Dark foi ativado
    if (e == true) {
      this.changeTheme('dark');

    } else {
      this.changeTheme('default');
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  storage() {
    console.log("storage");
    var modal = this.dialogService.open(
      StorageComponent,
      {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        hasScroll: true,
        // context: {
        //   action: "novo",
        //   id: 0,
        //   data: {}
        // }
      }
    );
    modal.onClose.subscribe(data => {
      console.log(data);
      // if(data.status) {
      //   this.loadPage(this.aplicativo.current_page);
      // }
    });
  }

  openRamais(params: any = {}) {

    //Modal de Ramais
    var modal = this.dialogService.open(
      RamaisComponent,
      {
        hasBackdrop: true,
        closeOnBackdropClick: true,
        closeOnEsc: true,
        hasScroll: true,
        context: {
          params: params,
        }
      }
    );
    modal.onClose.subscribe(data => {
      console.log("Ramais", data);
      // if(data.status) {
      //   this.loadPage(this.aplicativo.current_page);
      // }
    });
  }

  // Mudança do tema antes de imprimir
  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint(event) {
    let actualTheme = localStorage.getItem('themeName');

    this.changeTheme('default');
    setTimeout(() => {
      this.changeTheme(actualTheme);
    }, 2000);
  }
}
