import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritosComponent } from './favoritos.component';
import { RouterModule } from '@angular/router';
import { NbCardModule, NbButtonModule, NbActionsModule, NbIconModule } from '@nebular/theme';

@NgModule({
  declarations: [FavoritosComponent],
  imports: [
    CommonModule,
    RouterModule,

    NbCardModule,
    NbButtonModule,
    
    NbIconModule,
    NbActionsModule,
  ],
  exports: [
    FavoritosComponent
  ],
  entryComponents: [
    FavoritosComponent
  ]
})
export class FavoritosModule { }
