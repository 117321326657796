import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { ApiColaboradores } from '../../../../../@core/api/colaboradores.service';
import { UtilsService } from '../../../../../@core/utils';
import { RealocarAgenciaComponent } from './realocar-agencia/realocar-agencia.component';

interface FSEntry {
  numero_pa?: string;
  nome_pa?: string;
  sobras?: string;
}

@Component({
  selector: 'ngx-sobras-areas',
  templateUrl: './sobras-areas.component.html',
  styleUrls: ['./sobras-areas.component.scss']
})
export class SobrasAreasComponent implements OnInit {

  allColumns = ['numero_pa', 'nome_pa', 'sobras', 'acao'];

  dataSource: NbTreeGridDataSource<FSEntry>;

  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  @Input() data: any;
  @Input() superintendente: any;

  constructor(protected ref: NbDialogRef<SobrasAreasComponent>, private dialogService: NbDialogService, private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>, public utils: UtilsService, private api: ApiColaboradores) { }

  ngOnInit() {
    
    let table_data = [];

    let key = Object.keys(this.data)[0];

    this.data[key].forEach(agencia => {

      table_data.push({ data: { numero_pa: agencia.numero_pa, nome_pa: agencia.nome_pa, sobras: agencia.valor } })

      // table_data.push({ data: { nome: colaborador.nome, rec_adm:  parseFloat((colaborador.rec_adm + colaborador.rec_adm_rat)).toFixed(2), sobras: parseFloat((colaborador.sobras + colaborador.sobras_rat)).toFixed(2), porcentagem: colaborador.porcentagem} });
    });

    this.dataSource = this.dataSourceBuilder.create(table_data);

  }

  close() {
    this.ref.close();
  }


  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  realocar(row) {
    this.api.apiGet('areassup/centro', { centro_custo: row.data.numero_pa }).subscribe(response => {

      let key  = Object.keys(response.dados)[0];

      let dialog = this.dialogService.open(RealocarAgenciaComponent, {
        context: {
          data: response.dados[key]
        }
      });

      dialog.onClose.subscribe(dialogResponse => {
        if (dialogResponse != null && dialogResponse.stats) {
          this.atualizarTabela();
        }
      });


    });

  }

  atualizarTabela() {
    this.api.apiPost("areassup/area", { codigo: this.superintendente }).subscribe(response => {

      let table_data = [];

      let key = Object.keys(response.dados)[0];
  
      response.dados[key].forEach(agencia => {
  
        table_data.push({ data: { numero_pa: agencia.numero_pa, nome_pa: agencia.nome_pa, sobras: agencia.valor } })
  
        // table_data.push({ data: { nome: colaborador.nome, rec_adm:  parseFloat((colaborador.rec_adm + colaborador.rec_adm_rat)).toFixed(2), sobras: parseFloat((colaborador.sobras + colaborador.sobras_rat)).toFixed(2), porcentagem: colaborador.porcentagem} });
      });
  
      this.dataSource = this.dataSourceBuilder.create(table_data);

    });
  }

  get agencia() {
    return Object.keys(this.data)[0];
  }

}
