import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbToggleModule,
  NbTooltipModule,
  NbPopoverModule,
  NbCardModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
  PaginationComponent,
  ContextButtonComponent,
  PrintButtonComponent,
  BetaCardComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  SlimOneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { PINKISH_THEME } from './styles/theme.pinkish';
import { RamaisModule } from '../ramais/ramais.module';
import { StorageModule } from '../storage/storage.module';
import { NgbDropdownModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LinksModule } from '../links/links.module';
import { FavoritosModule } from '../links/favoritos/favoritos.module';
import { TarifasModule } from '../tarifas/tarifas.module';
import { ContextMenuModule } from './components/context-button/context-menu/context-menu.module';
import { NgxPrintModule } from 'ngx-print';
import { NbTabsetFooterComponent } from './components/nb-tabset-footer/nb-tabset-footer.component';
import { NbTreegridPaginationComponent } from './components/nb-treegrid-pagination/nb-treegrid-pagination.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { InfoComponent } from './components/info/info.component';
import { AuthCarouselComponent } from './components/auth-carousel/auth-carousel.component';

const CUSTOM_THEMES = [
  DARK_THEME,
  COSMIC_THEME,
  CORPORATE_THEME,
  PINKISH_THEME,
];

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbToggleModule,
  NbTooltipModule,
  NgbDropdownModule,
  NbPopoverModule,
  NbCardModule,

  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  PaginationComponent,
  ContextButtonComponent,
  PrintButtonComponent,
  BetaCardComponent,
  OneColumnLayoutComponent,
  SlimOneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  NbTabsetFooterComponent,
  NbTreegridPaginationComponent,
  PieChartComponent,
  ConfirmationComponent,
  InfoComponent,
  AuthCarouselComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

const CUSTOM_PAGES = [
  ContextMenuModule,
  LinksModule,
  TarifasModule,
  FavoritosModule,
  RamaisModule,
  StorageModule,

  NgxPrintModule,
  NgbAlertModule,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, ...CUSTOM_PAGES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, NbTabsetFooterComponent],
  entryComponents: [ConfirmationComponent, InfoComponent]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, ...CUSTOM_THEMES],
        ).providers,
      ],
    };
  }
}
