import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ApiBase } from '../../../../../../@core/api/api-base.service';
import { ApiColaboradores } from '../../../../../../@core/api/colaboradores.service';
import { UtilsService } from '../../../../../../@core/utils';
import { DatePt } from '../../../../../../@core/utils/util-service.service';

@Component({
  selector: 'ngx-colaboradores-afastar',
  templateUrl: './colaboradores-afastar.component.html',
  styleUrls: ['./colaboradores-afastar.component.scss']
})
export class ColaboradoresAfastarComponent implements OnInit {

  lista_agencias: any;

  @Input() data: any;

  formTransferencia = this.fb.group({
    codigo: [{ value: '', disabled: true }],
    nome: [{ value: '', disabled: true }],
    centro_custo: [{ value: '', disabled: true }],
    
    data_afastamento: ['', [Validators.required]],
    data_retorno: ['', [Validators.required]],

    descricao: [null, [Validators.required]]
  });

  private user;

  constructor(private fb: FormBuilder, protected ref: NbDialogRef<ColaboradoresAfastarComponent>, private utils: UtilsService, private api: ApiColaboradores, private apiBase: ApiBase, private datePipe: DatePipe) { }

  ngOnInit() {

    this.api.apiGet('ficticio/todos-pas', '').subscribe(result => {
      this.lista_agencias = result.dados;
    });

    this.formTransferencia.get('codigo').patchValue(this.data.codigo);

    this.formTransferencia.get('nome').patchValue(this.data.usuario.nome);
    this.formTransferencia.get('centro_custo').patchValue(this.data.usuario.centro_custo + ' (PA ' + this.data.usuario.nome_centro_custo + ')');

    this.formTransferencia.get('data_afastamento').patchValue(new Date(this.data.data_afastamento));
    this.formTransferencia.get('data_retorno').patchValue(new Date(this.data.data_previsao_retorno));

    this.formTransferencia.get('descricao').patchValue(this.data.descricao);

    this.apiBase.apiGet('perfil', {}).subscribe(response => {
      this.user = response.dados['user'];
    });

  }

  get alterado(){
    let formValue = this.formTransferencia.value;

    return formValue.data_afastamento == this.datePipe.transform(formValue.data_afastamento, 'yyyy-MM-dd') || formValue.data_retorno == this.datePipe.transform(formValue.data_retorno, 'yyyy-MM-dd') || formValue.descricao == this.data.descricao;
  }

  close() {
    this.ref.close();
  }

  afastar() {

    let formValue = this.formTransferencia.getRawValue();

    
    let data = {
      codigo: formValue.codigo,

      data_afastamento: this.datePipe.transform(formValue.data_afastamento, 'yyyy-MM-dd'),
      data_previsao_retorno: this.datePipe.transform(formValue.data_retorno, 'yyyy-MM-dd'),

      descricao: formValue.descricao,
      created_by: this.user.id
    };

    this.api.apiPost('colaboradores/afastamento/cadastrar', data).subscribe(response => {

      if(response.status == 200){

        this.ref.close();
        this.utils.showToast('top-right', 'success', 'Yeah!', 'Colaborador afastadi com sucesso!');

      } else {
        this.utils.showToast('top-right', 'danger', 'Ops...', 'Erro no afastamento do colaborador.');
      }

    })

  }

  get novo_centro_custo() {
    return this.formTransferencia.get('nome_novo_centro_custo').value;
  }

}
