import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiBase } from '../../@core/api/api-base.service';

@Component({
  selector: 'ngx-manter',
  templateUrl: './manter.component.html',
  styleUrls: ['./manter.component.scss']
})
export class ManterComponent implements OnInit {

  action: string = "novo";
  params: any;


  regUrl = "(http(s)?:\\/\\/)?([\\d0-9a-zA-Z.-_\\w]+)\\.([0-9a-zA-Z]{2,6})\\:?[\\?\\=\\&\\/\\w.#_-]*\\/?";
  // public title = 'Novo Pagamento';

  formLinks = this.fb.group({
    title: ['',
      [
        Validators.required,
      ]
    ],
    url: ['',
      [
        Validators.required,
        Validators.pattern(this.regUrl),
      ]
    ],
  });

  // public tipoStatus: any;

  constructor(
    private fb: FormBuilder,
    protected dialogRef: NbDialogRef<ManterComponent>,
    private api: ApiBase,
  ) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log('init', this.action, this.params);

    // Verifica a ação
    if (this.action == 'editar' || this.action == 'delete') {
      this.title.setValue(this.params.title);
      this.url.setValue(this.params.url);
    }
  }

  get title() { return this.formLinks.get('title'); }
  get url() { return this.formLinks.get('url'); }


  submit() {
    console.log('submit', this.action, this.params);

    if (this.action == 'novo') {

      //POST    '/api/cliente/{numero_sisbr}/rateio/{id_Rateio}/pagamento'
      this.api.apiPost("perfil/links", {
        ativo: 1,
        ...this.formLinks.value
      }).subscribe(
        response => {
          this.modalClose(response, true);
        },
        error => {
          console.log(error);
        }
      );

    } else if (this.action == 'editar') {

      //POST    '/api/cliente/{numero_sisbr}/rateio/{id_Rateio}/pagamento'
      this.api.apiPut("perfil/links/edit/" + this.params.id, {
        ativo: 1,
        ...this.formLinks.value
      }).subscribe(
        response => {
          this.modalClose(response, true);
        },
        error => {
          console.log(error);
        }
      );

    } else if (this.action == 'delete') {

      //POST    '/api/cliente/{numero_sisbr}/rateio/{id_Rateio}/pagamento'
      this.api.apiDelete("perfil/links/delete/" + this.params.id).subscribe(
        response => {
          this.modalClose(response, true);
        },
        error => {
          console.log(error);
        }
      );

    }
  }

  // ngOnInit() {


  //   console.log(this.params);


  //   //Busca os Status Disponiveis
  //   this.apiDemissionario.apiGet("geral/status/tipos", {}).subscribe(
  //     response => {
  //       this.tipoStatus = response.dados.status_tipo;


  //       // Verifica a ação
  //       if (this.action == 'editar') {
  //         this.title = this.params.data.data_movimento + ' - Editar Pagamento';

  //         this.data_movimento.setValue(new Date(this.params.data.data_movimento));

  //         this.valor_conta_capital.setValue(this.params.data.valor_conta_capital);
  //         this.valor_baixado_conta_capital.setValue(this.params.data.valor_baixado_conta_capital);
  //         this.valor_pago_associado.setValue(this.params.data.valor_pago_associado);
  //         this.valor_devolvido.setValue(this.params.data.valor_devolvido);

  //         //Defini o valor do Status
  //         this.status_tipos_id.setValue(this.params.data.status[0].status_tipos_id);

  //         //Defini o nome do Status
  //         this.status_nome = this.tipoStatus[this.status_tipos_id.value - 1].nome;

  //         this.observacao.setValue(this.params.data.status[0].observacao);

  //       } else {
  //         this.title = 'Novo Pagamento';
  //       }
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );


  //   // console.log(this.action, this.params);
  // }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }

  // get data_movimento() { return this.formPagamentos.get('data_movimento'); }
  // get valor_conta_capital() { return this.formPagamentos.get('valor_conta_capital'); }
  // get valor_baixado_conta_capital() { return this.formPagamentos.get('valor_baixado_conta_capital'); }
  // get valor_pago_associado() { return this.formPagamentos.get('valor_pago_associado'); }
  // get valor_devolvido() { return this.formPagamentos.get('valor_devolvido'); }
  // get status_tipos_id() { return this.formPagamentos.get('status_tipos_id'); }
  // get observacao() { return this.formPagamentos.get('observacao'); }

  // submit() {

  //   if (this.action == 'novo') {

  //     console.log(this.formPagamentos, this.action, this.params);

  //     //POST    '/api/cliente/{numero_sisbr}/rateio/{id_Rateio}/pagamento'
  //     this.apiDemissionario.apiPost("cliente/" + this.params.numero_sisbr + "/rateio/" + this.params.idRateio + "/pagamento", this.formPagamentos.value).subscribe(
  //       response => {

  //         this.modalClose(response, true);

  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );

  //   } else if (this.action == 'editar') {


  //     //PUT    '/api/cliente/{numero_sisbr}/rateio/{id_Rateio}/pagamento/edit/{id_pagamento}'

  //     console.log(this.formPagamentos, this.action);

  //     console.log(this.params);

  //     this.formPagamentos.value.data_movimento = this.formPagamentos.value.data_movimento.toISOString();

  //     console.log(this.formPagamentos, this.action);



  //     //POST    '/api/cliente/{numero_sisbr}/rateio/{id_Rateio}/pagamento'
  //     this.apiDemissionario.apiPut("cliente/" + this.params.data.numero_sisbr + "/rateio/" + this.params.rateioId + "/pagamento/edit/" + this.params.data.id, this.formPagamentos.value).subscribe(
  //       response => {
  //         console.log('Retorno do POST', response);

  //         this.modalClose(response, true);

  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );

  //   }

  // }
}
