import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifasComponent } from './tarifas.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbSelectModule, NbInputModule, NbCardModule, NbIconModule, NbUserModule, NbActionsModule, NbAccordionModule, NbButtonModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { TaxasComponent } from './taxas/taxas.component';
import { TarifasPageComponent } from './tarifas-page/tarifas-page.component';
import { SelicComponent } from './taxas/selic/selic.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { TarifasPessoaFisicaComponent } from './tarifas-page/tarifas-pessoa-fisica/tarifas-pessoa-fisica.component';
import { TarifasPessoaJuridicaComponent } from './tarifas-page/tarifas-pessoa-juridica/tarifas-pessoa-juridica.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TarifasAvulsasComponent } from './tarifas-avulsas/tarifas-avulsas.component';

@NgModule({
  declarations: [TarifasComponent, TaxasComponent, TarifasPageComponent, SelicComponent, TarifasPessoaFisicaComponent, TarifasPessoaJuridicaComponent, TarifasAvulsasComponent],
  imports: [
    CommonModule,
    RouterModule,
     
    FormsModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbButtonModule,
    NbInputModule,

    NgSelectModule,

    NgbModule,

    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,

    NbUserModule,
    NbActionsModule,

    NbAccordionModule,

    NgxEchartsModule,

    Ng2SmartTableModule,
  ],
  exports: [
    TarifasComponent
  ],
  entryComponents: [
    TarifasComponent
  ]
})
export class TarifasModule { }
