import { AfterViewInit, Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NbLayoutComponent, NbSidebarService, NbMediaBreakpointsService, NbThemeService } from '@nebular/theme';

import { WindowModeBlockScrollService } from '../../services/window-mode-block-scroll.service';
import { EnvironmentRoles } from '../../../@core/auth/roles.service';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent implements AfterViewInit {
  roles: any = new EnvironmentRoles();
  private destroy$: Subject<void> = new Subject<void>();
  isLessThanSm = false;

  @ViewChild(NbLayoutComponent, { static: false }) layout: NbLayoutComponent;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
  ) { }

  ngOnInit(): void {
    const { sm } = this.breakpointService.getBreakpointsMap();

    // Tamanhos das telas de telas
    this.themeService.onMediaQueryChange().pipe(
      map(([, currentBreakpoint]) => currentBreakpoint.width < sm),
      takeUntil(this.destroy$),

    ).subscribe((isLessThanSm: boolean) => {
      this.isLessThanSm = isLessThanSm
    });
  }

  ngAfterViewInit() {
    this.roles = new EnvironmentRoles();
    if (isPlatformBrowser(this.platformId)) {
    }
  }

  clickSideBar() {
    this.minimizeSidebar();
  }

  closeSidebar() {
    this.minimizeSidebar();
  }

  minimizeSidebar() {
    if (this.isLessThanSm) {
      this.sidebarService.collapse('menu-sidebar');
    } else {
      this.sidebarService.compact('menu-sidebar');
    }
  }
}
