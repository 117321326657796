import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ApiColaboradores } from '../../../../../../@core/api/colaboradores.service';
import { UtilsService } from '../../../../../../@core/utils';
import { ColaboradoresRealocarComponent } from '../../../colaboradores/relacao-colaboradores/colaboradores-realocar/colaboradores-realocar.component';

@Component({
  selector: 'ngx-realocar-agencia',
  templateUrl: './realocar-agencia.component.html',
  styleUrls: ['./realocar-agencia.component.scss']
})
export class RealocarAgenciaComponent implements OnInit {

  @Input() data: any;

  formRealocar = this.fb.group({
    numero_pa: [{ value: '', disabled: true }, Validators.required],
    nome_pa: [{ value: '', disabled: true }],
    superintendente: [{ value: '', disabled: true }],

    cod_novo_superintendente: [null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6)]],
    novo_superintendente: [{ value: '', disabled: true }, [Validators.required]],
  });

  superintendentes: any;
  realocando = false;
  selecionado = false;

  constructor(private fb: FormBuilder, protected ref: NbDialogRef<ColaboradoresRealocarComponent>, private utils: UtilsService, private api: ApiColaboradores) {

    api.apiGet('sup/todos', '').subscribe(result => {
      this.superintendentes = result.dados;
    });

  }

  ngOnInit() {

    console.log(this.data);
    

    this.formRealocar.get('numero_pa').patchValue(this.data[0].numero_pa);
    this.formRealocar.get('nome_pa').patchValue(this.data[0].nome_pa);
    this.formRealocar.get('superintendente').patchValue(this.data[0].nome);
  }

  realocar() {

    this.realocando = true;

    this.utils.showToast('top-right', 'warning', 'Aguarde...', 'Enviando a solicitação para o servidor');

    this.api.apiPost('areassup/alterar', { numero_pa: this.data[0].numero_pa, superintendente: this.formRealocar.get('cod_novo_superintendente').value }).subscribe(response => {

      if (response.status == 200) {
        this.utils.showToast('top-right', 'success', 'Agência realocada com sucesso!');
        this.ref.close({ stats: response.status });
      } else {
        this.utils.showToast('top-right', 'danger', 'Falha ao realocar agência', response.message);
      }
    });
  }

  close() {
    this.ref.close();
  }


  selecionarSuperintendente($event) {

    let registry = this.formRealocar.get('cod_novo_superintendente');

    if (registry.valid) {

      let superintendente = this.superintendentes[$event.target.value];

      if (superintendente != undefined && superintendente != null) {
        this.formRealocar.get('novo_superintendente').patchValue(superintendente[0].nome);
        this.selecionado = true;
      } else {
        this.formRealocar.get('novo_superintendente').patchValue('');
        this.selecionado = false;
      }

    } else {
      this.formRealocar.get('novo_superintendente').patchValue('');
      this.selecionado = false;
    }

  }

}
