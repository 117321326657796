import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UtilsService } from '../../@core/utils';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-file-reader',
  templateUrl: './file-reader.component.html',
  styleUrls: ['./file-reader.component.scss']
})
export class FileReaderComponent implements OnInit {

  action: string = "view";
  data: any;
  method: string = "html";
  fileType: any;
  fileURL: any;

  STG_URL: string = environment.STG_URL;

  constructor(
    protected dialogRef: NbDialogRef<FileReaderComponent>,
    private utils: UtilsService,
  ) {

  }

  ngOnInit() {
    console.log(this.action);
    console.log(this.data);

    this.fileType = this.utils.getFileType(this.data.extensao);
    // this.fileURL = this.utils.getSafeUrl(this.STG_URL + '/arquivo/' + this.data.tb_storage_user_app_id + '/' + this.data.id + '/' + this.data.file_name);
    this.fileURL = this.STG_URL + '/arquivo/' + this.data.tb_storage_user_app_id + '/' + this.data.id + '/' + this.data.file_name;
    // this.utils.getFileType(this.data);
  }

  modalClose(data: any = {}, status: boolean = false) {
    this.dialogRef.close({
      data: [data],
      status: status
    });
  }
}
