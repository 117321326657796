import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-taxas',
  templateUrl: './taxas.component.html',
  styleUrls: ['./taxas.component.scss']
})
export class TaxasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
