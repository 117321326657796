import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetalhesAgenciaComponent } from './detalhes-agencia.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbSpinnerModule, NbTreeGridModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DetalhesAgenciaComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,

    FormsModule,
    ReactiveFormsModule,
    NbTreeGridModule,
  ],
  exports: [DetalhesAgenciaComponent],
  entryComponents: [DetalhesAgenciaComponent]
})
export class DetalhesAgenciaModule { }
