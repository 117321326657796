import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateEditComponent } from './create-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NbButtonModule, NbCardModule, NbDialogModule, NbIconModule, NbSpinnerModule } from '@nebular/theme';
import { TagsInputModule } from 'ngx-tags-input';



@NgModule({
  declarations: [CreateEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    NgSelectModule,
    NbButtonModule,
    NbDialogModule,
    NbCardModule,
    NbIconModule,
    NbSpinnerModule,

    TagsInputModule,

    FormsModule,
    ReactiveFormsModule
  ],
  exports: [CreateEditComponent],
  entryComponents: [CreateEditComponent]
})
export class CreateEditModule { }
