import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router, 
        private authService: AuthenticationService
    ) {
        // console.log("teste");
        
    }

    canActivate(    
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree  {
        // console.log(route);
        // console.log(state);
        var roles = JSON.parse(localStorage.getItem('userRoles'));
        
        if (!this.authService.isTokenExpired()) { // validando se o tokem esta valido
            // console.log("1",!this.authService.isTokenExpired());

            return true;
        }
        
        // console.log(route.routeConfig.path);
        this.router.navigate(['/auth/login']);
        return false;
    }

}